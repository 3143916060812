import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

class AdminProcessoSituacaoForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    back = "/painel/situacoes";

    routes = [
        {title: <Link to={this.back}>Situações do Processo</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]

    componentDidMount = () => {
        titleWeb('Situações do Processo')
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onView = async () => {
        POST_API('/process_step/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/process_step/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate)  return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminProcessoSituacaoForm;