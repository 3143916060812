import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig, BLOCK_FORM_ENTER, getUPLOADAPI } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

class AdminTaskDocumentForm extends React.Component {

    state = {
        _navigate: false,
        _navigate_duplicate: false,
        _duplicate_id: 0,
        _loading: false,
        _screen: true,
        tag_value: '',
        _params: window.location.href.split('/'),
        defaultValue: null,
    }

    back = "/painel/tarefas/"+this.state._params[5]+"/documentos";
    back2 = "/painel/tarefas";

    routes = [
        {title: <Link to={this.back2}>Tarefas</Link>},
        {title: this.state._params[5] },
        {title: <Link to={this.back}>Documentos</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]

    componentDidMount = () => {
        titleWeb('Tarefas Documentos')
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onView = async () => {
        POST_API('/task_document/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[7]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    defaultValue: res.data,
                })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.TASK_ID = this.state._params[5];
        POST_API('/task_document/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate)  return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form onKeyDown={BLOCK_FORM_ENTER} layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Row gutter={[16,0]} align={"bottom"}>
                                        <Form.Item hidden name="ID">
                                            <Input />
                                        </Form.Item>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="NAME" label="Nome" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="URL" label="Arquivo" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <Upload maxCount={1} action={getUPLOADAPI} className={`admin-profile-upload`}>
                                                    <Button block>Selecionar Arquivo</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminTaskDocumentForm;