import { Col, Row, Breadcrumb, Typography, Image, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import about from '../../assets/img/about/about-img.png';
import { IoCheckmarkDone, IoClipboardOutline, IoDocumentOutline, IoEyeOutline, IoFlagOutline } from "react-icons/io5";
import ReactCardFlip from 'react-card-flip';

class WebAbout extends React.Component {

    state = {
        flip1: false,
        flip2: false,
        flip3: false,
        flip4: false,
        flip5: false,
        flip6: false,
    }

    breadcrumb = [
        { title: <Link to='/'><Typography className="home-breadcrumb-link">Home</Typography></Link> },
        { title: <Typography className="home-breadcrumb-link active">Quem Somos</Typography> }
    ]

    onClick = () => {
        window.document.getElementById('web-content').scrollTo(0,0)
    }

    render = () => {

        return (
            
            <Row>
                <Col span={24} className="home-col-black">
                    <Row align={'middle'} className="home-row-breadcrumb">
                        <Col span={12}>
                            <Breadcrumb className="home-breadcrumb" items={this.breadcrumb} />
                        </Col>
                        <Col span={12}>
                            <Typography className="home-breadcrumb-title">Quem Somos</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[60,16]} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title">Quem Somos</Typography></Col>
                        <Col xs={24} md={12} className="home-row-page-about">
                            <Typography className="about-row-title">APRESENTAÇÃO</Typography>
                            <Typography className="about-row-subtitle"><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDone color="var(--primary)" size={18} /></span>Startup jurídica 100% nacional e focada em agilidade, controle, gestão e atendimento, com a nossa plataforma vamos otimizar a gestão de seus processos e relacionamento com os clientes.</Typography>
                            <Typography className="about-row-subtitle"><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDone color="var(--primary)" size={18} /></span>O principal objetivo da nossa plataforma é contribuir para a melhor gestão dos escritórios e eficiência na operação dos processos e atendimentos aos clientes.</Typography>
                            <Typography className="about-row-subtitle"><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDone color="var(--primary)" size={18} /></span>Nós oferecemos soluções inovadoras e práticas para o dia a dia dos advogados, acreditamos na melhoria contínua e, por isso, estamos sempre evoluindo e melhorando para nossos clientes terem o melhor a sua disposição.</Typography>
                            <Typography className="about-row-subtitle"><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDone color="var(--primary)" size={18} /></span>Nosso propósito é desburocratizar a gestão dos escritórios jurídicos e fazer os advogados pararem de perder tempo com os serviços operacionais.</Typography>
                            <Typography className="about-row-subtitle"><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDone color="var(--primary)" size={18} /></span>Gente que se faz presente - Entendemos que com uma boa equipe, pessoas do bem, motivadas, qualificadas e apaixonadas por nosso propósito, podemos oferecer um serviço do mais alto padrão de qualidade para nossos clientes.</Typography>
                        </Col>
                        <Col xs={24} md={12}>
                            <Image src={about} preview={false} style={{width: '100%', borderRadius: 8}} />
                        </Col>
                        <Col xs={24} md={12}>
                            <center>
                                <div className="about-row-card">
                                    <IoFlagOutline color="var(--primary)" size={30}/>
                                    <Typography className="about-row-page-title">Missão</Typography>
                                    <Typography className="about-row-page-text">Oferecer soluções tecnológicas ágeis para escritórios jurídicos.</Typography>
                                </div>
                            </center>
                        </Col>
                        <Col xs={24} md={12}>
                            <center>
                                <div className="about-row-card">
                                    <IoEyeOutline color="var(--primary)" size={30}/>
                                    <Typography className="about-row-page-title">Visão</Typography>
                                    <Typography className="about-row-page-text">Ser reconhecida como plataforma referência em gestão ágil para escritórios jurídicos.</Typography>
                                </div>
                            </center>
                        </Col>
                        <Col span={24}>
                            <Typography className="about-row-title" style={{marginTop: 20}}>Valores</Typography>
                        </Col>
                        <Col md={8} xs={24} onMouseOver={() => this.setState({flip1: true})} onMouseOut={() => this.setState({flip1: false})}>
                            <ReactCardFlip isFlipped={this.state.flip1} flipDirection="horizontal">
                                <center>
                                    <div className="about-row-card-flip">
                                        <IoCheckmarkDone color="var(--primary)" size={30}/>
                                        <Typography className="about-row-page-title">Ética</Typography>
                                    </div>
                                </center>
                                <center>
                                    <div className="about-row-card-flip active">
                                        <Typography className="about-row-page-text color-white">Não se trata de fazer só o certo e sim fazer consciente.</Typography>
                                    </div>
                                </center>
                            </ReactCardFlip>
                        </Col>
                        <Col md={8} xs={24} onMouseOver={() => this.setState({flip2: true})} onMouseOut={() => this.setState({flip2: false})}>
                            <ReactCardFlip isFlipped={this.state.flip2} flipDirection="horizontal">
                                <center>
                                    <div className="about-row-card-flip">
                                        <IoCheckmarkDone color="var(--primary)" size={30}/>
                                        <Typography className="about-row-page-title">Cumplicidade</Typography>
                                    </div>
                                </center>
                                <center>
                                    <div className="about-row-card-flip active">
                                        <Typography className="about-row-page-text color-white">Trabalhamos juntos e crescemos juntos.</Typography>
                                    </div>
                                </center>
                            </ReactCardFlip>
                        </Col>
                        <Col md={8} xs={24} onMouseOver={() => this.setState({flip3: true})} onMouseOut={() => this.setState({flip3: false})}>
                            <ReactCardFlip isFlipped={this.state.flip3} flipDirection="horizontal">
                                <center>
                                    <div className="about-row-card-flip">
                                        <IoCheckmarkDone color="var(--primary)" size={30}/>
                                        <Typography className="about-row-page-title">Integridade</Typography>
                                    </div>
                                </center>
                                <center>
                                    <div className="about-row-card-flip active">
                                        <Typography className="about-row-page-text color-white">Vale mais que um contrato.</Typography>
                                    </div>
                                </center>
                            </ReactCardFlip>
                        </Col>
                        <Col md={8} xs={24} onMouseOver={() => this.setState({flip4: true})} onMouseOut={() => this.setState({flip4: false})}>
                            <ReactCardFlip isFlipped={this.state.flip4} flipDirection="horizontal">
                                <center>
                                    <div className="about-row-card-flip">
                                        <IoCheckmarkDone color="var(--primary)" size={30}/>
                                        <Typography className="about-row-page-title">Confiança</Typography>
                                    </div>
                                </center>
                                <center>
                                    <div className="about-row-card-flip active">
                                        <Typography className="about-row-page-text color-white">Acreditamos em nossa plataforma como solução para o seu negócio.</Typography>
                                    </div>
                                </center>
                            </ReactCardFlip>
                        </Col>
                        <Col md={8} xs={24} onMouseOver={() => this.setState({flip5: true})} onMouseOut={() => this.setState({flip5: false})}>
                            <ReactCardFlip isFlipped={this.state.flip5} flipDirection="horizontal">
                                <center>
                                    <div className="about-row-card-flip">
                                        <IoCheckmarkDone color="var(--primary)" size={30}/>
                                        <Typography className="about-row-page-title">Paixão</Typography>
                                    </div>
                                </center>
                                <center>
                                    <div className="about-row-card-flip active">
                                        <Typography className="about-row-page-text color-white">Amamos o que fazemos, por isso fazemos o melhor.</Typography>
                                    </div>
                                </center>
                            </ReactCardFlip>
                        </Col>
                        <Col md={8} xs={24} onMouseOver={() => this.setState({flip6: true})} onMouseOut={() => this.setState({flip6: false})}>
                            <ReactCardFlip isFlipped={this.state.flip6} flipDirection="horizontal">
                                <center>
                                    <div className="about-row-card-flip">
                                        <IoCheckmarkDone color="var(--primary)" size={30}/>
                                        <Typography className="about-row-page-title">Fazer a diferença</Typography>
                                    </div>
                                </center>
                                <center>
                                    <div className="about-row-card-flip active">
                                        <Typography className="about-row-page-text color-white">Não somos mais uma no mercado, pode testar.</Typography>
                                    </div>
                                </center>
                            </ReactCardFlip>
                        </Col>
                        <Col span={24}>
                            <Typography className="about-row-title" style={{marginTop: 20}}>POLÍTICA E TERMOS</Typography>
                        </Col>
                        <Col span={24}>
                            <Link to='/politica'><Button onClick={this.onClick} type="link" className="btn-link" icon={<IoClipboardOutline />}>Política de Privacidade.</Button></Link><br />
                            <Link to='/termos'><Button onClick={this.onClick} type="link" className="btn-link" icon={<IoDocumentOutline />}>Termo de Condições de Uso.</Button></Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
           
        )

    }

}

export default WebAbout;