import React from "react";
import { Breadcrumb, Button, Col, Layout, Modal, Row, Tag, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoClose, IoReturnUpBackOutline } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

class AdminEscritorioPlanoList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _params: window.location.href.split('/'),
        filter: {
            PLAN_ID: []
        },
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: { current: 1, total: 0, limit: 10 },
        table_filter: null,
        table_sorter: null,
        table_select: [],
    }

    back = "/painel/escritorios";

    routes = [
        {title: <Link to={"/painel/escritorios/"}>Escritórios</Link>},
        {title: <Link to={"/painel/escritorios/"+this.state._params[5]}>{this.state._params[5]}</Link>},
        {title: <Link to={this.back}>Plano</Link>},
        {title: this.props.type === 'list' ? 'Lista' : 'Lixeira'}
    ]

    componentDidMount = async () => {
        titleWeb('Escritório - Plano')
       this.onRenderColumns().then(() => this.onRenderData())
    }

    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/subscription/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify({ OFFICE_ID: this.state._params[5] }), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/subscription/cancel.php', { token: getToken(), ID: ID, type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        this.setState({
            table_column: [
                { title: 'Plano', dataIndex: 'PLAN_NAME', key: 'plan.NAME', align: 'left', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Situação', dataIndex: 'STATUS_NAME', key: 'subscription.STATUS', width: 150, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => <Tag>{text}</Tag> },
                { title: 'Data Assinatura', width: 180, align: 'center', dataIndex: 'DATETIME_INSERT_FORMAT', key: 'subscription.DATETIME_INSERT', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Data Vencimento', width: 180, align: 'center', dataIndex: 'DATETIME_DUE_FORMAT', key: 'subscription.DATETIME_DUE', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Data Cancelamento', width: 180, align: 'center', dataIndex: 'DATETIME_CANCEL_FORMAT', key: 'subscription.DATETIME_CANCEL', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Ações', key: 'action', width: 80, render: (text, record) => (
                    <Row gutter={[4,4]} className="table__actions">
                        { this.props.type === 'list' && verifyConfig(`epl.del`) && record.STATUS === 'A' ? <Col><Button onClick={() => this.onAction(record.ID, 'del', 'Cancelar assinatura?')} className="table__btn table__btn_delete" title='Cancelar' type="primary" shape="circle" size="small"><IoClose size={14} /></Button></Col> : null }
                        { this.props.type === 'list' && verifyConfig(`epl.del`) && record.STATUS !== 'A' ? <Col><Button onClick={() => this.onAction(record.ID, 'rec', 'Restaurar assinatura?')} className="table__btn table__btn_recover" title='Cancelar' type="primary" shape="circle" size="small"><IoReturnUpBackOutline size={14} /></Button></Col> : null }
                    </Row>
                )},
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}> </Col>
                        <Col span={24}>
                            <Table
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminEscritorioPlanoList;