import React from "react";
import { Card, Col, Image, Row, Spin, Typography, message } from 'antd';

import { POST_API, POST_CATCH, getToken } from "../../services/api";
import TimelineComponent from "../../components/Timeline";

import { IoLinkOutline, IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoMailOutline }  from 'react-icons/io5';

class WebPerfilSobre extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        profile: null,
        _publicacoes_list: [],
        _publicacoes_loading: true,
        _publicacoes_pagination: {
            current: 1,
            total: 0,
            limit: 30
        },
        _fotos_list: []
    }

    componentDidMount = () => {
        this.onView()
        this.onRenderDataPublicacoes()
        this.onRenderDataFotos()
    }

    onRenderDataFotos = () => {
        setTimeout(async () => {
            POST_API('/timeline_photo/search.php', { token: getToken(), filter: JSON.stringify({CREDENTIAL_ID: this.state._params[4]}), pagination: JSON.stringify({current: 1, total: 0, limit: 4}), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _fotos_list: res.data
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH())
        }, 500);
    }

    onView = async () => {
        await POST_API('/credential/search.php', { token: getToken(), filter: JSON.stringify({ID: this.state._params[4]}), type: 'view' }).then(rs => rs.json()).then(res => {
            this.setState({profile: res.data, _screen: false})
        }).catch(() => POST_CATCH())
    }

    onRenderDataPublicacoes = () => {
        this.setState({_publicacoes_loading: true })
        setTimeout(async () => {
            POST_API('/timeline/search.php', { token: getToken(), filter: JSON.stringify({CREDENTIAL_ID: this.state._params[4]}), pagination: JSON.stringify(this.state._publicacoes_pagination), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _publicacoes_list: res.data,
                        _publicacoes_pagination: {
                            ...this.state._publicacoes_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({_publicacoes_loading: false }))
        }, 500);
    }

    onRenderDataPublicacoesLoad = () => {
        setTimeout(async () => {
            POST_API('/timeline/search.php', { token: getToken(), filter: JSON.stringify({CREDENTIAL_ID: this.state._params[4]}), pagination: JSON.stringify(this.state._publicacoes_pagination), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _publicacoes_list: res.data,
                        _publicacoes_pagination: {
                            ...this.state._publicacoes_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({_publicacoes_loading: false }))
        }, 500);
    }

    render = () => {

        return (
            
            <Row className="perfil-content-row" gutter={[16, 16]}>
                <Col md={10} xs={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="Apresentação">
                                <Typography style={{textAlign: 'justify'}}>{this.state.profile?.DESC}</Typography>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title="Sobre">
                                <Typography style={{textAlign: 'justify'}}>{this.state.profile?.ABOUT}</Typography>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title="Contatos">
                                { this.state.profile?.EMAIL ? <a rel="noreferrer" href={`mailto:${this.state.profile?.EMAIL}`} target="_blank" className="btn-link-contato"><IoMailOutline size={20} style={{marginRight: 10}} />{this.state.profile?.EMAIL}</a> : null }
                                { this.state.profile?.WHATSAPP ? <a rel="noreferrer" href={`https://web.whatsapp.com/send?phone=${this.state.profile?.WHATSAPP}`} target="_blank" className="btn-link-contato"><IoLogoWhatsapp size={20} style={{marginRight: 10}} />{this.state.profile?.WHATSAPP}</a> : null }
                                { this.state.profile?.FACEBOOK ? <a rel="noreferrer" href={`${this.state.profile?.FACEBOOK}`} target="_blank" className="btn-link-contato"><IoLogoFacebook size={20} style={{marginRight: 10}} />{this.state.profile?.FACEBOOK}</a> : null }
                                { this.state.profile?.INSTAGRAM ? <a rel="noreferrer" href={`${this.state.profile?.INSTAGRAM}`} target="_blank" className="btn-link-contato"><IoLogoInstagram size={20} style={{marginRight: 10}} />{this.state.profile?.INSTAGRAM}</a> : null }
                                { this.state.profile?.TWITTER ? <a rel="noreferrer" href={`${this.state.profile?.TWITTER}`} target="_blank" className="btn-link-contato"><IoLogoTwitter size={20} style={{marginRight: 10}} />{this.state.profile?.TWITTER}</a> : null }
                                { this.state.profile?.LINKEDIN ? <a rel="noreferrer" href={`${this.state.profile?.LINKEDIN}`} target="_blank" className="btn-link-contato"><IoLogoLinkedin size={20} style={{marginRight: 10}} />{this.state.profile?.LINKEDIN}</a> : null }
                                { this.state.profile?.YOUTUBE ? <a rel="noreferrer" href={`${this.state.profile?.YOUTUBE}`} target="_blank" className="btn-link-contato"><IoLogoYoutube size={20} style={{marginRight: 10}} />{this.state.profile?.YOUTUBE}</a> : null }
                                { this.state.profile?.SITE ? <a rel="noreferrer" href={`${this.state.profile?.SITE}`} target="_blank" className="btn-link-contato"><IoLinkOutline size={20} style={{marginRight: 10}} />{this.state.profile?.SITE}</a> : null }
                            </Card>
                        </Col>
                        <Col span={24}>
                            <iframe title="mapa" width={'100%'} height={400} src={`https://maps.google.com/maps?q=${this.state.profile?.STREET} ${this.state.profile?.NUMBER} ${this.state.profile?.DISTRICT} ${this.state.profile?.CITY}&t=&z=13&ie=UTF8&iwloc=&output=embed`} frameborder="0" allowfullscreen />
                        </Col>
                        <Col span={24}>
                            <Card title="Galeria">
                                <Row gutter={[4, 4]}>
                                    { this.state._fotos_list.map((v, i) => (
                                        <Col span={12} key={i}>
                                            <Image src={v.URL} className="galeria-image-profile" />
                                        </Col>
                                    )) }
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>     
                <Col md={14} xs={24}>
                { this.state._publicacoes_loading ? <Col span={24}><center><Spin /></center></Col> : (
                    <Row gutter={[16,16]}>
                        { this.state._publicacoes_list.length > 0 ? (
                            <>
                            { this.state._publicacoes_list.map((item, index) => (
                                <Col span={24} key={index}>
                                    <TimelineComponent type="list" index={index} item={item} onActionPublicacoes={this.onActionPublicacoes} openModalPublicacoes={this.openModalPublicacoes} onRenderDataPublicacoesLoad={this.onRenderDataPublicacoesLoad} />
                                </Col>
                            )) }
                            </>
                        ) : <Col span={24}><Typography className="no-data">Sem Publicações</Typography></Col> }
                    </Row>
                ) }
                </Col>     
            </Row>

        )

    }

}

export default WebPerfilSobre;