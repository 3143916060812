import React from "react";
import { Avatar, Button, Card, Carousel, Divider, Image, Input, List, Row, Typography, message } from 'antd';

import { IoChatboxEllipses, IoChatboxEllipsesOutline, IoHeart, IoHeartOutline, IoPencil, IoSendSharp, IoStar, IoStarOutline, IoTrash } from 'react-icons/io5';
import { POST_API, POST_CATCH, getToken } from "../../services/api";

class TimelineComponent extends React.Component {

    state = {
        comment: false,
        body: ''
    }

    onActionLike = () => {
        
        const { item, onRenderDataPublicacoesLoad } = this.props
        POST_API('/timeline/like.php', { token: getToken(), TYPELIKE: 'LIKE', ID: item.ID, TYPE: (item.SELF_LIKE === '0') ? 'LIKE' : 'UNLIKE' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                onRenderDataPublicacoesLoad()
            } else {
                message.warning(res.msg)
            }
        }).catch(() => { POST_CATCH() })

    }

    onActionLove = () => {
        
        const { item, onRenderDataPublicacoesLoad } = this.props
        POST_API('/timeline/like.php', { token: getToken(), TYPELIKE: 'LOVE', ID: item.ID, TYPE: (item.SELF_LOVE === '0') ? 'LIKE' : 'UNLIKE' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                onRenderDataPublicacoesLoad()
            } else {
                message.warning(res.msg)
            }
        }).catch(() => { POST_CATCH() })

    }

    onComment = () => {
        this.setState({comment: !this.state.comment})
    }

    onCommentSend = () => {

        const { item, onRenderDataPublicacoesLoad } = this.props

        if (this.state.body === '') {
            message.warning('Digite seu comentário')
        } else {
            POST_API('/timeline_comment/save.php', { token: getToken(), master: JSON.stringify({ TIMELINE_ID: item.ID, BODY: this.state.body }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({ body: '' })
                    onRenderDataPublicacoesLoad()
                } else {
                    message.warning(res.msg)
                }
            }).catch(() => { POST_CATCH() })
        }

    }

    render = () => {

        const { item, index, openModalPublicacoes, onActionPublicacoes, type } = this.props

        return (
            <>
                <Card size="small"
                    title={
                        <List>
                            <List.Item key={index} actions={ type === 'list' ? [] : [<Button onClick={() => openModalPublicacoes('edit', item)} className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button>, <Button  onClick={() => onActionPublicacoes(item.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button>]}>
                                <List.Item.Meta
                                    avatar={<Avatar size={30} src={item.CREDENTIAL_AVATAR} />}
                                    title={<Typography style={{marginTop: 3, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{item.CREDENTIAL_NAME}</Typography>}
                                />
                            </List.Item>
                        </List>
                    }
                    cover={
                        <>
                            { item.PHOTOS.length > 0 ? (
                                <Carousel className="timelineA-carousel">
                                    { item.PHOTOS.map((v,i) => (
                                        <div className="timeline-carousel" key={i}>
                                            <Image src={v.URL} preview={false} />
                                        </div>
                                    )) }
                                </Carousel>
                            ) : null }
                        </>
                    }
                    actions={[
                        <Button onClick={this.onActionLike} title="Like" type="link" className="btn-link">{item.SELF_LIKE === '0' ? <IoStarOutline style={{marginRight: 5}}/> : <IoStar color="var(--primary)" style={{marginRight: 5}}/>}{item.LIKE_QTDE}</Button>,
                        <Button onClick={this.onActionLove} title="Amei" type="link" className="btn-link">{item.SELF_LOVE === '0' ? <IoHeartOutline style={{marginRight: 5}}/> : <IoHeart color="var(--primary)" style={{marginRight: 5}}/>}{item.LOVE_QTDE}</Button>,
                        <Button onClick={this.onComment} title="Comentários" type="link" className="btn-link">{ !this.state.comment ? <IoChatboxEllipsesOutline style={{marginRight: 5}}/> : <IoChatboxEllipses color="var(--primary)" style={{marginRight: 5}}/> }{item.COMMENT_QTDE}</Button>,
                    ]}
                >  
                    <Typography className="timeline-title">{item.NAME}</Typography>
                    <Typography className="timeline-text">{item.DESC}</Typography>
                    <Typography className="timeline-date">Publicado em {item.DATETIME_INSERT_FORMAT}</Typography>
                </Card>
                { this.state.comment ? (
                    <Card style={{marginTop: 10}}>
                        <Row justify={'space-between'} align={'bottom'}>
                            <Input.TextArea value={this.state.body} onChange={(v) => this.setState({ body: v.target.value })} placeholder="Deixe seu comentário..." style={{width: '90%'}}></Input.TextArea>
                            <Button onClick={this.onCommentSend} shape="circle" className="btn-link" type="link" style={{width: '8%'}}><IoSendSharp /></Button>
                        </Row>
                        <Divider/>
                        { item.COMMENT_QTDE > 0 ? (
                            <List
                                itemLayout="horizontal"
                                dataSource={item.COMMENTS}
                                renderItem={(comment, i) => (
                                    <List.Item key={i}>
                                        <List.Item.Meta
                                            avatar={<Avatar src={comment.CREDENTIAL_AVATAR} />}
                                            title={<Typography>{comment.CREDENTIAL_NAME}</Typography>}
                                            description={comment.BODY}
                                        />
                                    </List.Item>
                                )}
                            />
                        ) : <Typography style={{ textTransform: 'uppercase', color: '#0000008c', textAlign: 'center', fontSize: 10 }}>Seja o primeiro a comentar</Typography> }
                    </Card>
                ) : null }
            </>
        )
    }

}

export default TimelineComponent;