import React from "react";
import { Avatar, Breadcrumb, Button, Card, Col, Modal, Divider, Form, Input, Layout, Row, Switch, Tag, Typography, Upload, message, Select } from "antd";
import { Link, Navigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { POST_API, getToken, titleWeb, setPath, POST_CATCH, verifyConfig, getUPLOADAPI } from "../../services/api";

// CONTROLLER
import PageLoad from '../Controller/PageLoad';

import { IoLinkOutline, IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoCheckmark, IoMailOutline, IoCameraOutline, IoDocument, IoDocumentOutline } from 'react-icons/io5';

import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import ImgCrop from "antd-img-crop";

class AdminMinhaConta extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: null,
        doc: false,
        doc_user: false,
        method: [],
        modal: false,
        term: false,
        pages: [],
        row: [],
        version: 0,
        valuePage: null
    }

    routes = [
        {title: 'Minha conta'},
    ]

    componentDidMount = () => {
        titleWeb('Minha conta')
        setPath('credential')
        this.onView()
        this.onRenderData()
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({_screen: true})
        await fetch('https://viacep.com.br/ws/'+cep+'/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    defaultValue: {
                        ...this.state.defaultValue,
                        STREET: res.logradouro,
                        DISTRICT: res.bairro,
                        CITY: res.localidade,
                        STATE: res.uf,
                        CEP: cep
                    }
                })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))

    }

    // FUNCTION MY PROFILE

    onView = async () => {
        await POST_API('/credential/search.php', { token: getToken(), type: 'self' }).then(rs => rs.json()).then(res => {
            this.setState({defaultValue: res.data, _screen: false})
            if (String(res.data.LOGIN).length > 14) this.setState({doc: true})
        }).catch(() => POST_CATCH())
        await POST_API('/payment_method/search.php', { token: getToken(), type: 'list' }).then(rs => rs.json()).then(res => {
            this.setState({method: res.data})
        }).catch(() => POST_CATCH())
    }

    onSend = (values) => {
        this.setState({_screen: true})
        POST_API('/credential/self-pass.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSendAccount = (values) => {
        this.setState({_loading: true})
        POST_API('/credential/self-saveall.php', { token: getToken(), master: JSON.stringify(this.state.defaultValue) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onPayment = () => {
        this.setState({ modal: !this.state.modal })
    }

    onSelectPlan = (text, id, type) => {

        var self = this
        Modal.confirm({
            title: text,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/subscription/save.php', { token: getToken(), ID: id, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        self.onView()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH() })
            },
            onCancel() {},
        })

    }

    onChangePic = (value) => {
        if (value.file.response?.url) {
            this.setState({_screen: true})
            POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({AVATAR: value.file.response?.url}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangeName = (value) => {
        this.setState({_screen: true})
        POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({INTER_NAME: value}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView())
    }

    downloadQRCode = async () => {
        const canvas = await html2canvas(document.getElementById('myqrcode'));
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'download.png', 'image/png');
    };

    onCopy = () => {
        window.navigator.clipboard.writeText(`https://jurisvip.adsolucoestecnologia.com.br/perfil/${this.state.defaultValue?.ID}`);
        message.success({ content: 'URL copiada', key: 'screen' })
    }

    onChangeField = (a, b) => {
        if (a.length > 0) {
            var name = a[0].name[0]
            var value = a[0].value
            this.setState({
                defaultValue: {
                    ...this.state.defaultValue,
                    [name]: value
                }
            })
        }
    } 

    onSendPayment = (value) => {
        POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({PAYMENT_METHOD_ID: value.PAYMENT_METHOD_ID}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.onView()
                this.onPayment()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView())
    }

    onRenderData = () => {
        setTimeout(async () => {
            POST_API('/page/search.php', { token: getToken(), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({pages: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH())
        }, 500);
    }

    onOpenTerm = (ID, VER) => {

        POST_API('/page/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: ID}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({valuePage: res.data, version: VER})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).then(() => {
            POST_API('/page_body/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({PAGE_ID: ID, VER: VER}), sorter: JSON.stringify({ columnKey: 'page_body.`ROW`, page_body.`COLUMN`, page_body.`ITEM`', order: 'ascend' }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({row: res.data})
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            })
        }).catch(() => POST_CATCH()).finally(() => this.setState({term: true}))

    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/painel'} />)
        }

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col xs={24} md={16}>
                                <Form layout="vertical" onFinish={this.onSendAccount} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                    <Button size="large" className="fabbutton btn-primary" shape="circle" htmlType="submit"><IoCheckmark size={22} color="#FFF"/></Button>
                                    <Row gutter={[8,8]}>
                                        <Col span={24}>
                                            <Card title="Meus Dados" bordered size="small" actions={[<Typography className="text-info">(*) Essas são informações internas do sistema sobre sua conta, fique tranquilo (a), elas não serão divulgadas.</Typography>]} >
                                                <Row gutter={[16,0]}>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="LOGIN" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                            <ReactInputMask disabled className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                                        </Form.Item>
                                                        <Switch disabled unCheckedChildren="CPF" checkedChildren="CNPJ" defaultChecked={this.state.doc} style={{position: 'absolute', right: 13, top: 35}} />
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                            <Input addonBefore={<IoMailOutline />} type='email' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Whatsapp" name="WHATSAPP" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                            <span class="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                <span class="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                    <span class="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                                    <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.WHATSAPP} />
                                                                </span>
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    { this.state.doc ? (
                                                        <>
                                                            <Col xs={24} md={10}>
                                                                <Form.Item label="Razão Social" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                                    <Input type='text' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} md={8}>
                                                                <Form.Item label="Nome Fantasia" name="FANTASY">
                                                                    <Input type='text' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} md={6}>
                                                                <Form.Item label="Início Atividade" name="INITIALDATE">
                                                                    <Input type='date' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} md={8}>
                                                                <Form.Item label="Responsável | Nome" name="RESP_NAME">
                                                                    <Input type='text' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} md={8}>
                                                                <Form.Item label="Responsável | E-mail" name="RESP_EMAIL">
                                                                    <Input addonBefore={<IoMailOutline />} type='email' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} md={8}>
                                                                <Form.Item label="Responsável | Telefone" name="RESP_PHONE">
                                                                    <span class="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                        <span class="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                            <span class="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                                            <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.RESP_PHONE} />
                                                                        </span>
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Col xs={24} md={24}>
                                                                <Form.Item label="Nome Completo" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                                    <Input type='text' />
                                                                </Form.Item>
                                                            </Col>
                                                        </>
                                                    ) }
                                                    <Col xs={24} md={6}>
                                                        <Form.Item label="CEP" name="CEP">
                                                            <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" onBlur={this.buscaCEP} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={14}>
                                                        <Form.Item label="Endereço" name="STREET">
                                                            <Input type='text' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={4}>
                                                        <Form.Item label="Número" name="NUMBER">
                                                            <Input type='text' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={10}>
                                                        <Form.Item label="Bairro" name="DISTRICT">
                                                            <Input type='text' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={10}>
                                                        <Form.Item label="Cidade" name="CITY">
                                                            <Input type='text' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={4}>
                                                        <Form.Item label="UF" name="STATE">
                                                            <Input type='text' maxLength={2} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={16}>
                                                        <Form.Item label="Outro e-mail" name="EMAIL2">
                                                            <Input addonBefore={<IoMailOutline />} type='email' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Outro Telefone" name="PHONE">
                                                            <span class="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev">
                                                                <span class="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev">
                                                                    <span class="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                                    <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.PHONE} />
                                                                </span>
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Instagram" name="INSTAGRAM">
                                                            <Input addonBefore={<IoLogoInstagram />} type='url' placeholder="Colar URL completa" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Facebook" name="FACEBOOK">
                                                            <Input addonBefore={<IoLogoFacebook />} type='url' placeholder="Colar URL completa" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Twitter" name="TWITTER">
                                                            <Input addonBefore={<IoLogoTwitter />} type='url' placeholder="Colar URL completa" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="LinkedIn" name="LINKEDIN">
                                                            <Input addonBefore={<IoLogoLinkedin />} type='url' placeholder="Colar URL completa" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Youtube" name="YOUTUBE">
                                                            <Input addonBefore={<IoLogoYoutube />} type='url' placeholder="Colar URL completa" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Site" name="SITE">
                                                            <Input addonBefore={<IoLinkOutline />} type='url' placeholder="Colar URL completa" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        { verifyConfig('mct.pln') ? (
                                            <Col span={24}>
                                                <Card title="Meu Plano de Assinatura" bordered size="small" actions={[<Typography className="text-info">(*) Essas são informações internas do sistema sobre sua conta, fique tranquilo (a), elas não serão divulgadas.</Typography>]}>
                                                    <Row gutter={[16,0]}>
                                                        <Col span={24}>
                                                            <Typography>Plano atual: <Tag>{this.state.defaultValue.PLAN_NAME}</Tag> <Link to="alterarplano"><Button type="primary" shape="round" size="small">Solicitar Alteração</Button></Link></Typography>
                                                            <Typography>Descrição do Plano: {this.state.defaultValue.PLAN_DESC}</Typography>
                                                            <Typography>Data contratação: <b>{this.state.defaultValue.DATETIME_INSERT_FORMAT}</b></Typography>
                                                            <Typography>Data da próxima renovação automática: <b>{this.state.defaultValue.DATETIME_DUE_FORMAT}</b></Typography>
                                                            <Typography className="text-info" style={{paddingLeft: 0}}>Nota explicativa: Todos nossos planos possuem renovação automática, verifique sua conta e organize-se.</Typography>
                                                            {/* { this.state.defaultValue.PLAN_ID == 1 ? null : <Typography style={{marginTop: 20}}>Forma de Pagamento: <Tag>{this.state.defaultValue.PAYMENT_METHOD_NAME}</Tag> <Button onClick={this.onPayment} type="primary" shape="round" size="small">Solicitar Alteração</Button></Typography> } */}
                                                            <Typography style={{marginTop: 20}}><Button onClick={this.state.defaultValue.PLAN_ID === '1' ? null : () => this.onSelectPlan('Ao cancelar sua assinatura sua conta entrará no plano "Grátis"?', '1')} disabled={this.state.defaultValue.PLAN_ID === '1'} type="default" shape="round" size="small">Solicitar Cancelamento da Assinatura</Button></Typography>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        ) : null }
                                        <Col span={24}>
                                            <Card title="Políticas e Termos" bordered size="small" actions={[<Typography className="text-info">(*) Essas são informações internas do sistema sobre sua conta, fique tranquilo (a), elas não serão divulgadas.</Typography>]}>
                                                <Row gutter={[16,0]}>
                                                    <Col span={24}>
                                                        { this.state.pages.map((v, i) => (
                                                            <Typography onClick={() => this.onOpenTerm(v.ID, v.VER)} className="text-link" key={i}><IoDocumentOutline />  {v.TITLE}</Typography>
                                                        )) }
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                        <Col xs={24} md={8}>
                            <Row gutter={[8,8]}>
                                <Col span={24}>
                                    <Card bordered size="small" style={{paddingTop: 20}}>
                                        <Row justify="center" align={'middle'} style={{flexDirection:  'column'}}>
                                            <Avatar size={200} shape="circle" src={this.state.defaultValue?.AVATAR} style={{marginBottom: 12}}/>
                                            <ImgCrop rotationSlider>
                                                <Upload maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={this.onChangePic} className={`admin-profile-upload`}>
                                                    <Button shape="circle" className="btn-nav" type="default"><IoCameraOutline size={20} /></Button>
                                                </Upload>
                                            </ImgCrop>
                                            <Typography.Paragraph editable={{ tooltip: false, onChange: this.onChangeName }} className={`admin-profile-name`}>{this.state.defaultValue?.INTER_NAME}</Typography.Paragraph>
                                            <Tag color={this.state.defaultValue?.STATUS_COLOR}>{this.state.defaultValue?.CREDENTIAL_TYPE_NAME}</Tag>
                                            <Divider />
                                            <Form onFinish={this.onSend}>
                                                <Form.Item label="Senha atual" name="OLD_PASSWORD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                    <Input type="password"/>
                                                </Form.Item>
                                                <Form.Item label="Nova senha" name="NEW_PASSWORD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                    <Input type="password"/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button shape="round" htmlType="submit" className="btn-primary" block type="primary">Alterar Senha</Button>
                                                </Form.Item>
                                            </Form>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Modal open={this.state.modal} onCancel={this.onPayment} title="Alterar forma de pagamento" footer={false}>
                        <Form layout="vertical" onFinish={this.onSendPayment}>
                            <Form.Item name='PAYMENT_METHOD_ID' rules={[{required: true, message: 'Campo obrigatório*'}]} >
                                <Select placeholder="Selecione um item...">
                                    { this.state.method.map((v, i) => <Select.Option value={v.ID} key={i}>{v.NAME}</Select.Option>) }
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal open={this.state.term} footer={false} onCancel={() => this.setState({term: false})} style={{top: 30}} width={900}>
                        <Row>
                            <Col span={24}><Typography className="home-row-page-text-title">{this.state.valuePage?.TITLE}</Typography></Col>
                            <Col span={24} style={{height: 400, overflowY: 'auto', border: '1px solid black', borderRadius: 8, padding: 10}}>
                            { this.state.row && this.state.row.map((v, i) => (
                                <>
                                    <Row gutter={[16,0]} key={i}>
                                        { v.columns.map((cv, ci) => (
                                            <Col key={ci} span={24/v.type}>
                                                <Row>
                                                    { cv.items.map((iv, ii) => (
                                                        <Col span={24} key={ii} style={{marginBottom: 10}}>
                                                            { iv.type === 'T' ? <Typography className="policy-row-title" style={{marginTop: 30, marginBottom: 20}}>{iv.value}</Typography> : null }
                                                            { iv.type === 'P' ? <Typography className="policy-row-subtitle">{iv.value}</Typography> : null }
                                                            { iv.type === 'L' ? <Typography className="policy-row-subtitle-line">{iv.value}</Typography> : null }
                                                        </Col>
                                                    )) }
                                                </Row>
                                            </Col>
                                        )) }
                                    </Row>
                                </>
                            )) }
                            <Col span={24}><Typography className="home-row-page-version">Versão {this.state.valuePage?.VER} - Atualizada em {this.state.valuePage?.DATE_VER}</Typography></Col>
                            </Col>
                        </Row>
                    </Modal>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminMinhaConta;