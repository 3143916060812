import React from "react";
import { Avatar, Card, Carousel, Col, Row, Spin, Typography, message } from 'antd';

import { POST_API, POST_CATCH, getToken } from "../../services/api";

class WebPerfilEquipe extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        profile: null,
        _equipe_list: [],
        _equipe_loading: true,
    }

    componentDidMount = () => {
        this.onView()
        this.onRenderDataEquipe()
    }

    onView = async () => {
        await POST_API('/credential/search.php', { token: getToken(), filter: JSON.stringify({ID: this.state._params[4]}), type: 'view' }).then(rs => rs.json()).then(res => {
            this.setState({profile: res.data, _screen: false})
        }).catch(() => POST_CATCH())
    }

    onRenderDataEquipe = () => {
        this.setState({_equipe_loading: true })
        setTimeout(async () => {
            POST_API('/team/search.php', { token: getToken(), filter: JSON.stringify({CREDENTIAL_ID: this.state._params[4]}), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _equipe_list: res.data,
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({_equipe_loading: false }))
        }, 500);
    }

    render = () => {

        return (
            
            <Row className="perfil-content-row" gutter={[16, 16]}>
                <Col md={24} xs={24}>
                    <Card title="Nossa Equipe">
                        { this.state._equipe_loading ? <Col span={24}><center><Spin /></center></Col> : (
                            <Row gutter={[16,16]}>
                                { this.state._equipe_list.length > 0 ? (
                                    <Col span={24}>
                                        <Carousel className="equipe-carousel" autoplay>
                                            { this.state._equipe_list.map((item, index) => (
                                                <div key={index} className="equipe-carousel-item" >
                                                    <center><Avatar size={150} src={item.AVATAR} /></center>
                                                    <center><Typography className="equipe-title">{item.NAME}</Typography></center>
                                                    <center><Typography className="equipe-text">{item.DESC}</Typography></center>
                                                </div>
                                            )) }
                                        </Carousel>
                                    </Col>
                                ) : <Col span={24}><Typography className="no-data">Sem Equipe</Typography></Col> }
                            </Row>
                        ) }
                    </Card>
                </Col>     
            </Row>

        )

    }

}

export default WebPerfilEquipe;