import React from "react";
import { Layout, Row, Col, Typography, Button, Input, Switch, message } from 'antd';
import { FaFacebookF, FaGoogle, FaLinkedin } from "react-icons/fa";
import { IoArrowUndo, IoLockOpen, IoMailOpen, IoPerson } from "react-icons/io5";
import { Link, Navigate } from 'react-router-dom';
import { POST_API, POST_CATCH, setToken, setType } from "../../services/api";

class AuthForgot extends React.Component {
    
    state = {
        _navigate: false,
        load: false,
        side: 0,
        doc: false,
        form: {
            email: '',
        }
    }

    onChangeValue = (field, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value
            }
        })
    }

    onSend = async () => {

        const { email } = this.state.form
        if ( email === '' ) {
            message.warning('Por favor, preencha todos os campos')
        } else {

            this.setState({load: true})
            const values = {
                EMAIL: email,
            }

            await POST_API('/credential/forgot.php', {token: 'X', master: JSON.stringify(values)}).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success(res.msg)
                    this.setState({_navigate: true})
                } else {
                    message.warning(res.msg)
                }
            }).catch(() => { POST_CATCH() }).finally(() => {
                this.setState({load: false})
            })

        }

    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/login" />
        }

        return (
            <Layout.Content className="web-body">
                <Layout.Content className="auth-content">
                    <Row className="auth-box-login">
                        <Col className={"auth-col active all"}>
                            <Link to="/login"><Button shape="circle" className="btn-primary btn-auth" type="primary"><IoArrowUndo size={22} /></Button></Link>
                            <Typography className="auth-col-title">Esqueceu a senha?</Typography>
                            <Typography style={{marginTop: 10}} className="auth-col-text color-white">Preencha seu e-mail que enviaremos uma nova senha para você</Typography>
                            <Input size="large" prefix={<IoMailOpen />} className="auth-input" placeholder="E-mail" defaultValue={this.state.form.email} onChange={(v) => this.onChangeValue('email', v.target.value)} />
                            <div className="plan-card-button"><Button type="default" className="btn-outline" shape="round" loading={this.state.load} onClick={this.onSend}>ENVIAR E-MAIL</Button></div>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout.Content>

        )

    }

}

export default AuthForgot;