import React from 'react'
import './styles.css'

import { IoLogoWhatsapp, IoMailOutline } from 'react-icons/io5'

class HeaderMeuSite extends React.Component {
    render() {
        const { imagem, nomeEscritorio, especialidades, email, contato } = this.props

        return (
            <header className='header-meu-site'>
                {imagem}
                <div className="about">
                    <div className="sobre">
                        <p>Escritório: {nomeEscritorio}</p>
                        <div className='separador'></div>
                        <div className="especialidades divisor-simples">
                            <p id='especialidades-text'>Especialidades:</p>
                            {especialidades && especialidades.map((v,i) => <span>{v}</span>)}
                        </div>
                    </div>
                    <div className="contato divisor-simples">
                        <IoMailOutline /><p>Email: {email}</p>
                        <div className='separador'></div>
                        <IoLogoWhatsapp /><p>Contato: {contato}</p>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderMeuSite