import React from "react";
import { Breadcrumb, Button, Col, Layout, Row, Typography, message, Checkbox, Switch } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, URL_API, getToken, titleWeb, verifyConfig } from "../../../services/api";

import { FiEdit3 } from 'react-icons/fi';

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

class AdminComunicacao extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: 0,
        profile: null,
        date: '',
        // RENDERS
        select01: false,
        select02: false,
        select03: false,
    }

    routes = [
        {title: 'Comunicação'}
    ]

    componentDidMount = () => {
        titleWeb('Processos')
        this.onLoad()
    }

    onLoad = () => {
        POST_API('/comunication/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    defaultValue: res.data[0].VALUE,
                    select01: res.data[0].ACTIVE === 'S',
                    select02: res.data[1].ACTIVE === 'S',
                    select03: res.data[2].ACTIVE === 'S',
                })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => window.scrollTo(0, 0))
    }

    onEdit = (id, value) => {
        POST_API('/comunication/save.php', { token: getToken(), master: JSON.stringify({ ID: id, ACTIVE: value.target.checked ? 'S' : 'N' }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.onLoad()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    onEditValue = (id, value) => {
        POST_API('/comunication/save.php', { token: getToken(), master: JSON.stringify({ ID: id, VALUE: value }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.onLoad()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        </Col>
                        { false ? <PageLoad /> : (
                            <Col span={24}>
                                <Row className="admin-form" gutter={[8,8]}>
                                    <Col span={24}>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col flex='30px'><Checkbox checked={this.state.select01} onChange={(e) => this.onEdit(1, e)}/></Col>
                                            <Col flex='auto'><Typography className="prestacao-valor"><b>Tempo sem Movimentação no Processo</b></Typography><Typography className="prestacao-subtitulo">Ao passar X dias sem ter alteração nos dados do processo, será enviada uma comunicação automática para o seu cliente.</Typography></Col>
                                            <Col flex='150px'><Row className="prestacao-valor" justify={'space-between'}><Col style={{height: 23}}><b>Dias</b></Col><Col style={{height: 23}}><Typography.Paragraph editable={ { onChange: (t) => this.onEditValue(1, t), text: this.state.defaultValue, tooltip: false, icon:<FiEdit3 color="#000" /> }}>{this.state.defaultValue}</Typography.Paragraph></Col></Row></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col flex='30px'><Checkbox checked={this.state.select02} onChange={(e) => this.onEdit(2, e)}/></Col>
                                            <Col flex='auto'><Typography className="prestacao-valor"><b>Novo Processo Cadastrado para o Cliente</b></Typography><Typography className="prestacao-subtitulo">Ao cadastrar um novo processo para o cliente X, esse será comunicado.</Typography></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col flex='30px'><Checkbox checked={this.state.select03} onChange={(e) => this.onEdit(3, e)}/></Col>
                                            <Col flex='auto'><Typography className="prestacao-valor"><b>Atualização de Processo (Comentários)</b></Typography><Typography className="prestacao-subtitulo">Ao cadastrar um novo comentário no cliente, no processo ou na tarefa dentro do sistema, e estiver marcado a opção de enviar para o cliente, ele será comunicado.</Typography></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminComunicacao;