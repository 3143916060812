import React from "react";
import { Avatar, Breadcrumb, Button, Col, Form, Layout, Modal, Row, Switch, Tag, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoCameraOutline, IoLockOpen, IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, getUPLOADAPI, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';
import ReactInputMask from "react-input-mask";

class AdminBanners extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        banner01: '',
        banner02: '',
    }

    back = "/painel/banners";

    routes = [
        {title: <Link to={this.back}>Banners</Link>}
    ]

    componentDidMount = () => {
        titleWeb('Banners')
        this.onView()
    }
    
    // LOAD DATA
    onView = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/banner/search.php', { token: getToken(), type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({ banner01: res.data.BANNER01, banner02: res.data.BANNER02 })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onChangeBanner01 = (value) => {
        if (value.file.response?.url) {
            POST_API('/banner/save.php', { token: getToken(), master: JSON.stringify({ BANNER01: value.file.response?.url }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangeBanner02 = (value) => {
        if (value.file.response?.url) {
            POST_API('/banner/save.php', { token: getToken(), master: JSON.stringify({ BANNER02: value.file.response?.url }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    render = () => {
        
        if (this.state._navigate) return (<Navigate to={'/painel'} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.del`) && this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.trash`) ? <Link to={'trash'}><Button onClick={this.onChangePage} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Lixeira</Button></Link> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.add`) && this.state._plan ? <Button onClick={this.newAdvogado} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button> : null }
                            { this.props.type === 'trash' && verifyConfig(`${this.props.per}.rec`) && this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                            { this.props.type === 'trash' && verifyConfig(`${this.props.per}.list`) ? <Link to={this.back}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link> : null }
                        </Col>
                        <Col md={12} xs={24}>
                            <div className="dash-banner">
                                <Row justify="center" align={'middle'} style={{ flexDirection: 'column' }}>
                                    { this.state.banner01 ? <img src={this.state.banner01} className="image-banner" /> : null }
                                    <Upload maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={this.onChangeBanner01} className={`banner-profile-upload`}>
                                        <Button shape="circle" className="btn-nav" type="default"><IoCameraOutline size={20} /></Button>
                                    </Upload>
                                </Row>
                            </div>
                        </Col>
                        <Col md={12} xs={24}>
                            <div className="dash-banner">
                            <Row justify="center" align={'middle'} style={{ flexDirection: 'column' }}>
                                    { this.state.banner02 ? <img src={this.state.banner02} className="image-banner" /> : null }
                                    <Upload maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={this.onChangeBanner02} className={`banner-profile-upload`}>
                                        <Button shape="circle" className="btn-nav" type="default"><IoCameraOutline size={20} /></Button>
                                    </Upload>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminBanners;