import React from "react";
import { Col, Row, Breadcrumb, Typography, Button } from "antd";
import { Link } from "react-router-dom";

class WebRegister extends React.Component {

    breadcrumb = [
        { title: <Link to='/'><Typography className="home-breadcrumb-link">Home</Typography></Link> },
        { title: <Typography className="home-breadcrumb-link active">Cadastre-se</Typography> }
    ]

    render = () => {

        return (
            
            <Row>
                <Col span={24} className="home-col-black">
                    <Row align={'middle'} className="home-row-breadcrumb">
                        <Col span={12}>
                            <Breadcrumb className="home-breadcrumb" items={this.breadcrumb} />
                        </Col>
                        <Col span={12}>
                            <Typography className="home-breadcrumb-title">Cadastre-se</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[60,16]} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title">Cadastre-se</Typography></Col>
                        <Col span={24}>
                            <Button></Button>
                        </Col>
                        <Col span={24}>
                            <Typography className="about-row-title">SOU ADVOGADO</Typography>
                        </Col>
                        <Col span={24}>
                            
                        </Col>
                        <Col span={24}>
                            <Typography className="about-row-title">SOU CLIENTE</Typography>
                        </Col>
                        <Col span={24}>
                            
                        </Col>
                    </Row>
                </Col>
            </Row>
           
        )

    }

}

export default WebRegister;