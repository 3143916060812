import React from "react";
import { Avatar, Breadcrumb, Button, Checkbox, Col, Form, Input, Layout, List, Row, Select, Spin, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig, BLOCK_FORM_ENTER } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';
import { IoCheckmarkDone, IoClose } from "react-icons/io5";

class AdminTarefaForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        _filters: { STATUS: [] },
        part_loading: false,
        part_data: [],
        part_set: [],
        link_process: false,
        process_loading: false,
        process_data: [],
        process_set: [],
        link_client: false,
        client_loading: false,
        client_data: [],
        client_set: [],
    }

    back = "/painel/tarefas";

    routes = [
        {title: <Link to={this.back}>Tarefas</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]

    componentDidMount = () => {
        titleWeb('Tarefas')
        window.addEventListener('keypress', BLOCK_FORM_ENTER)
        
        this.onFilter().then(() => {
            if (this.props.type === 'add') {
                this.setState({_screen: false, defaultValue: { TIME_FINISH: '08:00' }})
                if (this.state._params[6] > 0 && this.state._params[7] === 'process') {
                    POST_API('/process/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[6]}) }).then(rs => rs.json()).then(res => {
                        if (res.return) {
                            this.setState({ link_process: true, process_set: [ res.data ] })
                        } else {
                            message.warning({ content: res.msg, key: 'screen' })
                        }
                    }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
                }
                if (this.state._params[6] > 0 && this.state._params[7] === 'client') {
                    POST_API('/client/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[6]}) }).then(rs => rs.json()).then(res => {
                        if (res.return) {
                            this.setState({ link_client: true, client_set: [ res.data ] })
                        } else {
                            message.warning({ content: res.msg, key: 'screen' })
                        }
                    }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
                }
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onFilter = async () => {
        POST_API('/task/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_filters: {
                    STATUS: res.data.STATUS,
                }})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    onView = async () => {
        POST_API('/task/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, part_set: res.data.PART})
                if (res.data.PROCESS_ID > 0) {
                    this.setState({
                        link_process: true,
                        process_set: [ { ID: res.data.PROCESS_ID, NUMBER: res.data.PROCESS_NUMBER, PROCESS_STATUS_NAME: res.data.PROCESS_STATUS_NAME } ]
                    })
                }
                if (res.data.CLIENT_ID > 0) {
                    this.setState({
                        link_client: true,
                        client_set: [ { ID: res.data.CLIENT_ID, AVATAR: res.data.CLIENT_AVATAR, NAME: res.data.CLIENT_NAME } ]
                    })
                }
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.PART = this.state.part_set;
        values.PROCESS_ID = (this.state.link_process) ? this.state.process_set[0].ID : null;
        values.CLIENT_ID = (this.state.link_client) ? this.state.client_set[0].ID : null;
        POST_API('/task/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    onSearchPart = (v) => {
        this.setState({part_loading: true})
        POST_API('/credential/search.php', { token: getToken(), pagination: JSON.stringify({current: 1, total: 0, limit: 10}), search: v, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    part_data: res.data
                })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({part_loading: false }))
    }

    onSearchProcess = (v) => {
        this.setState({process_loading: true})
        POST_API('/process/search.php', { token: getToken(), pagination: JSON.stringify({current: 1, total: 0, limit: 10}), search: v, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    process_data: res.data
                })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({process_loading: false }))
    }

    onSearchClient = (v) => {
        this.setState({client_loading: true})
        POST_API('/client/search.php', { token: getToken(), pagination: JSON.stringify({current: 1, total: 0, limit: 10}), search: v, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    client_data: res.data
                })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({client_loading: false }))
    }
    
    onSelectPart = (v) => {

        var verify = true;
        this.state.part_set.map((i) => {
            if (i.ID === v.ID) verify = false; 
            return null;
        })
        
        if (verify) {
            this.setState({
                part_data: [],
                part_set: [
                    ...this.state.part_set,
                    v
                ]
            })
        } else { this.setState({part_data: [] }) }

    }
    onDiselectPart = (i) => {
        var temp = this.state.part_set;
        temp.shift(i, 1);
        this.setState({ part_set: temp })
    }

    onSelectProcess = (v) => {
        this.setState({process_data: [], process_set: [ v ]})
    }

    onSelectClient = (v) => {
        this.setState({client_data: [], client_set: [ v ]})
    }

    render = () => {

        if (this.state._navigate)  return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />  

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={13}>
                                            <Form.Item label="Título" name="TITLE" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Item label="Situação" name="STATUS" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear placeholder="Selecione...">
                                                    { this.state._filters.STATUS.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option> )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={8} md={4}>
                                            <Form.Item label="Data Conclusão" name="DATE_FINISH" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='date' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6} md={3}>
                                            <Form.Item label="Hora Conclusão" name="TIME_FINISH" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='time' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Descrição" name="DESC">
                                                <Input.TextArea type='text' rows={4} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Responsáveis">
                                                <Input.Search placeholder="Procurar responsáveis..." onSearch={this.onSearchPart} />
                                                { this.state.part_loading ? <center><Spin style={{marginTop: 10}} /></center> : (
                                                    <>
                                                        { this.state.part_data.length > 0 ? (
                                                            <List
                                                                itemLayout="horizontal"
                                                                dataSource={this.state.part_data}
                                                                renderItem={(item, index) => (
                                                                    <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_recover" title="Marcar" onClick={() => this.onSelectPart(item)}><IoCheckmarkDone size={22}/></Button>]}>
                                                                        <List.Item.Meta
                                                                            avatar={<Avatar src={item.AVATAR} />}
                                                                            title={<Typography>{item.NAME}</Typography>}
                                                                            description={item.TYPE_NAME}
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        ) : null }
                                                    </>
                                                )}
                                                { this.state.part_set.length > 0 ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={this.state.part_set}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_delete" title="Desmarcar" onClick={() => this.onDiselectPart(index)}><IoClose size={22}/></Button>]}>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar src={item.AVATAR} />}
                                                                    title={<Typography>{item.NAME}</Typography>}
                                                                    description={item.TYPE_NAME}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : null }
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Checkbox style={{marginBottom: 10}} checked={this.state.link_process} onChange={(v) => this.setState({link_process: v.target.checked})}>Vincular Processo</Checkbox>
                                            { this.state.link_process ? (
                                                <Form.Item label="Selecionar processo">
                                                    <Input.Search placeholder="Procurar processo..." onSearch={this.onSearchProcess} />
                                                    { this.state.process_loading ? <center><Spin style={{marginTop: 10}} /></center> : (
                                                        <>
                                                            { this.state.process_data.length > 0 ? (
                                                                <List
                                                                    itemLayout="horizontal"
                                                                    dataSource={this.state.process_data}
                                                                    renderItem={(item, index) => (
                                                                        <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_recover" title="Marcar" onClick={() => this.onSelectProcess(item)}><IoCheckmarkDone size={22}/></Button>]}>
                                                                            <List.Item.Meta
                                                                                title={<Typography>{item.NUMBER}</Typography>}
                                                                                description={item.PROCESS_STATUS_NAME}
                                                                            />
                                                                        </List.Item>
                                                                    )}
                                                                />
                                                            ) : null }
                                                        </>
                                                    )}
                                                    { this.state.process_set.length > 0 ? (
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={this.state.process_set}
                                                            renderItem={(item, index) => (
                                                                <List.Item key={index}>
                                                                    <List.Item.Meta
                                                                        title={<Typography>{item.NUMBER}</Typography>}
                                                                        description={item.PROCESS_STATUS_NAME}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    ) : null }
                                                </Form.Item>
                                            ) : null }
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Checkbox style={{marginBottom: 10}} checked={this.state.link_client} onChange={(v) => this.setState({link_client: v.target.checked})}>Vincular Cliente</Checkbox>
                                            { this.state.link_client ? (
                                                <Form.Item label="Selecionar cliente">
                                                    <Input.Search placeholder="Procurar cliente..." onSearch={this.onSearchClient} />
                                                    { this.state.client_loading ? <center><Spin style={{marginTop: 10}} /></center> : (
                                                        <>
                                                            { this.state.client_data.length > 0 ? (
                                                                <List
                                                                    itemLayout="horizontal"
                                                                    dataSource={this.state.client_data}
                                                                    renderItem={(item, index) => (
                                                                        <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_recover" title="Marcar" onClick={() => this.onSelectClient(item)}><IoCheckmarkDone size={22}/></Button>]}>
                                                                            <List.Item.Meta
                                                                                avatar={<Avatar src={item.AVATAR} />}
                                                                                title={<Typography>{item.NAME}</Typography>}
                                                                                description={item.TYPE_NAME}
                                                                            />
                                                                        </List.Item>
                                                                    )}
                                                                />
                                                            ) : null }
                                                        </>
                                                    )}
                                                    { this.state.client_set.length > 0 ? (
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={this.state.client_set}
                                                            renderItem={(item, index) => (
                                                                <List.Item key={index}>
                                                                    <List.Item.Meta
                                                                        avatar={<Avatar src={item.AVATAR} />}
                                                                        title={<Typography>{item.NAME}</Typography>}
                                                                        description={item.TYPE_NAME}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    ) : null }
                                                </Form.Item>
                                            ) : null }
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminTarefaForm;