import { Breadcrumb, Col, Input, Layout, Row, Segmented } from "antd";
import { verifyConfig } from "../../../services/api";
import DashCliente from "./Cliente";
import DashEscritorio from "./Escritorio";
import { useState } from "react";

import './index.css'
import DashPlataforma from "./Plataforma";

const Dash = () => {

    const routes = [
        {title: 'Dashboard'},
    ]

    const [ option, setOption ] = useState('actual')
    const [ dateStart, setDateStart ] = useState(null)
    const [ dateEnd, setDateEnd ] = useState(null)

    return (
        <Layout>
            <Layout.Content className={`admin-container`}>
                <Row gutter={[8,8]} align={'middle'} justify={'space-between'}>
                    <Col>
                        <Breadcrumb items={routes} />
                    </Col>
                    <Col>
                        <Row gutter={[8,8]} align={'middle'}>
                            <Col>
                                <Segmented value={option} onChange={setOption} options={[
                                    { label: 'Mês atual', value: 'actual' },
                                    { label: 'Mês anterior', value: 'before' },
                                    { label: 'Últimos 3 meses', value: 'last' },
                                    { label: 'Personalizado', value: 'custom' }
                                ]}/>
                            </Col>
                            { option === 'custom' ? (
                                <>
                                <Col><Input type="month" value={dateStart} onChange={(v) => setDateStart(v.target.value)}/></Col>
                                <Col>à</Col>
                                <Col><Input type="month" value={dateEnd} onChange={(v) => setDateEnd(v.target.value)}/></Col>
                                </>
                            ) : null }
                        </Row>
                    </Col>
                    <Col span={24}>
                        { verifyConfig('dsh.cln') ? <DashCliente option={option} dateStart={dateStart} dateEnd={dateEnd} /> : null }
                        { verifyConfig('dsh.esc') ? <DashEscritorio option={option} dateStart={dateStart} dateEnd={dateEnd} /> : null }
                        { verifyConfig('dsh.plt') ? <DashPlataforma option={option} dateStart={dateStart} dateEnd={dateEnd} /> : null }
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    )

}

export default Dash;