import React from "react";
import { Avatar, Breadcrumb, Button, Col, Form, Input, Layout, List, Modal, Radio, Row, Select, Spin, Tag, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig, BLOCK_FORM_ENTER } from "../../../services/api";

import { ExclamationCircleOutlined } from '@ant-design/icons';

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';
import ReactInputMask from "react-input-mask";
import { IoAddCircleOutline, IoCheckmarkDone, IoClose } from "react-icons/io5";

class AdminProcessForm extends React.Component {

    state = {
        _plan: false,
        _navigate: false,
        _navigate_duplicate: false,
        _duplicate_id: 0,
        _loading: false,
        _screen: true,
        tag_value: '',
        _params: window.location.href.split('/'),
        defaultValue: null,
        _filters: {
            STATE: [],
            CITY: [],
            PROCESS_STEP: [],
            PROCESS_COURT: [],
            PROCESS_STATUS: [],
            PROCESS_ACTION: [],
            PROCESS_INSTANCE: [],
            PROCESS_CHANCE_WIN: [],
        },
        process_step_other: false,
        process_status_other: false,
        process_court_other: false,
        process_action_other: false,
        process_instance_other: false,
        process_chance_win_other: false,
        // RENDERS
        client_loading: false,
        client_data: [],
        client_set: [],
        lawyer_loading: false,
        lawyer_data: [],
        lawyer_set: [],
        tag_loading: false,
        tag_data: [],
        tag_set: [],
        opposing_part_loading: false,
        opposing_part_data: [],
        opposing_part_set: [],
    }

    back = "/painel/processos";

    routes = [
        {title: <Link to={this.back}>Processos</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]

    componentDidMount = () => {
        titleWeb('Processos')
        this.onFilter().then(() => {
            if (this.props.type === 'add') {
                this.setState({_screen: false})
            } else if (this.props.type === 'edit') {
                this.onView()
            }
        })
    }

    onAdd = () => {
        var self = this
        Modal.confirm({
            title: 'Adicionar nova tag?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/tag/save.php', { token: getToken(), master: JSON.stringify({ NAME: self.state.tag_value, TYPE: 'P' }) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onSelectTag(res.new)
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false, _modal: false}))
            },
            onCancel() {},
        })
    }

    onView = async () => {
        POST_API('/process/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    _plan: res.option,
                    defaultValue: res.data,
                    client_set: res.data.CLIENTS,
                    lawyer_set: res.data.LAWYERS,
                    opposing_part_set: res.data.OPPOSING_PARTS,
                    tag_set: res.data.TAGS,
                })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onFilter = async () => {
        POST_API('/process/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_filters: {
                    CITY: res.data.CITY,
                    STATE: res.data.STATE,
                    PROCESS_STEP: res.data.PROCESS_STEP,
                    PROCESS_COURT: res.data.PROCESS_COURT,
                    PROCESS_STATUS: res.data.PROCESS_STATUS,
                    PROCESS_ACTION: res.data.PROCESS_ACTION,
                    PROCESS_INSTANCE: res.data.PROCESS_INSTANCE,
                    PROCESS_CHANCE_WIN: res.data.PROCESS_CHANCE_WIN,
                }})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.LAWYER = this.state.lawyer_set;
        values.CLIENT = this.state.client_set;
        values.OPPOSING_PART = this.state.opposing_part_set;
        values.TAG = this.state.tag_set;
        POST_API('/process/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    onChangeProcessStep = (v) => {
        if (v === '*') this.setState({process_step_other: true})
        if (v !== '*') this.setState({process_step_other: false})
    }
    onChangeProcessStatus = (v) => {
        if (v === '*') this.setState({process_status_other: true})
        if (v !== '*') this.setState({process_status_other: false})
    }
    onChangeProcessAction = (v) => {
        if (v === '*') this.setState({process_action_other: true})
        if (v !== '*') this.setState({process_action_other: false})
    }
    onChangeProcessCourt = (v) => {
        if (v === '*') this.setState({process_court_other: true})
        if (v !== '*') this.setState({process_court_other: false})
    }
    onChangeProcessInstance = (v) => {
        if (v === '*') this.setState({process_instance_other: true})
        if (v !== '*') this.setState({process_instance_other: false})
    }
    onChangeProcessChanceWin = (v) => {
        if (v === '*') this.setState({process_chance_win_other: true})
        if (v !== '*') this.setState({process_chance_win_other: false})
    }

    onSearchClient = (v) => {
        this.setState({client_loading: true})
        POST_API('/client/search.php', { token: getToken(), pagination: JSON.stringify({current: 1, total: 0, limit: 10}), search: v, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    client_data: res.data
                })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({client_loading: false }))
    }
    onSelectClient = (v) => {

        var verify = true;
        this.state.client_set.map((i) => {
            if (i.ID === v.ID) verify = false; 
            return null;
        })
        
        if (verify) {
            this.setState({
                client_data: [],
                client_set: [
                    ...this.state.client_set,
                    v
                ]
            })
        } else { this.setState({client_data: [] }) }

    }
    onDiselectClient = (i) => {
        var temp = this.state.client_set;
        temp.shift(i, 1);
        this.setState({ client_set: temp })
    }

    onSearchLawyer = (v) => {
        this.setState({lawyer_loading: true})
        POST_API('/lawyer/search.php', { token: getToken(), pagination: JSON.stringify({current: 1, total: 0, limit: 10}), search: v, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    lawyer_data: res.data
                })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({lawyer_loading: false }))
    }
    onSelectLawyer = (v) => {

        var verify = true;
        this.state.lawyer_set.map((i) => {
            if (i.ID === v.ID) verify = false; 
            return null;
        })
        
        if (verify) {
            this.setState({
                lawyer_data: [],
                lawyer_set: [
                    ...this.state.lawyer_set,
                    v
                ]
            })
        } else { this.setState({lawyer_data: [] }) }

    }
    onDiselectLawyer = (i) => {
        var temp = this.state.lawyer_set;
        temp.shift(i, 1);
        this.setState({ lawyer_set: temp })
    }

    onSearchTag = (v) => {
        this.setState({tag_loading: true})
        POST_API('/tag/search.php', { token: getToken(), pagination: JSON.stringify({current: 1, total: 0, limit: 10}), filter: JSON.stringify({TYPE: 'P'}), search: v, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    tag_data: res.data
                })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({tag_loading: false }))
    }
    onSelectTag = (v) => {

        var verify = true;
        this.state.tag_set.map((i) => {
            if (i.ID === v.ID) verify = false; 
            return null;
        })
        
        if (verify) {
            this.setState({
                tag_data: [],
                tag_set: [
                    ...this.state.tag_set,
                    v
                ]
            })
        } else { this.setState({tag_data: [] }) }

    }
    onDiselectTag = (i) => {
        var temp = this.state.tag_set;
        temp.shift(i, 1);
        this.setState({ tag_set: temp })
    }

    onSearchOpposingPart = (v) => {
        this.setState({opposing_part_loading: true})
        POST_API('/opposing_part/search.php', { token: getToken(), pagination: JSON.stringify({current: 1, total: 0, limit: 10}), filter: JSON.stringify({TYPE: 'P'}), search: v, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    opposing_part_data: res.data
                })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({opposing_part_loading: false }))
    }
    onSelectOpposingPart = (v) => {

        var verify = true;
        this.state.opposing_part_set.map((i) => {
            if (i.ID === v.ID) verify = false; 
            return null;
        })
        
        if (verify) {
            this.setState({
                opposing_part_data: [],
                opposing_part_set: [
                    ...this.state.opposing_part_set,
                    v
                ]
            })
        } else { this.setState({opposing_part_data: [] }) }

    }
    onDiselectOpposingPart = (i) => {
        var temp = this.state.opposing_part_set;
        temp.shift(i, 1);
        this.setState({ opposing_part_set: temp })
    }
    
    changeDuplicate = async (value) => {
        this.setState({_duplicate_id: value})
    }

    onDuplicate = () => {
        var self = this
        Modal.confirm({
            title: 'Deseja duplicar o processo?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/process/duplicate.php', { token: getToken(), ID: self.state.defaultValue.ID }).then(rs => rs.json()).then(async res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        await self.changeDuplicate(res.id).then(() => {
                            self.setState({_navigate_duplicate: true})
                        })
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    onChangeState = async (v) => {
        POST_API('/process/filters-city.php', { token: getToken(), 'STATE_ID': v }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_filters: {
                    ...this.state._filters,
                    CITY: res.data.CITY
                }})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        if (this.state._navigate_duplicate) {
            return (<Navigate to={this.back} />)
        }

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form onKeyDown={BLOCK_FORM_ENTER} layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Row gutter={[16,0]} align={"bottom"}>
                                        <Col xs={10} md={7}>
                                            <Form.Item name="ID" label="ID do Processo">
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={14} md={7}>
                                            <Form.Item name="DATE_FILING" label="Data do Ajuizamento" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type="date" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={10}>
                                            <Form.Item name="NUMBER" label="Número Processo" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask mask={'9999999-99.9999.9.99.9999'} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={this.state.process_step_other ? 10 : 24} md={this.state.process_step_other ? 4 : 14}>
                                            <Form.Item name="PROCESS_STEP_ID" label="Situação" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear onChange={this.onChangeProcessStep} placeholder="Selecione...">
                                                    { this.state._filters.PROCESS_STEP.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option> )) }
                                                    <Select.Option value="*">Outro</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        { this.state.process_step_other ? (
                                            <Col xs={14} md={10}>
                                                <Form.Item name="PROCESS_STEP_OTHER" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite aqui" />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        <Col xs={this.state.process_status_other ? 10 : 24} md={this.state.process_status_other ? 4 : 10}>
                                            <Form.Item name="PROCESS_STATUS_ID" label="Status" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear onChange={this.onChangeProcessStatus} placeholder="Selecione...">
                                                    { this.state._filters.PROCESS_STATUS.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option> )) }
                                                    <Select.Option value="*">Outro</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        { this.state.process_status_other ? (
                                            <Col xs={14} md={6}>
                                                <Form.Item name="PROCESS_STATUS_OTHER" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite aqui" />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        <Col xs={24} md={24}>
                                            <Form.Item name="TITLE" label="Título" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Clientes">
                                                <Input.Search placeholder="Procurar cliente..." onSearch={this.onSearchClient} />
                                                { this.state.client_loading ? <center><Spin style={{marginTop: 10}} /></center> : (
                                                    <>
                                                        { this.state.client_data.length > 0 ? (
                                                            <List
                                                                itemLayout="horizontal"
                                                                dataSource={this.state.client_data}
                                                                renderItem={(item, index) => (
                                                                    <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_recover" title="Marcar" onClick={() => this.onSelectClient(item)}><IoCheckmarkDone size={22}/></Button>]}>
                                                                        <List.Item.Meta
                                                                            avatar={<Avatar src={item.AVATAR} />}
                                                                            title={<Typography>{item.NAME}</Typography>}
                                                                            description={item.LOGIN}
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        ) : null }
                                                    </>
                                                )}
                                                { this.state.client_set.length > 0 ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={this.state.client_set}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_delete" title="Desmarcar" onClick={() => this.onDiselectClient(index)}><IoClose size={22}/></Button>]}>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar src={item.AVATAR} />}
                                                                    title={<Typography>{item.NAME}</Typography>}
                                                                    description={item.LOGIN}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : null }
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Partes Contrária">
                                                <Input.Search placeholder="Procurar parte contrária..." onSearch={this.onSearchOpposingPart} />
                                                { this.state.opposing_part_loading ? <center><Spin style={{marginTop: 10}} /></center> : (
                                                    <>
                                                        { this.state.opposing_part_data.length > 0 ? (
                                                            <List
                                                                itemLayout="horizontal"
                                                                dataSource={this.state.opposing_part_data}
                                                                renderItem={(item, index) => (
                                                                    <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_recover" title="Marcar" onClick={() => this.onSelectOpposingPart(item)}><IoCheckmarkDone size={22}/></Button>]}>
                                                                        <List.Item.Meta
                                                                            avatar={<Avatar src={item.AVATAR} />}
                                                                            title={<Typography>{item.NAME}</Typography>}
                                                                            description={item.LOGIN}
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        ) : null }
                                                    </>
                                                )}
                                                { this.state.opposing_part_set.length > 0 ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={this.state.opposing_part_set}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_delete" title="Desmarcar" onClick={() => this.onDiselectOpposingPart(index)}><IoClose size={22}/></Button>]}>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar src={item.AVATAR} />}
                                                                    title={<Typography>{item.NAME}</Typography>}
                                                                    description={item.LOGIN}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : null }
                                            </Form.Item>
                                        </Col>
                                        <Col xs={this.state.process_action_other ? 10 : 24} md={this.state.process_action_other ? 4 : 14}>
                                            <Form.Item name="PROCESS_ACTION_ID" label="Tipo de Ação" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear onChange={this.onChangeProcessAction} placeholder="Selecione...">
                                                    { this.state._filters.PROCESS_ACTION.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option> )) }
                                                    <Select.Option value="*">Outro</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        { this.state.process_action_other ? (
                                            <Col xs={14} md={10}>
                                                <Form.Item name="PROCESS_ACTION_OTHER" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite aqui" />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        <Col xs={this.state.process_court_other ? 10 : 24} md={this.state.process_court_other ? 4 : 10}>
                                            <Form.Item name="PROCESS_COURT_ID" label="Tribunal" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear onChange={this.onChangeProcessCourt} placeholder="Selecione...">
                                                    { this.state._filters.PROCESS_COURT.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option> )) }
                                                    <Select.Option value="*">Outro</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        { this.state.process_court_other ? (
                                            <Col xs={14} md={6}>
                                                <Form.Item name="PROCESS_COURT_OTHER" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite aqui" />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        <Col xs={8} md={4}>
                                            <Form.Item name="STATE" label="UF" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select onChange={this.onChangeState} onSearch={this.onSearch}>
                                                { this.state._filters.STATE.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.STATE}</Select.Option> )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={16} md={12}>
                                            <Form.Item name="CITY" label="Cidade" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select>
                                                { this.state._filters.CITY.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option> )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={this.state.process_instance_other ? 10 : 24} md={this.state.process_instance_other ? 3 : 8}>
                                            <Form.Item name="PROCESS_INSTANCE_ID" label="Instância" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear onChange={this.onChangeProcessInstance} placeholder="Selecione...">
                                                    { this.state._filters.PROCESS_INSTANCE.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option> )) }
                                                    <Select.Option value="*">Outro</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        { this.state.process_instance_other ? (
                                            <Col xs={14} md={5}>
                                                <Form.Item name="PROCESS_INSTANCE_OTHER" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite aqui" />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        <Col xs={24} md={24}>
                                            <Form.Item name="LOCAL" label="Foro | Juízo" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="MAGISTRATE" label="Magistrado" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="DETAILS" label="Detalhes/Resumo do Processo">
                                                <Input.TextArea rows={4} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item name="DEMAND" label="Justiça Gratuita" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Radio.Group>
                                                    <Radio value={'S'}>Sim</Radio>
                                                    <Radio value={'N'}>Não</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={8}>
                                            <Form.Item name="VALUE" label="Valor da Causa" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input prefix="R$" type="number" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={this.state.process_chance_win_other ? 10 : 24} md={this.state.process_chance_win_other ? 4 : 10}>
                                            <Form.Item name="PROCESS_CHANCE_WIN_ID" label="Chance Ganho" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Select allowClear onChange={this.onChangeProcessChanceWin} placeholder="Selecione...">
                                                    { this.state._filters.PROCESS_CHANCE_WIN.map((v, i) => ( <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option> )) }
                                                    <Select.Option value="*">Outro</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        { this.state.process_chance_win_other ? (
                                            <Col xs={14} md={6}>
                                                <Form.Item name="PROCESS_CHANCE_WIN_OTHER" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite aqui" />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Advogados">
                                                <Input.Search placeholder="Procurar advogado..." onSearch={this.onSearchLawyer} />
                                                { this.state.lawyer_loading ? <center><Spin style={{marginTop: 10}} /></center> : (
                                                    <>
                                                        { this.state.lawyer_data.length > 0 ? (
                                                            <List
                                                                itemLayout="horizontal"
                                                                dataSource={this.state.lawyer_data}
                                                                renderItem={(item, index) => (
                                                                    <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_recover" title="Marcar" onClick={() => this.onSelectLawyer(item)}><IoCheckmarkDone size={22}/></Button>]}>
                                                                        <List.Item.Meta
                                                                            avatar={<Avatar src={item.AVATAR} />}
                                                                            title={<Typography>{item.NAME}</Typography>}
                                                                            description={item.LOGIN}
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        ) : null }
                                                    </>
                                                )}
                                                { this.state.lawyer_set.length > 0 ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={this.state.lawyer_set}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_delete" title="Desmarcar" onClick={() => this.onDiselectLawyer(index)}><IoClose size={22}/></Button>]}>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar src={item.AVATAR} />}
                                                                    title={<Typography>{item.NAME}</Typography>}
                                                                    description={item.LOGIN}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : null }
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Tags">
                                                <Input.Search onChange={(v) => this.setState({tag_value: v.target.value})} placeholder="Procurar tag..." suffix={<IoAddCircleOutline size={20} style={{cursor: 'pointer'}} onClick={this.onAdd} />} onSearch={this.onSearchTag} />
                                                { this.state.tag_loading ? <center><Spin style={{marginTop: 10}} /></center> : (
                                                    <>
                                                        { this.state.tag_data.length > 0 ? (
                                                            <List
                                                                itemLayout="horizontal"
                                                                dataSource={this.state.tag_data}
                                                                renderItem={(item, index) => (
                                                                    <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_recover" title="Marcar" onClick={() => this.onSelectTag(item)}><IoCheckmarkDone size={22}/></Button>]}>
                                                                        <List.Item.Meta
                                                                            title={<Tag color={item.COLOR}>{item.NAME}</Tag>}
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        ) : null }
                                                    </>
                                                )}
                                                { this.state.tag_set.length > 0 ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={this.state.tag_set}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={index} actions={[<Button type="primary" shape="circle" className="table__btn_delete" title="Desmarcar" onClick={() => this.onDiselectTag(index)}><IoClose size={22}/></Button>]}>
                                                                <List.Item.Meta
                                                                    title={<Tag color={item.COLOR}>{item.NAME}</Tag>}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : null }
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            { this.props.type === 'edit' && this.state._plan ? <Button loading={this.state._loading} type="default" shape="round" className="float-left" onClick={this.onDuplicate}>Duplicar</Button> : null }
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminProcessForm;