import React from "react";
import { Col, Layout, Result, Row } from 'antd';
import { Navigate } from "react-router-dom";

class ControllerPage403 extends React.Component {

    state = {
        _navigate: false
    }

    onButton = () => {
        this.setState({_navigate: true})
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to="/" />);
        }

        return (
            <Content>
                <Row style={{height: '100vh', alignItems: 'center'}} justify="center">
                    <Col span={24}>
                        <Result
                            status="403"
                            title="403"
                            subTitle="Desculpe, você não tem autorização para acessar essa página."
                        />
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default ControllerPage403;