import React from "react";
import { Avatar, Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, QRCode, Row, Typography, Upload, message, Modal, Spin, List, Pagination, Select, Image } from "antd";
import { Navigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import { POST_API, getToken, getUPLOADAPI, titleWeb, setPath, POST_CATCH, verifyConfig } from "../../services/api";

// CONTROLLER
import PageLoad from '../Controller/PageLoad';

import { IoCameraOutline, IoLinkOutline, IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoCheckmark, IoCloudUploadOutline, IoPencil, IoTrash, IoMailOutline } from 'react-icons/io5';

import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';

import logo from '../../assets/img/logo/jurisvip-transparente.png';
import TimelineComponent from "../../components/Timeline";

class AdminMeuPerfil extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        special: [],
        defaultValue: null,
        doc: false,
        // NOSSA EQUIPE
        _equipe_list: [],
        _equipe_form: null,
        _equipe_type: '',
        _equipe_modal: false,
        _equipe_search: '',
        _equipe_loading: false,
        _equipe_pagination: {
            current: 1,
            total: 0,
            limit: 3
        },
        // CLIENTES
        _cliente_list: [],
        _cliente_form: null,
        _cliente_type: '',
        _cliente_modal: false,
        _cliente_search: '',
        _cliente_loading: false,
        _cliente_pagination: {
            current: 1,
            total: 0,
            limit: 3
        },
        // PUBLICAÇÕES
        _publicacoes_list: [],
        _publicacoes_form: null,
        _publicacoes_type: '',
        _publicacoes_modal: false,
        _publicacoes_search: '',
        _publicacoes_loading: false,
        _publicacoes_pagination: {
            current: 1,
            total: 0,
            limit: 3
        },
    }

    routes = [
        { title: 'Crie sua página aqui e divulgue seu escritório!' },
    ]

    componentDidMount = () => {
        titleWeb('Meu Perfil')
        setPath('credential')
        this.onLoad()
        this.onView()
        this.onRenderDataEquipe()
        this.onRenderDataCliente()
        this.onRenderDataPublicacoes()
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({ _screen: true })
        await fetch('https://viacep.com.br/ws/' + cep + '/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    defaultValue: {
                        ...this.state.defaultValue,
                        STREET: res.logradouro,
                        DISTRICT: res.bairro,
                        CITY: res.localidade,
                        STATE: res.uf,
                        CEP: cep
                    }
                })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({ _screen: false }))

    }

    // FUNCTIONS NOSSA EQUIPE
    openModalNossaEquipe = (type, record = null) => {
        this.setState({ _equipe_modal: true, _equipe_type: type, _equipe_form: record })
    }

    closeModalNossaEquipe = () => {
        this.setState({ _equipe_modal: false, _equipe_type: '', _equipe_form: null })
    }

    onSaveEquipe = (values) => {
        this.setState({ _equipe_loading: true })
        POST_API('/team/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({ _equipe_form: null, _equipe_modal: false, _equipe_type: '' })
                this.onRenderDataEquipe()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({ _equipe_loading: false }))
    }

    onActionEquipe = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/team/del.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen', })
                        self.onRenderDataEquipe()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => { POST_CATCH() }).finally(() => self.setState({ _equipe_loading: false }))
            },
            onCancel() { },
        })

    }

    onRenderDataEquipe = () => {
        this.setState({ _equipe_loading: true })
        setTimeout(async () => {
            POST_API('/team/search.php', { token: getToken(), pagination: JSON.stringify(this.state._equipe_pagination), search: this.state._equipe_search, type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _equipe_list: res.data,
                        _equipe_pagination: {
                            ...this.state._equipe_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({ _equipe_loading: false }))
        }, 500);
    }

    onPaginationChangeEquipe = (page, size) => {
        this.setState({
            _equipe_pagination: {
                ...this.state._equipe_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderDataEquipe()
    }

    onSearchChangeEquipe = (e) => {
        this.setState({
            _equipe_search: e.target.value
        })
        this.onRenderDataEquipe()
    }

    // FUNCTIONS CLIENTES
    openModalCliente = (type, record = null) => {
        this.setState({ _cliente_modal: true, _cliente_type: type, _cliente_form: record })
    }

    closeModalCliente = () => {
        this.setState({ _cliente_modal: false, _cliente_type: '', _cliente_form: null })
    }

    onSaveCliente = (values) => {
        this.setState({ _cliente_loading: true })
        POST_API('/client_team/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({ _cliente_form: null, _cliente_modal: false, _cliente_type: '' })
                this.onRenderDataCliente()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({ _cliente_loading: false }))
    }

    onActionCliente = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/client_team/del.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen', })
                        self.onRenderDataCliente()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => { POST_CATCH() }).finally(() => self.setState({ _cliente_loading: false }))
            },
            onCancel() { },
        })

    }

    onRenderDataCliente = () => {
        this.setState({ _cliente_loading: true })
        setTimeout(async () => {
            POST_API('/client_team/search.php', { token: getToken(), pagination: JSON.stringify(this.state._cliente_pagination), search: this.state._cliente_search, type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _cliente_list: res.data,
                        _cliente_pagination: {
                            ...this.state._cliente_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({ _cliente_loading: false }))
        }, 500);
    }

    onPaginationChangeCliente = (page, size) => {
        this.setState({
            _cliente_pagination: {
                ...this.state._cliente_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderDataCliente()
    }

    onSearchChangeCliente = (e) => {
        this.setState({
            _cliente_search: e.target.value
        })
        this.onRenderDataCliente()
    }

    // FUNCTIONS PUBLICAÇÕES
    openModalPublicacoes = (type, record = null) => {
        this.setState({ _publicacoes_modal: true, _publicacoes_type: type, _publicacoes_form: record })
    }

    closeModalPublicacoes = () => {
        this.setState({ _publicacoes_modal: false, _publicacoes_type: '', _publicacoes_form: null })
    }

    onSavePublicacoes = (values) => {
        this.setState({ _publicacoes_loading: true })
        POST_API('/timeline/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({ _publicacoes_form: null, _publicacoes_modal: false, _publicacoes_type: '' })
                this.onRenderDataPublicacoes()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({ _publicacoes_loading: false }))
    }

    onActionPublicacoes = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/timeline/del.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderDataPublicacoes()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => { POST_CATCH() }).finally(() => self.setState({ _publicacoes_loading: false }))
            },
            onCancel() { },
        })

    }

    onRenderDataPublicacoes = () => {
        this.setState({ _publicacoes_loading: true })
        setTimeout(async () => {
            POST_API('/timeline/search.php', { token: getToken(), pagination: JSON.stringify(this.state._publicacoes_pagination), search: this.state._publicacoes_search, type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _publicacoes_list: res.data,
                        _publicacoes_pagination: {
                            ...this.state._publicacoes_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({ _publicacoes_loading: false }))
        }, 500);
    }
    onRenderDataPublicacoesLoad = () => {
        setTimeout(async () => {
            POST_API('/timeline/search.php', { token: getToken(), pagination: JSON.stringify(this.state._publicacoes_pagination), search: this.state._publicacoes_search, type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _publicacoes_list: res.data,
                        _publicacoes_pagination: {
                            ...this.state._publicacoes_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({ _publicacoes_loading: false }))
        }, 500);
    }

    onPaginationChangePublicacoes = (page, size) => {
        this.setState({
            _publicacoes_pagination: {
                ...this.state._publicacoes_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderDataPublicacoes()
    }

    onSearchChangePublicacoes = (e) => {
        this.setState({
            _publicacoes_search: e.target.value
        })
        this.onRenderDataPublicacoes()
    }

    removePhoto = (ID) => {
        POST_API('/timeline/del_photo.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen', })
                this.setState({ _publicacoes_form: res.dados })
                this.onRenderDataPublicacoes()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => { POST_CATCH() })
    }

    changePagination = async () => {
        this.setState({
            _publicacoes_pagination: {
                ...this.state._publicacoes_pagination,
                limit: this.state._publicacoes_pagination.limit + 3
            }
        })
    }

    onLoadMore = async () => {
        await this.changePagination().then(() => this.onRenderDataPublicacoes())
    }

    // FUNCTION MY PROFILE

    onLoad = () => {
        POST_API('/specialty/search.php', { token: getToken(), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ special: res.data })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    onView = async () => {
        await POST_API('/credential/search.php', { token: getToken(), type: 'self' }).then(rs => rs.json()).then(res => {
            this.setState({ defaultValue: res.data, _screen: false })
            if (String(res.data.LOGIN).length > 14) this.setState({ doc: true })
        }).catch(() => POST_CATCH())
    }

    onSend = (values) => {
        this.setState({ _screen: true })
        POST_API('/credential/self-pass.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({ _screen: false }))
    }

    onSendAccount = (values) => {
        this.setState({ _loading: true })
        POST_API('/credential/self-saveall.php', { token: getToken(), master: JSON.stringify(this.state.defaultValue) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({ _loading: false }))
    }

    onChangePic = (value) => {
        if (value.file.response?.url) {
            this.setState({ _screen: true })
            POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({ INTER_AVATAR: value.file.response?.url }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangePicBanner = (value) => {
        if (value.file.response?.url) {
            this.setState({ _screen: true })
            POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({ BANNER: value.file.response?.url }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangeName = (value) => {
        this.setState({ _screen: true })
        POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({ NAME: value }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView())
    }

    downloadQRCode = async () => {
        const canvas = await html2canvas(document.getElementById('myqrcode'));
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'download.png', 'image/png');
    };

    onCopy = () => {
        window.navigator.clipboard.writeText(`https://jurisvip.adsolucoestecnologia.com.br/perfil/${this.state.defaultValue.ID}`);
        message.success({ content: 'URL copiada', key: 'screen' })
    }

    onChangeField = (a, b) => {
        if (a.length > 0) {
            var name = a[0].name[0]
            var value = a[0].value
            this.setState({
                defaultValue: {
                    ...this.state.defaultValue,
                    [name]: value
                }
            })
        }
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/painel'} />)
        }

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8, 8]}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        </Col>
                        {this.state._screen ? <PageLoad /> : (
                            <>
                                <>
                                    {verifyConfig(['PA', 'PC']) ? null : (
                                        <Col xs={24} md={16}>
                                            <Form layout="vertical" onFinish={this.onSendAccount} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                                <Button size="large" className="fabbutton btn-primary" shape="circle" htmlType="submit"><IoCheckmark size={22} color="#FFF" /></Button>
                                                <Row gutter={[8, 8]}>
                                                    <Col span={24}>
                                                        <Card title="Perfil Público" bordered size="small">
                                                            <Row gutter={[16, 0]}>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="Especialidade" name="SPECIALITY">
                                                                        <Select mode="multiple">
                                                                            {this.state.special.map((v, i) => (
                                                                                <Select.Option key={i} value={v.NAME}>{v.NAME}</Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="Apresentação" name="DESC">
                                                                        <Input.TextArea type='text' maxLength={1000} showCount rows={4} className="text-area" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="Sobre" name="ABOUT">
                                                                        <Input.TextArea type='text' maxLength={1000} showCount rows={4} className="text-area" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Endereço" bordered size="small">
                                                            <Row gutter={[16, 0]}>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label="CEP" name="CEP">
                                                                        <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" onBlur={this.buscaCEP} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={14}>
                                                                    <Form.Item label="Endereço" name="STREET">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="Número" name="NUMBER">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Bairro" name="DISTRICT">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Cidade" name="CITY">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="UF" name="STATE">
                                                                        <Input type='text' maxLength={2} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Contato" bordered size="small">
                                                            <Row gutter={[16, 0]}>
                                                                <Col xs={24} md={16}>
                                                                    <Form.Item label="E-mail" name="EMAIL">
                                                                        <Input addonBefore={<IoMailOutline />} type='email' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Whatsapp" name="WHATSAPP">
                                                                        <span className="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                            <span className="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                                <span className="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                                                <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.WHATSAPP} />
                                                                            </span>
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Instagram" name="INSTAGRAM">
                                                                        <Input addonBefore={<IoLogoInstagram />} type='url' placeholder="Colar URL completa" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Facebook" name="FACEBOOK">
                                                                        <Input addonBefore={<IoLogoFacebook />} type='url' placeholder="Colar URL completa" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Twitter" name="TWITTER">
                                                                        <Input addonBefore={<IoLogoTwitter />} type='url' placeholder="Colar URL completa" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="LinkedIn" name="LINKEDIN">
                                                                        <Input addonBefore={<IoLogoLinkedin />} type='url' placeholder="Colar URL completa" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Youtube" name="YOUTUBE">
                                                                        <Input addonBefore={<IoLogoYoutube />} type='url' placeholder="Colar URL completa" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Site" name="SITE">
                                                                        <Input addonBefore={<IoLinkOutline />} type='url' placeholder="Colar URL completa" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card bordered size="small">
                                                            <Typography style={{ textAlign: 'justify' }}>Todos os campos desta página são opcionais e podem ser excluídos a qualquer momento e, ao preenchê-los, você nos dá consentimento para compartilhar esses dados onde quer que seu perfil de usuário apareça. Consulte nossa declaração de privacidade para saber mais sobre como usamos essas informações.</Typography>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                {/* LISTA NOSSA EQUIPE */}
                                                <Divider />
                                                <Row gutter={[8, 8]}>
                                                    <Col span={24}>
                                                        <Card bordered size="small" title="Nossa Equipe">
                                                            <Row gutter={[8, 8]} align={'middle'}>
                                                                <Col md={20} xs={24}>
                                                                    <Typography style={{ textAlign: 'justify' }}>Adicione um membro a sua equipe, para que outros possam ver!</Typography>
                                                                </Col>
                                                                <Col md={4} xs={24}>
                                                                    <Button type="primary" className="btn-primary" size="small" block shape="round" style={{ fontSize: 10 }} onClick={() => this.openModalNossaEquipe('add')}>NOVO</Button>
                                                                </Col>
                                                                <Col md={0} xs={0}></Col>
                                                                <Col md={24} xs={24}>
                                                                    <Input onChange={this.onSearchChangeEquipe} placeholder={"Pesquisar..."} className="table__form_filter_input" value={this.state._equipe_search} />
                                                                </Col>
                                                                {this.state._equipe_loading ? <Col span={24}><center><Spin /></center></Col> : (
                                                                    <>
                                                                        {this.state._equipe_list.length > 0 ? (
                                                                            <Col span={24}>
                                                                                <List
                                                                                    itemLayout="horizontal"
                                                                                    dataSource={this.state._equipe_list}
                                                                                    renderItem={(item, index) => (
                                                                                        <List.Item key={index} actions={[<Button onClick={() => this.openModalNossaEquipe('edit', item)} className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button>, <Button onClick={() => this.onActionEquipe(item.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button>]}>
                                                                                            <List.Item.Meta
                                                                                                avatar={<Avatar size={50} src={item.AVATAR} />}
                                                                                                title={<Typography>{item.NAME}</Typography>}
                                                                                                description={<Typography style={{ textAlign: 'justify' }}>{item.DESC}</Typography>}
                                                                                            />
                                                                                        </List.Item>
                                                                                    )}
                                                                                />
                                                                            </Col>
                                                                        ) : <Col span={24}><Typography className="no-data">Nenhum item encontrado</Typography></Col>}
                                                                    </>
                                                                )}
                                                                <Col span={24}>
                                                                    <center><Pagination size="small" current={this.state._equipe_pagination.current} defaultCurrent={this.state._equipe_pagination.current} total={this.state._equipe_pagination.total} pageSize={this.state._equipe_pagination.limit} onChange={this.onPaginationChangeEquipe} /></center>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                {/* LISTA CLIENTES */}
                                                <Divider />
                                                <Row gutter={[8, 8]}>
                                                    <Col span={24}>
                                                        <Card bordered size="small" title="Clientes">
                                                            <Row gutter={[8, 8]} align={'middle'}>
                                                                <Col md={20} xs={24}>
                                                                    <Typography style={{ textAlign: 'justify' }}>Adicione um cliente a sua lista de clientes, para que outros possam ver!</Typography>
                                                                </Col>
                                                                <Col md={4} xs={24}>
                                                                    <Button type="primary" className="btn-primary" size="small" block shape="round" style={{ fontSize: 10 }} onClick={() => this.openModalCliente('add')}>NOVO</Button>
                                                                </Col>
                                                                <Col md={0} xs={0}></Col>
                                                                <Col md={24} xs={24}>
                                                                    <Input onChange={this.onSearchChangeCliente} placeholder={"Pesquisar..."} className="table__form_filter_input" value={this.state._cliente_search} />
                                                                </Col>
                                                                {this.state._cliente_loading ? <Col span={24}><center><Spin /></center></Col> : (
                                                                    <>
                                                                        {this.state._cliente_list.length > 0 ? (
                                                                            <Col span={24}>
                                                                                <List
                                                                                    itemLayout="horizontal"
                                                                                    dataSource={this.state._cliente_list}
                                                                                    renderItem={(item, index) => (
                                                                                        <List.Item key={index} actions={[<Button onClick={() => this.openModalCliente('edit', item)} className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button>, <Button onClick={() => this.onActionCliente(item.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button>]}>
                                                                                            <List.Item.Meta
                                                                                                avatar={<Avatar size={50} src={item.AVATAR} />}
                                                                                                title={<Typography>{item.NAME}</Typography>}
                                                                                                description={<Typography style={{ textAlign: 'justify' }}>{item.DESC}</Typography>}
                                                                                            />
                                                                                        </List.Item>
                                                                                    )}
                                                                                />
                                                                            </Col>
                                                                        ) : <Col span={24}><Typography className="no-data">Nenhum item encontrado</Typography></Col>}
                                                                    </>
                                                                )}
                                                                <Col span={24}>
                                                                    <center><Pagination size="small" current={this.state._cliente_pagination.current} defaultCurrent={this.state._cliente_pagination.current} total={this.state._cliente_pagination.total} pageSize={this.state._cliente_pagination.limit} onChange={this.onPaginationChangeCliente} /></center>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                {/* LISTA PUBLICAÇÕES */}
                                                <Divider />
                                                <Row gutter={[8, 8]}>
                                                    <Col span={24}>
                                                        <Card bordered size="small" title="Faça uma publicação">
                                                            <Row gutter={[16, 16]} align={'middle'}>
                                                                <Col md={20} xs={24}>
                                                                    <Typography style={{ textAlign: 'justify' }}>Adicione uma publicação em seu perfil, para que outros possam ver!</Typography>
                                                                </Col>
                                                                <Col md={4} xs={24}>
                                                                    <Button type="primary" className="btn-primary" size="small" block shape="round" style={{ fontSize: 10 }} onClick={() => this.openModalPublicacoes('add')}>NOVO</Button>
                                                                </Col>
                                                                <Col md={0} xs={0}></Col>
                                                                <Col md={24} xs={24}>
                                                                    <Input onChange={this.onSearchChangePublicacoes} placeholder={"Pesquisar..."} className="table__form_filter_input" value={this.state._publicacoes_search} />
                                                                </Col>
                                                                {this.state._publicacoes_loading ? <Col span={24}><center><Spin /></center></Col> : (
                                                                    <>
                                                                        {this.state._publicacoes_list.length > 0 ? (
                                                                            <>
                                                                                {this.state._publicacoes_list.map((item, index) => (
                                                                                    <Col span={24} key={index}>
                                                                                        <TimelineComponent index={index} item={item} onActionPublicacoes={this.onActionPublicacoes} openModalPublicacoes={this.openModalPublicacoes} onRenderDataPublicacoesLoad={this.onRenderDataPublicacoesLoad} />
                                                                                    </Col>
                                                                                ))}
                                                                            </>
                                                                        ) : <Col span={24}><Typography className="no-data">Nenhum item encontrado</Typography></Col>}
                                                                    </>
                                                                )}
                                                                {this.state._publicacoes_pagination.limit < this.state._publicacoes_pagination.total ? <Col span={24}><center><Button type="default" className="btn-default" onClick={this.onLoadMore}>Carregar mais</Button></center></Col> : null}
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    )}
                                    <Col xs={24} md={8}>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Card bordered size="small" style={{ paddingTop: 20 }}>
                                                    <Row justify="center" align={'middle'} style={{ flexDirection: 'column' }}>
                                                        <Avatar size={200} shape="circle" src={this.state.defaultValue.INTER_AVATAR} style={{ marginBottom: 12 }} />
                                                        <ImgCrop rotationSlider>
                                                            <Upload maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={this.onChangePic} className={`admin-profile-upload`}>
                                                                <Button shape="circle" className="btn-nav" type="default"><IoCameraOutline size={20} /></Button>
                                                            </Upload>
                                                        </ImgCrop>
                                                        <Typography.Paragraph editable={{ tooltip: false, onChange: this.onChangeName }} className={`admin-profile-name`}>{this.state.defaultValue.NAME}</Typography.Paragraph>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col span={24}>
                                                <Card title="Banner" bordered size="small">
                                                    <Row justify="center" align={'middle'} style={{ flexDirection: 'column' }}>
                                                        <Image src={this.state.defaultValue.BANNER} width={'100%'} style={{borderRadius: 8,  marginBottom: 12}} preview={false}/>
                                                        <Upload maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={this.onChangePicBanner} className={`admin-profile-upload`}>
                                                            <Button shape="circle" className="btn-nav" type="default"><IoCameraOutline size={20} /></Button>
                                                        </Upload>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            {verifyConfig(['PA', 'PC']) ? null : (
                                                <Col span={24}>
                                                    <Card title="Compartilhar Perfil" bordered size="small">
                                                        <Row justify="center" align={'middle'} style={{ flexDirection: 'column' }} gutter={[8, 8]}>
                                                            <Col span={24} id="myqrcode">
                                                                <QRCode
                                                                    size={200}
                                                                    value={`https://jurisvip.adsolucoestecnologia.com.br/perfil/${this.state.defaultValue.ID}`}
                                                                    icon={logo}
                                                                />
                                                            </Col>
                                                            <Col span={24}>
                                                                <Button type="primary" onClick={this.downloadQRCode} shape="round">
                                                                    Download
                                                                </Button>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Button type="link" className="btn-link" onClick={this.onCopy} shape="round">
                                                                    Copiar URL
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </>
                            </>
                        )}
                    </Row>
                    {/* MODAL NOSSA EQUIPE */}
                    <Modal open={this.state._equipe_modal} title={this.state._equipe_type === 'add' ? 'Novo' : 'Atualizar'} closable={false} footer={false} destroyOnClose={true}>
                        <Divider style={{ margin: 0, marginBottom: 10 }} />
                        <Form layout="vertical" onFinish={this.onSaveEquipe} initialValues={this.state._equipe_form}>
                            <Row gutter={[48, 0]}>
                                <Form.Item name="ID" hidden>
                                    <Input />
                                </Form.Item>
                                <Col span={24}>
                                    <Form.Item label="Nome" name="NAME" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Descrição" name="DESC" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                                        <Input.TextArea maxLength={1000} showCount rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Foto" name="AVATAR">
                                        <Upload
                                            action={getUPLOADAPI}
                                            listType="picture"
                                            className="upload-list-inline"
                                            accept="image/png,image/jpeg"
                                            maxCount={1}
                                        >
                                            <Button icon={<IoCloudUploadOutline />}>Upload</Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button loading={this.state._equipe_loading} onClick={this.closeModalNossaEquipe} className="btn-default" type="default" shape="round" block>Fechar</Button>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button loading={this.state._equipe_loading} htmlType="submit" className="btn-primary" type="primary" shape="round" block>Salvar</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    {/* MODAL CLIENTE */}
                    <Modal open={this.state._cliente_modal} title={this.state._cliente_type === 'add' ? 'Novo' : 'Atualizar'} closable={false} footer={false} destroyOnClose={true}>
                        <Divider style={{ margin: 0, marginBottom: 10 }} />
                        <Form layout="vertical" onFinish={this.onSaveCliente} initialValues={this.state._cliente_form}>
                            <Row gutter={[48, 0]}>
                                <Form.Item name="ID" hidden>
                                    <Input />
                                </Form.Item>
                                <Col span={24}>
                                    <Form.Item label="Nome" name="NAME" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Descrição" name="DESC" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                                        <Input.TextArea maxLength={1000} showCount rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Foto" name="AVATAR">
                                        <Upload
                                            action={getUPLOADAPI}
                                            listType="picture"
                                            className="upload-list-inline"
                                            accept="image/png,image/jpeg"
                                            maxCount={1}
                                        >
                                            <Button icon={<IoCloudUploadOutline />}>Upload</Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button loading={this.state._cliente_loading} onClick={this.closeModalCliente} className="btn-default" type="default" shape="round" block>Fechar</Button>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button loading={this.state._cliente_loading} htmlType="submit" className="btn-primary" type="primary" shape="round" block>Salvar</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    {/* MODAL PUBLICAÇÕES */}
                    <Modal open={this.state._publicacoes_modal} title={this.state._publicacoes_type === 'add' ? 'Novo' : 'Atualizar'} closable={false} footer={false} destroyOnClose={true}>
                        <Divider style={{ margin: 0, marginBottom: 10 }} />
                        <Form layout="vertical" onFinish={this.onSavePublicacoes} initialValues={this.state._publicacoes_form}>
                            <Row gutter={[48, 0]}>
                                <Form.Item name="ID" hidden>
                                    <Input />
                                </Form.Item>
                                <Col span={24}>
                                    <Form.Item label="Título" name="NAME" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Descrição" name="DESC" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                                        <Input.TextArea maxLength={1000} showCount rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Imagens" name="PHOTOS">
                                        <Upload
                                            action={getUPLOADAPI}
                                            listType="picture"
                                            className="upload-list-inline"
                                            accept="image/png,image/jpeg"
                                            multiple
                                        >
                                            <Button icon={<IoCloudUploadOutline />}>Upload</Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={24} style={{ marginBottom: 10, marginTop: -24 }}>
                                    <div className="ant-upload-wrapper upload-list-inline css-dev-only-do-not-override-w8mnev css-w8mnev">
                                        <div className="ant-upload-list ant-upload-list-picture">
                                            {this.state._publicacoes_form?.PHOTOS.map((v, i) => (
                                                <div className="ant-upload-list-item-container">
                                                    <div className="ant-upload-list-item ant-upload-list-item-done" key={i}>
                                                        <a className="ant-upload-list-item-thumbnail" href={v.URL} target="_blank" rel="noopener noreferrer">
                                                            <img src={v.URL} alt={v.NAME} className="ant-upload-list-item-image" />
                                                        </a>
                                                        <span className="ant-upload-list-item-name" title={v.NAME}>{v.NAME}</span>
                                                        <span className="ant-upload-list-item-actions picture"><button onClick={() => this.removePhoto(v.ID)} title="Remove file" type="button" className="ant-btn css-dev-only-do-not-override-w8mnev ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action"><span className="ant-btn-icon"><span role="img" aria-label="delete" tabindex="-1" className="anticon anticon-delete"><svg viewBox="64 64 896 896" focusable="false" data-icon="delete" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path></svg></span></span></button></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button loading={this.state._publicacoes_loading} onClick={this.closeModalPublicacoes} className="btn-default" type="default" shape="round" block>Fechar</Button>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button loading={this.state._publicacoes_loading} htmlType="submit" className="btn-primary" type="primary" shape="round" block>Salvar</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminMeuPerfil;