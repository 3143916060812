import React from "react";
import { Breadcrumb, Button, Col, Layout, Modal, Popover, Row, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoEye } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

class AdminProcessFinancialList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: { current: 1, total: 0, limit: 10 },
        table_filter: null,
        table_sorter: null,
        table_select: [],
    }

    back = "/painel/financeiro";

    routes = [
        {title: <Link to={this.back}>Financeiro</Link>},
        {title: 'Lista'}
    ]

    componentDidMount = () => {
        titleWeb('Financeiro')
        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/process_financial/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/process_financial/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Registro', dataIndex: 'ID', align: 'center', key: 'process_financial.ID', width: 80, sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <>
                        <Typography>{record.ID}</Typography>
                        <Typography>{record.PROCESS_CHANCE_WIN_NAME}</Typography>
                    </>
                ) },
                { title: 'Processo', dataIndex: 'NUMBER', key: 'process.NUMBER', width: 250, sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <>
                        <Popover content="Número do Processo" placement="left"><Typography className="financial_number">{record.PROCESS_NUMBER}</Typography></Popover>
                        <Popover content="Tipo de Ação" placement="left"><Typography className="financial_action">{record.PROCESS_ACTION_NAME}</Typography></Popover>
                        <Typography className="financial_register">Cadastro: {record.PROCESS_DATETIME_INSERT}</Typography>
                        <Typography className="financial_filing">Data Ajuizamento: {record.PROCESS_DATE_FILING}</Typography>
                        { record.LAWYERS.lenght > 0 ? (
                            <>
                                <br />
                                <Typography>Advogados Participantes</Typography>
                                { record.LAWYERS.map((v, i) => (
                                    <Link to={'/painel/advogados/'+v.ID}><Typography className="financial_lawyer" key={i}>{v.NAME}</Typography></Link>
                                )) }
                            </>
                        ) : null }
                    </>
                ) },
                { title: 'Clientes', sorter: false, showSorterTooltip: false,  width: 150, render: (text, record) => (
                    <div>
                        { record.CLIENTS.map((v, i) => (
                            <Link to={'/painel/clientes/'+v.ID}>
                                <div className="financial_lawyer">
                                    <Typography className="clientes" key={i}>{v.NAME}</Typography>
                                    <Typography className="clientes" key={i}>{v.CPFCNPJ}</Typography>
                                </div>
                            </Link>
                        )) }
                    </div>
                ) },
                { title: 'Valor da Causa', align: 'center', dataIndex: 'VALUE', key: 'process.VALUE', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Typography>R$<br />{record.PROCESS_VALUE_FORMAT}</Typography>
                ) },
                { title: 'Valor Cobrado', align: 'center', dataIndex: 'AMOUNT_CHARGED', key: 'process_financial.AMOUNT_CHARGED', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Typography>R$<br />{record.AMOUNT_CHARGED_FORMAT}</Typography>
                ) },
                { title: 'Valor Recebido', align: 'center', dataIndex: 'AMOUNT_RECEIVED', key: 'process_financial.AMOUNT_RECEIVED', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Typography>R$<br />{record.AMOUNT_RECEIVED_FORMAT}</Typography>
                ) },
                { title: 'Gastos e Repasses', align: 'center', dataIndex: 'EXPENSES_TRANSFERS', key: 'process_financial.EXPENSES_TRANSFERS', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Typography>R$<br />{record.EXPENSES_TRANSFERS_FORMAT}</Typography>
                ) },
                { title: 'Valor a Receber', align: 'center', dataIndex: 'AMOUNT_RECEIVABLE', key: 'process_financial.AMOUNT_RECEIVABLE', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Typography>R$<br />{record.AMOUNT_RECEIVABLE_FORMAT}</Typography>
                ) },
                { title: 'Ações', key: 'action', width: 80, render: (text, record) => (
                    <Row gutter={[4,4]} className="table__actions">
                        <Col><Link to={record.ID}><Button className={"table__btn table__btn_view"} title='Detalhes' type="primary" shape="circle" size="small"><IoEye size={14} /></Button></Link></Col>
                    </Row>
                )},
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate)  return (<Navigate to={'/painel'} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={24}>
                            <Table
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminProcessFinancialList;