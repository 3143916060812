import React from "react";
import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Layout, Row, Select, Spin, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, getType, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

class AdminTipoDeUsuarioForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        loadPermission: true,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        listPermission: [],
        permissionSelect: []
    }

    back = "/painel/tipousuario";

    routes = [
        {title: <Link to={this.back}>Tipos de Usuário</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]

    componentDidMount = () => {
        titleWeb('Tipos de Usuário')
        POST_API('/permission/page.php', { token: getToken(), filter: JSON.stringify({ TYPE: getType() }) }).then(rs => rs.json()).then(res => {
            if (res.return) { this.setState({ listPermission: res.data }) } else { message.warning({ content: res.msg, key: 'screen' }) }
        }).catch(POST_CATCH).finally(() => this.setState({ loadPermission: false }))
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onView = async () => {
        POST_API('/credential_type/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, permissionSelect: res.sec})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        if (getType() === 'A') values.VISIBLE = 'A'
        POST_API('/credential_type/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values), permission: JSON.stringify(this.state.permissionSelect) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    onChangeField = (values) => {

        this.setState({loadPermission: true})

        var temp = this.state.permissionSelect
        var val = values.target.value
        if (temp.includes(val)) {
            temp.splice(temp.indexOf(val), 1)
        } else {
            temp.push(val)
        }
        this.setState({permissionSelect: temp})
        setTimeout(() => {
            this.setState({loadPermission: false})
        }, 500);
        
    } 

    onChangeAll = (values) => {

        this.setState({ loadPermission: true })

        var temp = this.state.permissionSelect
        var val = this.state.listPermission[values.target.value]['PERMISSION_IDS']

        val.map((v,i) => {
            if (temp.includes(v)) { } else {
                temp.push(v)
            }
            return i;
        })

        this.setState({ permissionSelect: temp })

        setTimeout(() => {
            this.setState({ loadPermission: false })
        }, 500);
        
    } 

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,10]}>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={ getType() === 'A' ? 16 : 11 }>
                                            <Form.Item label="Descrição" name="DESCRIPTION">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        { getType() !== 'A' ? (
                                            <Col xs={24} md={5}>
                                                <Form.Item label="Visível" name="VISIBLE">
                                                    <Select>
                                                        <Select.Option value="D">Apenas Desenvolvedor</Select.Option>
                                                        <Select.Option value="A">Apenas Assinante</Select.Option>
                                                        <Select.Option value="P">Apenas Plataforma</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        { this.state.listPermission.map((v, i) => (
                                            <Col key={i} xs={24} md={Number(v.COL)}>
                                                <Card hoverable size="small" title={v.NAME}>
                                                    <Row>
                                                        <Col span={24}>
                                                        { this.state.loadPermission ? <Spin size="small"><Checkbox>Selecionar Todos</Checkbox></Spin> : <Checkbox onChange={this.onChangeAll} value={i}>Selecionar Todos</Checkbox> }
                                                        </Col>
                                                        { v.PERMISSION.map((v1, i1) => (
                                                            <Col span={24} key={i1} style={{ display: 'flex', alignItems: 'center', height: 22}}>
                                                                { this.state.loadPermission ? <Spin size="small"><Checkbox>{v1.DESCRIPTION}</Checkbox></Spin> : <Checkbox onChange={this.onChangeField} value={v1.ID} checked={this.state.permissionSelect.includes(v1.ID) ? true : false}>{v1.DESCRIPTION}</Checkbox> }
                                                            </Col>
                                                        )) }
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )) }
                                        <Col xs={24} md={24} style={{marginTop: 10}}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminTipoDeUsuarioForm;