import React from "react";
import { Avatar, Breadcrumb, Button, Card, Col, Form, Input, Layout, Row, Switch, Tag, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";

import { POST_API, getToken, getUPLOADAPI, titleWeb, setPath, POST_CATCH, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import { IoCameraOutline, IoLinkOutline, IoLogoFacebook, IoLogoGoogle, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoCheckmark } from 'react-icons/io5';

import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';

class AdminParteContrariaForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: {ID: 0},
        doc: false,
    }

    back = "/painel/partecontraria";

    routes = [
        {title: <Link to={this.back}>Partes Contrária</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]


    componentDidMount = () => {
        titleWeb('Partes Contrária')
        setPath('opposing_part')
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({_screen: true})
        await fetch('https://viacep.com.br/ws/'+cep+'/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    defaultValue: {
                        ...this.state.defaultValue,
                        STREET: res.logradouro,
                        DISTRICT: res.bairro,
                        CITY: res.localidade,
                        STATE: res.uf,
                        CEP: cep
                    }
                })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))

    }

    onView = async () => {
        await POST_API('/opposing_part/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            this.setState({defaultValue: res.data, _screen: false})
            if (String(res.data.CPFCNPJ).length > 14) this.setState({doc: true})
        }).catch(() => POST_CATCH())
    }

    onSend = (values) => {
        this.setState({_screen: true})
        POST_API('/opposing_part/self-pass.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSendAccount = (values) => {
        this.setState({_loading: true})
        POST_API('/opposing_part/saveall.php', { token: getToken(), master: JSON.stringify(this.state.defaultValue) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onChangePic = (value) => {
        if (value.file.response?.url) {
            this.setState({_screen: true})
            POST_API('/opposing_part/save.php', { token: getToken(), master: JSON.stringify({AVATAR: value.file.response?.url, ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangeName = (value) => {
        this.setState({_screen: true})
        POST_API('/opposing_part/save.php', { token: getToken(), master: JSON.stringify({NAME: value, ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView())
    }

    downloadQRCode = async () => {
        const canvas = await html2canvas(document.getElementById('myqrcode'));
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'download.png', 'image/png');
    };

    onCopy = () => {
        window.navigator.clipboard.writeText('https://jurisvip.adsolucoestecnologia.com.br');
        message.success({ content: 'URL copiada', key: 'screen' })
    }

    onChangeField = (a, b) => {
        if (a.length > 0) {
            var name = a[0].name[0]
            var value = a[0].value
            this.setState({
                defaultValue: {
                    ...this.state.defaultValue,
                    [name]: value
                }
            })
        }
    } 

    render = () => {

        if (this.state._navigate)  return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <>
                                { true ? (
                                    <>
                                        <Col xs={24} md={24}>
                                            <Form layout="vertical" onFinish={this.onSendAccount} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                                <Button size="large" className="fabbutton btn-primary" shape="circle" htmlType="submit"><IoCheckmark size={22} color="#FFF"/></Button>
                                                <Row gutter={[8,8]}>
                                                    <Col span={24}>
                                                        <Card title="Dados" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={18}>
                                                                    <Form.Item hidden name="ID">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                    <Form.Item label="Nome Completo" name="NAME" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="CPFCNPJ" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                                                        <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                                                    </Form.Item>
                                                                    <Switch checked={this.state.doc} unCheckedChildren="CPF" checkedChildren="CNPJ" onChange={(v) => this.setState({doc: v})} style={{position: 'absolute', right: 13, top: 35}} />
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="Apresentação" name="DESC">
                                                                        <Input.TextArea type='text' maxLength={1000} showCount rows={4}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="Observações" name="ABOUT">
                                                                        <Input.TextArea type='text' maxLength={1000} showCount rows={4}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Endereço" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label="CEP" name="CEP">
                                                                        <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" onBlur={this.buscaCEP} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={14}>
                                                                    <Form.Item label="Endereço" name="STREET">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="Número" name="NUMBER">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Bairro" name="DISTRICT">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Cidade" name="CITY">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="UF" name="STATE">
                                                                        <Input type='text' maxLength={2} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Contato" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={16}>
                                                                    <Form.Item label="E-mail" name="EMAIL">
                                                                        <Input addonBefore={<IoLogoGoogle />} type='email' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Whatsapp" name="WHATSAPP">
                                                                        <span class="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                            <span class="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                                <span class="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                                                <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.WHATSAPP} />
                                                                            </span>
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Instagram" name="INSTAGRAM">
                                                                        <Input addonBefore={<IoLogoInstagram />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Facebook" name="FACEBOOK">
                                                                        <Input addonBefore={<IoLogoFacebook />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Twitter" name="TWITTER">
                                                                        <Input addonBefore={<IoLogoTwitter />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="LinkedIn" name="LINKEDIN">
                                                                        <Input addonBefore={<IoLogoLinkedin />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Youtube" name="YOUTUBE">
                                                                        <Input addonBefore={<IoLogoYoutube />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Site" name="SITE">
                                                                        <Input addonBefore={<IoLinkOutline />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    
                                                </Row>
                                            </Form>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col xs={24} md={8}>
                                            <Row gutter={[8,8]}>
                                                <Col span={24}>
                                                    <Card bordered size="small" style={{paddingTop: 20}}>
                                                        <Row justify="center" align={'middle'} style={{flexDirection:  'column'}}>
                                                            <Avatar size={200} shape="circle" src={this.state.defaultValue.AVATAR} style={{marginBottom: 12}}/>
                                                            <Upload maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={this.onChangePic} className={`admin-profile-upload`}>
                                                                <Button shape="circle" className="btn-nav" type="default"><IoCameraOutline size={20} /></Button>
                                                            </Upload>
                                                            <Typography.Paragraph editable={{ tooltip: false, onChange: this.onChangeName }} className={`admin-profile-name`}>{this.state.defaultValue.NAME}</Typography.Paragraph>
                                                            <Tag color={this.state.defaultValue.STATUS_COLOR}>Parte Adversária</Tag>
                                                        </Row>
                                                    </Card>
                                                </Col> 
                                                {/* <Col span={24}>
                                                    <Card title="Compartilhar Perfil" bordered size="small">
                                                        <Row justify="center" align={'middle'} style={{flexDirection: 'column'}} gutter={[8,8]}>
                                                            <Col span={24} id="myqrcode">
                                                                <QRCode                                                                
                                                                    size={200}
                                                                    value={'https://jurisvip.adsolucoestecnologia.com.br'}
                                                                    icon={logo}
                                                                />
                                                            </Col>
                                                            <Col span={24}>
                                                                <Button type="primary" onClick={this.downloadQRCode} shape="round">
                                                                    Download
                                                                </Button>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Button type="link" className="btn-link" onClick={this.onCopy} shape="round">
                                                                    Copiar URL
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col> */}
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={16}>
                                            <Form layout="vertical" onFinish={this.onSendAccount} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                                <Button size="large" className="fabbutton btn-primary" shape="circle" htmlType="submit"><IoCheckmark size={22} color="#FFF"/></Button>
                                                <Row gutter={[8,8]}>
                                                    <Col span={24}>
                                                        <Card title="Perfil Público" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Form.Item hidden name="ID">
                                                                    <Input type='text' />
                                                                </Form.Item>
                                                                <Col xs={24} md={18}>
                                                                    <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="CPFCNPJ">
                                                                        <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                                                    </Form.Item>
                                                                    <Switch checked={this.state.doc} unCheckedChildren="CPF" checkedChildren="CNPJ" onChange={(v) => this.setState({doc: v})} style={{position: 'absolute', right: 13, top: 35}} />
                                                                </Col>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label="Data Nascimento" name="BIRTHDAY">
                                                                        <Input type='date' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="Apresentação" name="DESC">
                                                                        <Input.TextArea type='text' maxLength={1000} showCount rows={4}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="Sobre" name="ABOUT">
                                                                        <Input.TextArea type='text' maxLength={1000} showCount rows={4}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Endereço" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label="CEP" name="CEP">
                                                                        <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" onBlur={this.buscaCEP} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={14}>
                                                                    <Form.Item label="Endereço" name="STREET">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="Número" name="NUMBER">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Bairro" name="DISTRICT">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Cidade" name="CITY">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="UF" name="STATE">
                                                                        <Input type='text' maxLength={2} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Contato" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={16}>
                                                                    <Form.Item label="E-mail" name="EMAIL">
                                                                        <Input addonBefore={<IoLogoGoogle />} type='email' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Whatsapp" name="WHATSAPP">
                                                                        <span class="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                            <span class="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                                                <span class="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                                                <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.WHATSAPP} />
                                                                            </span>
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Instagram" name="INSTAGRAM">
                                                                        <Input addonBefore={<IoLogoInstagram />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Facebook" name="FACEBOOK">
                                                                        <Input addonBefore={<IoLogoFacebook />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Twitter" name="TWITTER">
                                                                        <Input addonBefore={<IoLogoTwitter />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="LinkedIn" name="LINKEDIN">
                                                                        <Input addonBefore={<IoLogoLinkedin />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Youtube" name="YOUTUBE">
                                                                        <Input addonBefore={<IoLogoYoutube />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Site" name="SITE">
                                                                        <Input addonBefore={<IoLinkOutline />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    
                                                </Row>
                                            </Form>
                                        </Col>
                                    </>
                                )}
                            </>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminParteContrariaForm;