import React from "react";
import { Layout, Button, Col, Modal, Row, message, Breadcrumb, Divider, List } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoConstruct, IoEye, IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

class AdminPageAceites extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        table_filter: { TYPE: '*' },
        table_sorter: null,
        table_select: [],
    }

    back = "/painel/politicastermos";

    componentDidMount = () => {
        titleWeb('Políticas e Termos')
        this.onRenderColumns().then(() => this.onRenderData())
    }

    routes = [
        {title: <Link to={this.back}>Políticas e Termos</Link>},
        {title: 'Logs Aceites'}
    ]

    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/page_signature/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Escritório', dataIndex: 'OFFICE_NAME', key: 'office.NAME', align: 'left', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Usuário', dataIndex: 'CREDENTIAL_NAME', key: 'credential.INTER_NAME', align: 'left', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Página', dataIndex: 'PAGE_TITLE', key: 'page.TITLE', width: 200, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Versão', dataIndex: 'VER', key: 'page_signature.VER', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Data Aceite', dataIndex: 'PAGE_SIGNATURE_DATE', key: 'page_signature.DATETIME_INSERT', width: 120, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/painel'} />)
        }

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col md={6} xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        <Col span={24}>
                            <Table
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminPageAceites;