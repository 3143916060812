import React from "react";
import { Col, Row, Breadcrumb, Typography, Collapse } from "antd";
import { Link } from "react-router-dom";

class WebFAQ extends React.Component {

    breadcrumb = [
        { title: <Link to='/'><Typography className="home-breadcrumb-link">Home</Typography></Link> },
        { title: <Typography className="home-breadcrumb-link active">FAQ</Typography> }
    ]

    render = () => {

        return (
            
            <Row>
                <Col span={24} className="home-col-black">
                    <Row align={'middle'} className="home-row-breadcrumb">
                        <Col span={12}>
                            <Breadcrumb className="home-breadcrumb" items={this.breadcrumb} />
                        </Col>
                        <Col span={12}>
                            <Typography className="home-breadcrumb-title">FAQ</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[60,16]} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title">FAQ – PERGUNTAS FREQUENTES</Typography></Col>
                        <Col span={24}>
                            <Typography className="about-row-title">SOU ADVOGADO</Typography>
                        </Col>
                        <Col span={24}>
                            <Collapse accordion>
                                <Collapse.Panel header="A plataforma JurisVip pode ser usada por um advogado autônomo ou um escritório?">
                                    <p>Sim, nossa plataforma pode ser usada por pessoas ou empresas que realizam serviços jurídicos e precisam de mais controle de processos, clientes e financeiro, otimização do tempo, trabalho online em qualquer lugar e automatização da comunicação como cliente.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Preciso ter registro na OAB para usar a plataforma JurisVip?">
                                    <p>Não, nossa plataforma é aberta para todos que tem uma atividade jurídica.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Posso testar a JurisVip gratuitamente?">
                                    <p>Sim, você pode testar nosso sistema para advogado gratuitamente para sempre. Temos um plano gratuito e sempre será.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Quais funcionalidades vou ter acesso ao fazer o cadastro na plataforma JurisVip?">
                                    <p>Temos muitas funcionalidades, para cada necessidade sua, da sua equipe ou do seu escritório, vai depender do plano que você cadastrou.</p>
                                    <p><Link to='/planos'>Clique aqui</Link> para ver todas as funcionalidades.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Como fazer para aparecer na lista de advogados do site da JurisVip?">
                                    <p>Para aparecer em nosso site, basta ter um cadastro ativo na nossa plataforma.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="A JurisVip é segura?">
                                    <p>Sim! Usamos a conexão segura SSL para encriptar todo o tráfego do site (basta checar o cadeado ao lado do endereço do site). Além disso, tomamos todas as medidas de validação de senhas e dados, e ainda, realizamos backups diários do sistema. Logo, seus dados estão seguros.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Posso cancelar a qualquer momento? Há fidelidade?">
                                    <p>Não há período de fidelidade. Para cancelar é simples e rápido, você acessa sua conta e seleciona a opção "solicitar cancelamento da assinatura mensal".</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Como é feito o pagamento?">
                                    <p>Nossos planos são mensais, com pagamentos lançados diretamente na fatura do seu cartão, sem comprometer o limite. Toda transação é criptografada e realizada via PagSeguro, uma empresa reconhecida nacionalmente. Sendo assim, não ficamos com os dados do seu cartão. Se preferir você poderá selecionar a opção de boleto bancário.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="A JurisVip coleta as publicações do Diário Oficial?">
                                    <p>Sim, consultamos praticamente todos os Diários da Justiça procurando pelo nome e/ou OAB dos advogados cadastrados.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="A JurisVip coleta os andamentos de processos nos tribunais?">
                                    <p>Sim, capturamos os andamentos processuais abertos, ou seja, os dados que não são necessários o usuário e senha para capturar. Esta captura depende exclusivamente dos dados disponibilizados nos Sites dos tribunais, Ou seja, se o tribunal estiver fora do ar não será possível a captura.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Se eu tiver duvida ou problemas com a plataforma, consigo falar com o atendimento da Jurisvip?">
                                    <p>Sim, temos um time preparado para atender você por e-mail ou chat online.</p>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Col>
            </Row>
           
        )

    }

}

export default WebFAQ;