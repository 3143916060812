import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Select, Switch, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../../services/api";

// CONTROLLER
import Page403 from '../../../Controller/Page403';
import PageLoad from '../../../Controller/PageLoad';
import ReactInputMask from "react-input-mask";

class AdminEscritorioEquipeForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        doc: false,
        filter: { CREDENTIAL_TYPE_ID: [] }
    }

    back = "/painel/escritorios/"+this.state._params[5]+"/equipe";

    routes = [
        {title: <Link to={"/painel/escritorios/"}>Escritórios</Link>},
        {title: <Link to={"/painel/escritorios/"+this.state._params[5]}>{this.state._params[5]}</Link>},
        {title: <Link to={this.back}>Equipe</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]

    componentDidMount = () => {
        titleWeb('Equipe')

        POST_API('/credential_type/search.php', { token: getToken(), filter: JSON.stringify({ OFFICE_ID: this.state._params[5], VISIBLE: "'A'" }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({filter: { CREDENTIAL_TYPE_ID: res.data }})
            } else { message.warning({ content: res.msg, key: 'screen' }) }
        }).catch(() => POST_CATCH())
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onView = async () => {
        POST_API('/credential/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[7], OFFICE_ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, doc: String(res.data.LOGIN).length > 14})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.OFFICE_ID = this.state._params[5];
        values.CREDENTIAL_TYPE_ID = '21'
        POST_API('/credential/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={6}>
                                            <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="LOGIN" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                            </Form.Item>
                                            <Switch unCheckedChildren="CPF" checkedChildren="CNPJ" defaultChecked={this.state.doc} onChange={(v) => this.setState({doc: v})} style={{position: 'absolute', right: 13, top: 35}} />
                                        </Col>
                                        <Col xs={24} md={18}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminEscritorioEquipeForm;