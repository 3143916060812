import { Col, Divider, Row, Segmented, Tag, Typography, message } from "antd";
import { POST_API, POST_CATCH, getToken, verifyConfig } from "../../../services/api";
import TableComponent from "../../../components/Table";
import { useEffect, useRef, useState } from "react";
import * as echarts from 'echarts';

import { IoChevronDown, IoChevronUp, IoReorderTwo } from 'react-icons/io5';

const KPIComponent = ( { title, value, compare = null } ) => {

    return (
        <div className="kpi-content">
            <Typography className="kpi-value">{value} { compare !== null ? <span>{compare}% {compare > 0 ? <IoChevronUp /> : compare === 0 ? <IoReorderTwo /> : <IoChevronDown />}</span> : null }</Typography>
            <Typography className="kpi-title">{title}</Typography>
        </div>
    )

}

const DashPlataforma = ( { option, dateStart, dateEnd } ) => {

    // DADOS PLANOS
    const gPlanos = useRef()
    const pPlanos = useRef()
    const [ dataPlanos, setDataPlanos ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/planos.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataPlanos(res)

            const chart = gPlanos.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value' }, tooltip: { trigger: 'axis' }, grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

            const chartPlanoP = pPlanos.current;
            const chartPlanoPMain = echarts.init(chartPlanoP)

            const chartPlanoPOption = {
                color: [ '#008000', '#FFC222', '#0000CD', '#d9232d' ],
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Tipo plano', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.type
                } ]
            };
    
            chartPlanoPMain.setOption(chartPlanoPOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    // DADOS PLANOS
    const gPlanosFat = useRef()
    const [ dataPlanosFat, setDataPlanosFat ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/planos_fat.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataPlanosFat(res)

            const chart = gPlanosFat.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value', axisLabel: { formatter: (value) => `R$ ${Number(value).toLocaleString('pt-br')}` } },
                tooltip: { trigger: 'axis', valueFormatter: (value) => `R$ ${Number(value).toLocaleString('pt-br')}` }, 
                grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    // DADOS ASSINANTES
    const gAssinantes = useRef()
    const [ dataAssinantes, setDataAssinantes ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/assinantes.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataAssinantes(res)

            const chart = gAssinantes.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value' }, tooltip: { trigger: 'axis' }, grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    // DADOS USUARIOS
    const gUsuarios = useRef()
    const [ dataUsuarios, setDataUsuarios ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/usuarios.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataUsuarios(res)

            const chart = gUsuarios.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value' }, tooltip: { trigger: 'axis' }, grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])
    
    // DADOS PROCESSO
    const gProcesso = useRef()
    const [ dataProcesso, setDataProcesso ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/processos.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataProcesso(res)

            const chart = gProcesso.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value' }, tooltip: { trigger: 'axis' }, grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    // DADOS TAREFA
    const gTarefa = useRef()
    const pTarefa = useRef()
    const [ dataTarefa, setDataTarefa ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/tarefas.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataTarefa(res)

            const chart = gTarefa.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value' }, tooltip: { trigger: 'axis' }, grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

            const chartTarefa = pTarefa.current;
            const chartTarefaMain = echarts.init(chartTarefa)
    
            const chartTarefaOption = {
                color: [ '#008000', '#FFC222', '#0000CD', '#d9232d' ],
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Situação tarefa', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.status
                } ]
            };
    
            chartTarefaMain.setOption(chartTarefaOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    return (
        <Row gutter={[10,10]}>

            <Col span={24}>
                <Divider orientation="left" style={{margin: 0}}>Dados Planos</Divider>
            </Col>
            <Col md={8} xs={24}>
                <KPIComponent title="Planos (geral)" value={dataPlanos?.total} />
                <KPIComponent title="Planos (filtro)" value={dataPlanos?.filtro} compare={dataPlanos?.compare} />
                <KPIComponent title="Faturamento (geral)" value={`R$ ${dataPlanosFat?.total}`} />
                <div className="graph-content">
                    <Typography className="graph-title">Planos por tipo</Typography>
                    <div ref={pPlanos} style={{height: 232}}></div>
                </div>
            </Col>
            <Col md={16} xs={24}>
                <div className="graph-content">
                    <div ref={gPlanos} style={{height: 336}}></div>
                </div>
                <div className="graph-content">
                    <div ref={gPlanosFat} style={{height: 336}}></div>
                </div>
            </Col>

            <Col span={24}>
                <Divider orientation="left" style={{margin: 0}}>Dados Assinantes</Divider>
            </Col>
            <Col md={16} xs={24}>
                <div className="graph-content">
                    <div ref={gAssinantes} style={{height: 288}}></div>
                </div>
            </Col>
            <Col md={8} xs={24}>
                <KPIComponent title="Assinantes (geral)" value={dataAssinantes?.total} />
                <KPIComponent title="Assinantes (filtro)" value={dataAssinantes?.filtro} compare={dataAssinantes?.compare} />
            </Col>

            <Col span={24}>
                <Divider orientation="left" style={{margin: 0}}>Dados Usuários</Divider>
            </Col>
            <Col md={8} xs={24}>
                <KPIComponent title="Usuários (geral)" value={dataUsuarios?.total} />
                <KPIComponent title="Usuários (filtro)" value={dataUsuarios?.filtro} compare={dataUsuarios?.compare} />
            </Col>
            <Col md={16} xs={24}>
                <div className="graph-content">
                    <div ref={gUsuarios} style={{height: 288}}></div>
                </div>
            </Col>

            <Col span={24}>
                <Divider orientation="left" style={{margin: 0}}>Dados Processos</Divider>
            </Col>
            <Col md={16} xs={24}>
                <div className="graph-content">
                    <div ref={gProcesso} style={{height: 288}}></div>
                </div>
            </Col>
            <Col md={8} xs={24}>
                <KPIComponent title="Processos (geral)" value={dataProcesso?.total} />
                <KPIComponent title="Processos (filtro)" value={dataProcesso?.filtro} compare={dataProcesso?.compare} />
            </Col>

            <Col span={24}>
                <Divider orientation="left" style={{marginBottom: 0}}>Dados Tarefas</Divider>
            </Col>
            <Col md={8} xs={24}>
                <KPIComponent title="Tarefas (geral)" value={dataTarefa?.total} />
                <KPIComponent title="Tarefas (filtro)" value={dataTarefa?.filtro} compare={dataTarefa?.compare} />
                <div className="graph-content">
                    <Typography className="graph-title">Tarefa por situação</Typography>
                    <div ref={pTarefa} style={{height: 232}}></div>
                </div>
            </Col>
            <Col md={16} xs={24}>
                <div className="graph-content">
                    <div ref={gTarefa} style={{height: 534}}></div>
                </div>
            </Col>
        </Row>
    )

}

export default DashPlataforma;