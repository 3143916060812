import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Image, Button, Drawer, FloatButton } from "antd";

// ICONS
import { IoMenuSharp } from 'react-icons/io5';
import { BsChatDotsFill } from 'react-icons/bs';

// IMG
import logo from '../../assets/img/logo/jurisvip-transparente.png';

// CSS
import './styles.css';

class NavbarComponent extends React.Component {

    state = {
        menu: false
    }

    onClick = () => {
        window.document.getElementById('web-content').scrollTo(0,0)
    }

    onMenu = () => {
        this.setState({menu: !this.state.menu})
    }

    render = () => {

        return (
            <Row className="navbar-content" gutter={[8,8]} align={'middle'}>
                <Col xs={6} md={2}>
                    <Link to="/"><Image src={logo} preview={false} className="navbar-logo" /></Link>
                </Col>
                <Col xs={0} md={22}>
                    <Row justify={'end'} align={'middle'}>
                        <Link to="/"><Button type=" onClick={this.onClick}link" className="btn-link navbar-link">Home</Button></Link>
                        <Link to="/quemsomos"><Button onClick={this.onClick} type="link" className="btn-link navbar-link">Quem Somos</Button></Link>
                        <Link to="/planos"><Button onClick={this.onClick} type="link" className="btn-link navbar-link">Planos</Button></Link>
                        <Link to="/faq"><Button onClick={this.onClick} type="link" className="btn-link navbar-link">FAQ</Button></Link>
                        <Link to="/contato"><Button onClick={this.onClick} type="link" className="btn-link navbar-link">Contato</Button></Link>
                        {/* <Link to="/cadastrese"><Button onClick={this.onClick} type="link" className="btn-link navbar-link">Cadastre-se</Button></Link> */}
                        <Link to="/login"><Button onClick={this.onClick} type="primary" size="large" className="btn-primary navbar-button">Entrar</Button></Link>
                    </Row>
                </Col>
                <Col xs={18} md={0}>
                    <Row justify={'end'} align={'middle'}>
                        <Link to="/login"><Button type="primary" className="btn-primary navbar-button">Entrar</Button></Link>
                        <Button onClick={this.onMenu} type="link" shape="circle" className="btn-link navbar-link" style={{marginLeft: 10}}><IoMenuSharp size={20} /></Button>
                    </Row>
                </Col>
                <Drawer width={250} open={this.state.menu} onClose={this.onMenu} closable={false}>
                    <Row gutter={[8,8]}>
                        <Col span={24}><Link to="/"><Button onClick={this.onMenu} block type="link" className="btn-link navbar-link">Home</Button></Link></Col>
                        <Col span={24}><Link to="/quemsomos"><Button onClick={this.onMenu} block type="link" className="btn-link navbar-link">Quem Somos</Button></Link></Col>
                        <Col span={24}><Link to="/planos"><Button onClick={this.onMenu} block type="link" className="btn-link navbar-link">Planos</Button></Link></Col>
                        <Col span={24}><Link to="/faq"><Button onClick={this.onMenu} block type="link" className="btn-link navbar-link">FAQ</Button></Link></Col>
                        <Col span={24}><Link to="/contato"><Button onClick={this.onMenu} block type="link" className="btn-link navbar-link">Contato</Button></Link></Col>
                        {/* <Col span={24}><Link to="/cadastrese"><Button onClick={this.onMenu} block type="link" className="btn-link navbar-link">Cadastre-se</Button></Link></Col> */}
                    </Row>
                </Drawer>
                <FloatButton className="navbar-fabbutton" icon={<BsChatDotsFill color="#FFFF"/>} onClick={() => console.log('click')} />
            </Row>
        )

    }
    
}

export default NavbarComponent;