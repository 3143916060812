import { Col, Divider, Row, Segmented, Tag, Typography, message } from "antd";
import { POST_API, POST_CATCH, getToken, verifyConfig } from "../../../services/api";
import TableComponent from "../../../components/Table";
import { useEffect, useRef, useState } from "react";
import * as echarts from 'echarts';

import { IoChevronDown, IoChevronUp, IoReorderTwo } from 'react-icons/io5';

const KPIComponent = ( { title, value, compare = null } ) => {

    return (
        <div className="kpi-content">
            <Typography className="kpi-value">{value} { compare !== null ? <span>{compare}% {compare > 0 ? <IoChevronUp /> : compare === 0 ? <IoReorderTwo /> : <IoChevronDown />}</span> : null }</Typography>
            <Typography className="kpi-title">{title}</Typography>
        </div>
    )

}

const DashEscritorio = ( { option, dateStart, dateEnd } ) => {

    // DADOS PROCESSO
    const gProcesso = useRef()
    const [ dataProcesso, setDataProcesso ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/processos.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataProcesso(res)

            const chart = gProcesso.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value' }, tooltip: { trigger: 'axis' }, grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    // DADOS CLIENTE
    const gCliente = useRef()
    const [ dataCliente, setDataCliente ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/clientes.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataCliente(res)

            const chart = gCliente.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value' }, tooltip: { trigger: 'axis' }, grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    // DADOS TAREFA
    const gTarefa = useRef()
    const pTarefa = useRef()
    const [ dataTarefa, setDataTarefa ] = useState(null)
    useEffect(() => {

        POST_API('/dashs/tarefas.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            setDataTarefa(res)

            const chart = gTarefa.current;
            const chartMain = echarts.init(chart)
    
            const chartOption = {
                aria: { decal: { show: true } },
                color: [ '#d9232d' ], xAxis: { type: 'category', data: res.datas },
                yAxis: { type: 'value' }, tooltip: { trigger: 'axis' }, grid: { left: '10px', right: '20px', bottom: '10px', top: '20px', containLabel: true },
                series: [ { data: res.values, type: 'bar' } ]
            };
    
            chartMain.setOption(chartOption)

            const chartTarefa = pTarefa.current;
            const chartTarefaMain = echarts.init(chartTarefa)
    
            const chartTarefaOption = {
                color: [ '#008000', '#FFC222', '#0000CD', '#d9232d' ],
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Situação tarefa', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.status
                } ]
            };
    
            chartTarefaMain.setOption(chartTarefaOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    // DADOS PROCESSO PIZZA
    const pProcesso = useRef()
    const pProcessoStatus = useRef()
    const pProcessoSituacao = useRef()
    const pProcessoChance = useRef()
    const pProcessoInstancia = useRef()
    const pProcessoTribunal = useRef()
    const pProcessoAcao = useRef()
    useEffect(() => {

        POST_API('/dashs/processos_pizzas.php', { token: getToken(), option: option, dateStart, dateEnd }).then(rs => rs.json()).then(res => {
            
            const chartStatus = pProcessoStatus.current;
            const chartStatusMain = echarts.init(chartStatus)
    
            const chartStatusOption = {
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Status Processo', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.status
                } ]
            };
    
            chartStatusMain.setOption(chartStatusOption)

            const chartSituacao = pProcessoSituacao.current;
            const chartSituacaoMain = echarts.init(chartSituacao)
    
            const chartSituacaoOption = {
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Situação Processo', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.situacao
                } ]
            };
    
            chartSituacaoMain.setOption(chartSituacaoOption)
            
            const chartChance = pProcessoChance.current;
            const chartChanceMain = echarts.init(chartChance)
    
            const chartChanceOption = {
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Chance de Ganho Processo', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.chance
                } ]
            };
    
            chartChanceMain.setOption(chartChanceOption)

            const chartInstancia = pProcessoInstancia.current;
            const chartInstanciaMain = echarts.init(chartInstancia)
    
            const chartInstanciaOption = {
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Instância Processo', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.instancia
                } ]
            };
    
            chartInstanciaMain.setOption(chartInstanciaOption)

            const chartTribunal = pProcessoTribunal.current;
            const chartTribunalMain = echarts.init(chartTribunal)
    
            const chartTribunalOption = {
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Tribunal Processo', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.tribunal
                } ]
            };
    
            chartTribunalMain.setOption(chartTribunalOption)

            const chartAcao = pProcessoAcao.current;
            const chartAcaoMain = echarts.init(chartAcao)
    
            const chartAcaoOption = {
                tooltip: { trigger: 'item' }, legend: { bottom: '0px', left: 'center', type: 'scroll' },
                series: [ { 
                    name: 'Tipo de Ação Processo', type: 'pie', radius: ['40%', '70%'], avoidLabelOverlap: false,
                    label: { show: false, position: 'center' }, emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold' } },
                    labelLine: { show: false }, data: res.acao
                } ]
            };
    
            chartAcaoMain.setOption(chartAcaoOption)

        }).catch(POST_CATCH)

    }, [option, dateStart, dateEnd])

    return (
        <Row gutter={[10,10]}>
            <Col span={24}>
                <Divider orientation="left" style={{margin: 0}}>Dados Processos</Divider>
            </Col>
            <Col md={8} xs={24}>
                <KPIComponent title="Processos (geral)" value={dataProcesso?.total} />
                <KPIComponent title="Processos (filtro)" value={dataProcesso?.filtro} compare={dataProcesso?.compare} />
            </Col>
            <Col md={16} xs={24}>
                <div className="graph-content">
                    <div ref={gProcesso} style={{height: 288}}></div>
                </div>
            </Col>
            <Col md={8} xs={12}>
                <div className="graph-content">
                    <Typography className="graph-title">Processos por status</Typography>
                    <div ref={pProcessoStatus} style={{height: 232}}></div>
                </div>
            </Col>
            <Col md={8} xs={12}>
                <div className="graph-content">
                    <Typography className="graph-title">Processos por situação</Typography>
                    <div ref={pProcessoSituacao} style={{height: 232}}></div>
                </div>
            </Col>
            <Col md={8} xs={12}>
                <div className="graph-content">
                    <Typography className="graph-title">Processos por chance de ganho</Typography>
                    <div ref={pProcessoChance} style={{height: 232}}></div>
                </div>
            </Col>
            <Col md={8} xs={12}>
                <div className="graph-content">
                    <Typography className="graph-title">Processos por instância</Typography>
                    <div ref={pProcessoInstancia} style={{height: 232}}></div>
                </div>
            </Col>
            <Col md={8} xs={12}>
                <div className="graph-content">
                    <Typography className="graph-title">Processos por tribunal</Typography>
                    <div ref={pProcessoTribunal} style={{height: 232}}></div>
                </div>
            </Col>
            <Col md={8} xs={12}>
                <div className="graph-content">
                    <Typography className="graph-title">Processos por tipo de ação</Typography>
                    <div ref={pProcessoAcao} style={{height: 232}}></div>
                </div>
            </Col>
            <Col span={24}>
                <Divider orientation="left" style={{marginBottom: 0}}>Dados Clientes</Divider>
            </Col>
            <Col md={16} xs={24}>
                <div className="graph-content">
                    <div ref={gCliente} style={{height: 288}}></div>
                </div>
            </Col>
            <Col md={8} xs={24}>
                <KPIComponent title="Clientes (geral)" value={dataCliente?.total} />
                <KPIComponent title="Clientes (filtro)" value={dataCliente?.filtro} compare={dataCliente?.compare} />
            </Col>
            <Col span={24}>
                <Divider orientation="left" style={{marginBottom: 0}}>Dados Tarefas</Divider>
            </Col>
            <Col md={8} xs={24}>
                <KPIComponent title="Tarefas (geral)" value={dataTarefa?.total} />
                <KPIComponent title="Tarefas (filtro)" value={dataTarefa?.filtro} compare={dataTarefa?.compare} />
                <div className="graph-content">
                    <Typography className="graph-title">Tarefa por situação</Typography>
                    <div ref={pTarefa} style={{height: 232}}></div>
                </div>
            </Col>
            <Col md={16} xs={24}>
                <div className="graph-content">
                    <div ref={gTarefa} style={{height: 534}}></div>
                </div>
            </Col>
        </Row>
    )

}

export default DashEscritorio;