import React from "react";
import { Card, Col, Row } from 'antd';

import { IoLinkOutline, IoLogoFacebook, IoLogoGoogle, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube } from 'react-icons/io5';

import { POST_API, POST_CATCH, getToken } from "../../services/api";

class WebPerfilContato extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        profile: null,
    }

    componentDidMount = () => {
        this.onView()
    }

    onView = async () => {
        await POST_API('/credential/search.php', { token: getToken(), filter: JSON.stringify({ID: this.state._params[4]}), type: 'view' }).then(rs => rs.json()).then(res => {
            this.setState({profile: res.data, _screen: false})
        }).catch(() => POST_CATCH())
    }

    render = () => {

        return (
            
            <Row className="perfil-content-row" gutter={[16, 16]}>
                <Col md={10} xs={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="Contatos">
                                { this.state.profile?.EMAIL ? <a rel="noreferrer" href={`mailto:${this.state.profile?.EMAIL}`} target="_blank" className="btn-link-contato"><IoLogoGoogle size={20} style={{marginRight: 10}} />{this.state.profile?.EMAIL}</a> : null }
                                { this.state.profile?.WHATSAPP ? <a rel="noreferrer" href={`https://web.whatsapp.com/send?phone=${this.state.profile?.WHATSAPP}`} target="_blank" className="btn-link-contato"><IoLogoWhatsapp size={20} style={{marginRight: 10}} />{this.state.profile?.WHATSAPP}</a> : null }
                                { this.state.profile?.FACEBOOK ? <a rel="noreferrer" href={`${this.state.profile?.FACEBOOK}`} target="_blank" className="btn-link-contato"><IoLogoFacebook size={20} style={{marginRight: 10}} />{this.state.profile?.FACEBOOK}</a> : null }
                                { this.state.profile?.INSTAGRAM ? <a rel="noreferrer" href={`${this.state.profile?.INSTAGRAM}`} target="_blank" className="btn-link-contato"><IoLogoInstagram size={20} style={{marginRight: 10}} />{this.state.profile?.INSTAGRAM}</a> : null }
                                { this.state.profile?.TWITTER ? <a rel="noreferrer" href={`${this.state.profile?.TWITTER}`} target="_blank" className="btn-link-contato"><IoLogoTwitter size={20} style={{marginRight: 10}} />{this.state.profile?.TWITTER}</a> : null }
                                { this.state.profile?.LINKEDIN ? <a rel="noreferrer" href={`${this.state.profile?.LINKEDIN}`} target="_blank" className="btn-link-contato"><IoLogoLinkedin size={20} style={{marginRight: 10}} />{this.state.profile?.LINKEDIN}</a> : null }
                                { this.state.profile?.YOUTUBE ? <a rel="noreferrer" href={`${this.state.profile?.YOUTUBE}`} target="_blank" className="btn-link-contato"><IoLogoYoutube size={20} style={{marginRight: 10}} />{this.state.profile?.YOUTUBE}</a> : null }
                                { this.state.profile?.SITE ? <a rel="noreferrer" href={`${this.state.profile?.SITE}`} target="_blank" className="btn-link-contato"><IoLinkOutline size={20} style={{marginRight: 10}} />{this.state.profile?.SITE}</a> : null }
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md={14} xs={24}>
                    <iframe title="mapa" width={'100%'} height={400} src={`https://maps.google.com/maps?q=${this.state.profile?.STREET} ${this.state.profile?.NUMBER} ${this.state.profile?.DISTRICT} ${this.state.profile?.CITY}&t=&z=13&ie=UTF8&iwloc=&output=embed`} frameborder="0" allowfullscreen />
                </Col>     
            </Row>

        )

    }

}

export default WebPerfilContato;