import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Switch, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';
import ReactInputMask from "react-input-mask";
import { IoLinkOutline, IoLogoFacebook, IoLogoGoogle, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube } from "react-icons/io5";

class AdminEscritorioForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        doc: true,
    }

    back = "/painel/escritorios";

    routes = [
        {title: <Link to={this.back}>Escritórios</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]

    componentDidMount = async () => {
        titleWeb('Escritórios')
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onView = async () => {
        POST_API('/office/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, doc: String(res.data.LOGIN).length > 14})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/office/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(this.state.defaultValue) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({_screen: true})
        await fetch('https://viacep.com.br/ws/'+cep+'/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    defaultValue: {
                        ...this.state.defaultValue,
                        STREET: res.logradouro,
                        DISTRICT: res.bairro,
                        CITY: res.localidade,
                        STATE: res.uf
                    }
                })
            }
        }).catch(() => {
            message.warning('Não foi possível estabelecer uma conexão com o sistema')
        }).finally(() => this.setState({_screen: false}))
    }

    onChangeField = (a, b) => {
        var name = a[0].name[0]
        var value = a[0].value
        this.setState({
            defaultValue: {
                ...this.state.defaultValue,
                [name]: value
            }
        })
    } 

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={6}>
                                            <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="LOGIN" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                            </Form.Item>
                                            <Switch checked={this.state.doc} unCheckedChildren="CPF" checkedChildren="CNPJ" onChange={(v) => this.setState({doc: v})} style={{position: 'absolute', right: 13, top: 35}} />
                                        </Col>
                                        <Col xs={24} md={18}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input addonBefore={<IoLogoGoogle />} type='email' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Whatsapp" name="WHATSAPP">
                                                <span class="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev">
                                                    <span class="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev">
                                                        <span class="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                        <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'(99) 99999-9999'} value={this.state.defaultValue?.WHATSAPP} />
                                                    </span>
                                                </span>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Instagram" name="INSTAGRAM">
                                                <Input addonBefore={<IoLogoInstagram />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Facebook" name="FACEBOOK">
                                                <Input addonBefore={<IoLogoFacebook />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Twitter" name="TWITTER">
                                                <Input addonBefore={<IoLogoTwitter />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="LinkedIn" name="LINKEDIN">
                                                <Input addonBefore={<IoLogoLinkedin />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Youtube" name="YOUTUBE">
                                                <Input addonBefore={<IoLogoYoutube />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Site" name="SITE">
                                                <Input addonBefore={<IoLinkOutline />} type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item label="CEP" name="CEP">
                                                <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" onBlur={this.buscaCEP} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Endereço" name="STREET">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={3}>
                                            <Form.Item label="Número" name="NUMBER">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Bairro" name="DISTRICT">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Form.Item label="Cidade" name="CITY">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={2}>
                                            <Form.Item label="UF" name="STATE">
                                                <Input type='text' maxLength={2} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminEscritorioForm;