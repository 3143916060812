import React from "react";
import { Col, Row, Breadcrumb, Typography, Button, Card, Form, Input, Checkbox } from "antd";
import { Link } from "react-router-dom";
import { IoCallOutline, IoMailOutline } from "react-icons/io5";

import ReactInputMask from "react-input-mask";

class WebContact extends React.Component {

    breadcrumb = [
        { title: <Link to='/'><Typography className="home-breadcrumb-link">Home</Typography></Link> },
        { title: <Typography className="home-breadcrumb-link active">Contato</Typography> }
    ]

    render = () => {

        return (
            
            <Row>
                <Col span={24} className="home-col-black">
                    <Row align={'middle'} className="home-row-breadcrumb">
                        <Col span={12}>
                            <Breadcrumb className="home-breadcrumb" items={this.breadcrumb} />
                        </Col>
                        <Col span={12}>
                            <Typography className="home-breadcrumb-title">Contato</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[20,16]} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title">CONTATO</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">Dúvida ou sugestão? Entre em contato conosco.</Typography></Col>
                        <Col md={12} xs={24}>
                            <Card hoverable>
                                <div className="contact-div">
                                    <div className="contact-icon"><IoCallOutline color="var(--primary)" size={30} /></div>
                                    <Typography className="home-row-page-text"><b>Vamos conversar pelo WhatsApp, é só chamar!</b></Typography>
                                    <Typography className="home-row-page-text">+1 5589 55488 55</Typography>
                                    <Typography className="home-row-page-text">+1 6678 254445 41</Typography>
                                </div>
                            </Card>
                            <Card hoverable style={{marginTop: 20}}>
                                <div className="contact-div">
                                    <div className="contact-icon"><IoMailOutline color="var(--primary)" size={30} /></div>
                                    <Typography className="home-row-page-text"><b>Se preferir pode mandar um e-mail!</b></Typography>
                                    <Typography className="home-row-page-text">info@example.com</Typography>
                                    <Typography className="home-row-page-text">contact@example.com</Typography>
                                </div>
                            </Card>
                        </Col>
                        <Col md={12} xs={24}>
                            <Card hoverable style={{height: 483}}>
                                <Form>
                                    <Form.Item>
                                        <Input type="text" placeholder="Seu nome" size="large" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Input type="text" placeholder="Seu melhor e-mail" size="large" />
                                    </Form.Item>
                                    <Form.Item>
                                        <ReactInputMask type="text" placeholder="Seu melhor celular" mask="(99) 99999-9999" className="ant-input ant-input-lg css-dev-only-do-not-override-w8mnev css-w8mnev" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Input.TextArea type="text" placeholder="Como podemos ajudar?" size="large" rows={3} autoSize={false} style={{resize: 'none'}} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Checkbox>Autorizo o contato e armazenamento dos meus dados para outros contatos e estou ciente que o objetivo desse formulário é para contato telefônico e/ou envio de e-mail.</Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <center><Button className="btn-primary" type="primary" size="large">Enviar</Button></center>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
           
        )

    }

}

export default WebContact;