import React from "react";
import { Breadcrumb, Button, Col, Layout, Row, Typography, message, Checkbox, Switch } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, URL_API, getToken, titleWeb, verifyConfig } from "../../../services/api";

import { FiEdit3 } from 'react-icons/fi';

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';
import HeaderMeuSite from "../../../components/HeaderMeuSite";
import FooterComponent from "../../../components/FooterComponent";

class AdminMyProcessPrestacaoContas extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        profile: null,
        date: '',
        // RENDERS
        client_set: [],
        opposing_part_set: [],
        lawyer_set: [],
        accountability: []
    }

    back = "/painel/meusprocessos";

    routes = [
        {title: <Link to={this.back}>Meus Processos</Link>},
        {title: this.state._params[5] },
        {title: 'Prestação de Contas'}
    ]

    componentDidMount = () => {
        titleWeb('Processos')
        this.onView().then(() => this.onLoad())
    }

    onView = async () => {
        POST_API('/myprocess/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    defaultValue: res.data,
                    client_set: res.data.CLIENTS,
                    opposing_part_set: res.data.OPPOSING_PARTS,
                    lawyer_set: res.data.LAWYERS
                })
                this.onViewPerfil(res.data.CREDENTIAL_ID)
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => window.scrollTo(0, 0))
    }

    onLoad = () => {
        POST_API('/process_accountability/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({PROCESS_ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ accountability: res.data, date: res.date })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onDownload = () => {
        window.open(URL_API+'/process_accountability/pdf.php?v='+Math.floor(Math.random() * 1000000)+'&ID='+this.state._params[5]+'&token="'+getToken()+'"');
    }

    onViewPerfil = async (id) => {
        await POST_API('/credential/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ ID: id }) }).then(rs => rs.json()).then(res => {
            this.setState({ profile: res.data })
            if (String(res.data.LOGIN).length > 14) this.setState({ doc: true })
        }).catch(() => POST_CATCH())
    }

    onChangeLiberado = (value) => {
        POST_API('/process/edit.php', { token: getToken(), master: JSON.stringify({ ID: this.state._params[5], LIBERAR_CLIENTE: value }) }).then(rs => rs.json()).then(res => {
            if (!res.return) {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={this.onDownload} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small" style={{marginRight: 10}}>Baixar PDF</Button>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { false ? <PageLoad /> : (
                            <Col span={24}>
                                <Row className="admin-form" gutter={[16,16]}>
                                    <Col span={24}>
                                        <HeaderMeuSite
                                            imagem={<img src={this.state.profile?.INTER_AVATAR} />}
                                            nomeEscritorio={this.state.defaultValue?.CREDENTIAL_NAME}
                                            especialidades={this.state.profile?.SPECIALITY}
                                            email={this.state.profile?.EMAIL}
                                            contato={this.state.profile?.WHATSAPP}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Typography className="title-prest">Prestação de Contas de Serviços Advocatícios</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col><Typography className="prestacao-titulo principal">Nome do Escritório:</Typography></Col>
                                            <Col><Typography className="prestacao-valor principal">{this.state.defaultValue?.CREDENTIAL_NAME}</Typography></Col>
                                        </Row>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col><Typography className="prestacao-titulo principal">Nome do Advogado Responsável:</Typography></Col>
                                            <Col><Typography className="prestacao-valor principal">{this.state.lawyer_set?.[0]?.NAME}</Typography></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col><Typography className="prestacao-titulo">Título do Processo:</Typography></Col>
                                            <Col><Typography className="prestacao-valor">{this.state.defaultValue?.TITLE}</Typography></Col>
                                        </Row>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col><Typography className="prestacao-titulo">ID Processo:</Typography></Col>
                                            <Col><Typography className="prestacao-valor">{this.state.defaultValue?.ID}</Typography></Col>
                                        </Row>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col><Typography className="prestacao-titulo">Nº do Processo:</Typography></Col>
                                            <Col><Typography className="prestacao-valor">{this.state.defaultValue?.NUMBER}</Typography></Col>
                                        </Row>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                            <Col><Typography className="prestacao-titulo">Tipo de Processo:</Typography></Col>
                                            <Col><Typography className="prestacao-valor">{this.state.defaultValue?.PROCESS_ACTION_NAME}</Typography></Col>
                                        </Row>
                                    </Col>
                                    { this.state.client_set.map((v, i) => (
                                        <Col span={24} key={i}>
                                            <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                                <Col><Typography className="prestacao-titulo">Nome do autor (cliente):</Typography></Col>
                                                <Col><Typography className="prestacao-valor">{v?.NAME}</Typography></Col>
                                            </Row>
                                            <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                                <Col><Typography className="prestacao-titulo">CPF do autor (cliente):</Typography></Col>
                                                <Col><Typography className="prestacao-valor">{v?.LOGIN}</Typography></Col>
                                            </Row>
                                        </Col>
                                    )) }
                                    { this.state.opposing_part_set.map((v, i) => (
                                        <Col span={24} key={i}>
                                            <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                                <Col><Typography className="prestacao-titulo">Parte Contrária:</Typography></Col>
                                                <Col><Typography className="prestacao-valor">{v?.NAME}</Typography></Col>
                                            </Row>
                                            <Row gutter={[8,8]} style={{marginBottom: 5}}>
                                                <Col><Typography className="prestacao-titulo">CPF da parte contrária:</Typography></Col>
                                                <Col><Typography className="prestacao-valor">{v?.CPFCNPJ}</Typography></Col>
                                            </Row>
                                        </Col>
                                    )) }
                                    <Col span={24}>
                                        { this.state.accountability.map((v, i) => (
                                            <Row key={i} gutter={[8,8]} style={{marginBottom: 5}}>
                                                <Col flex='auto'><Typography className="prestacao-valor"><b>{v.TITLE}</b></Typography><Typography className="prestacao-subtitulo">{v.SUBTITLE}</Typography></Col>
                                                <Col flex='150px'><Row className="prestacao-valor" justify={'space-between'}><Col style={{height: 23}}><span style={{fontSize: 10}}>({v.TYPE})</span> <b>R$</b></Col><Col style={{height: 23}}><Typography>{v.VALUE}</Typography></Col></Row></Col>
                                            </Row>
                                        )) }
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[8,8]} style={{marginBottom: 5}} justify={'end'}>
                                            <Col><Typography>{this.state.date}</Typography></Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row style={{flexWrap: 'nowrap'}}>
                                            <Col flex='auto'>
                                                <Typography><b>{this.state.defaultValue?.PROCESS_FOOTER_TITLE}</b></Typography>
                                                <Typography style={{textAlign: 'justify'}}>{this.state.defaultValue?.PROCESS_FOOTER_DESC}</Typography>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col flex='auto'>
                                                <Typography><b>{this.state.defaultValue?.PROCESS_OBS_TITLE}</b></Typography>
                                                <Typography style={{textAlign: 'justify'}}>{this.state.defaultValue?.PROCESS_OBS_DESC}</Typography>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <FooterComponent nomeEscritorio={this.state.defaultValue?.OFFICE_NAME} />
                                    </Col>
                                </Row>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminMyProcessPrestacaoContas;