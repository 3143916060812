import React from "react";
import { Avatar, Breadcrumb, Button, Card, Col, Form, Input, Layout, Modal, Row, Switch, Tag, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { POST_API, getToken, getUPLOADAPI, titleWeb, setPath, POST_CATCH, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';

import { IoCameraOutline, IoLinkOutline, IoLogoFacebook, IoLogoGoogle, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoCheckmark, IoClose, IoSearch } from 'react-icons/io5';

import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import TableComponent from "../../../components/Table";


class AdminAdvogadoForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        tags: [],
        _params: window.location.href.split('/'),
        defaultValue: {ID: 0},
        doc: false,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        table_filter: { },
        table_sorter: null,
        table_select: [],
    }

    back = "/painel/advogados";

    routes = [
        {title: <Link to={this.back}>Advogados</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]


    componentDidMount = () => {
        titleWeb('Advogados')
        setPath('lawyer')
        if (this.props.type === 'add') {
            this.setState({ defaultValue: { LOGIN: window.localStorage.getItem('CPFCNPJ') } })
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
        this.onRenderColumns().then(() => this.onRenderData())
    }

    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/tag/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify({'TYPE': 'A'}), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Cod.', dataIndex: 'ID', key: 'tag.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Nome', dataIndex: 'NAME', key: 'tag.NAME', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Tag color={record.COLOR}>{record.NAME}</Tag>
                ) },
                { title: 'Ações', key: 'action', width: 80, render: (text, record) => (
                    <Row gutter={[4,4]} className="table__actions">
                        <Col><Button onClick={() => this.onSelect(record.ID, 'add', 'Selecionar Tag?', record)} className="table__btn table__btn_view" title='Selecionar' type="primary" shape="circle" size="small"><IoCheckmark size={14} /></Button></Col>
                    </Row>
                )},
            ]
        })
    }

    buscaCEP = async (val) => {
        var cep = String(val.target.value).replace('-', '')
        this.setState({_screen: true})
        await fetch('https://viacep.com.br/ws/'+cep+'/json/').then(rs => rs.json()).then(res => {
            if (res.erro) {
                message.warning('CEP inválido')
            } else {
                this.setState({
                    defaultValue: {
                        ...this.state.defaultValue,
                        STREET: res.logradouro,
                        DISTRICT: res.bairro,
                        CITY: res.localidade,
                        STATE: res.uf,
                        CEP: cep
                    }
                })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))

    }

    onView = async () => {
        await POST_API('/lawyer/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            this.setState({defaultValue: res.data, _screen: false})
            if (String(res.data.LOGIN).length > 14) this.setState({doc: true})
        }).catch(() => POST_CATCH())
    }

    onSelect = (ID, type, title, obj = null) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                if (self.props.type === 'add') {
                    var temp = self.state.tags
                    temp.push(obj)
                    self.setState({tags: temp, _modal: false})
                    message.success({ content: 'Tag adicionada', key: 'screen' })
                } else {
                    POST_API('/lawyer_tag/save.php', { token: getToken(), LAWYER_ID: self.state.defaultValue.ID, TAG_ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                        if (res.return) {
                            message.success({ content: res.msg, key: 'screen' })
                            if (type === 'add-select') self.setState({table_select: []})
                            self.onView()
                        } else {
                            message.warning({ content: res.msg, key: 'screen' })
                        }
                    }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false, _modal: false}))
                }
            },
            onCancel() {},
        })
    }

    onDelete = (ID, index = null) => {
        var self = this
        Modal.confirm({
            title: 'Deletar tag?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                if (self.props.type === 'add') {
                    let temp = self.state.tags
                    temp.splice(index, 1)
                    self.setState({tags: temp, _modal: false})
                    message.success({ content: 'Tag removida', key: 'screen' })
                } else {
                    POST_API('/client_tag/del.php', { token: getToken(), ID: ID, type: 'del' }).then(rs => rs.json()).then(res => {
                        if (res.return) {
                            message.success({ content: res.msg, key: 'screen' })
                            self.onView()
                        } else {
                            message.warning({ content: res.msg, key: 'screen' })
                        }
                    }).catch(() => {POST_CATCH()}).finally(() => self.setState({_loading: false, _modal: false}))
                }
            },
            onCancel() {},
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    onSelectTable = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onSend = (values) => {
        this.setState({_screen: true})
        POST_API('/lawyer/self-pass.php', { token: getToken(), master: JSON.stringify(values),  }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_screen: false}))
    }

    onSendAccount = (values) => {
        this.setState({_loading: true})
        POST_API('/lawyer/saveall.php', { token: getToken(), master: JSON.stringify(this.state.defaultValue), tags: JSON.stringify(this.state.tags) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onChangePic = (value) => {
        if (value.file.response?.url) {
            this.setState({_screen: true})
            POST_API('/lawyer/save.php', { token: getToken(), master: JSON.stringify({AVATAR: value.file.response?.url, ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangeName = (value) => {
        this.setState({_screen: true})
        POST_API('/lawyer/save.php', { token: getToken(), master: JSON.stringify({NAME: value, ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView())
    }

    downloadQRCode = async () => {
        const canvas = await html2canvas(document.getElementById('myqrcode'));
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'download.png', 'image/png');
    };

    onCopy = () => {
        window.navigator.clipboard.writeText('https://jurisvip.adsolucoestecnologia.com.br');
        message.success({ content: 'URL copiada', key: 'screen' })
    }

    onChangeField = (a, b) => {
        if (a.length > 0) {
            var name = a[0].name[0]
            var value = a[0].value
            this.setState({
                defaultValue: {
                    ...this.state.defaultValue,
                    [name]: value
                }
            })
        }
    } 

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'..'}><Button type="primary" size="small" shape="round">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <>
                                { this.props.type === 'add' ? (
                                    <>
                                        <Col xs={24} md={24}>
                                            <Form layout="vertical" onFinish={this.onSendAccount} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                                <Button size="large" className="fabbutton btn-primary" shape="circle" htmlType="submit"><IoCheckmark size={22} color="#FFF"/></Button>
                                                <Row gutter={[8,8]}>
                                                    <Col span={24}>
                                                        <Card title="Dados" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="LOGIN" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                                                        <ReactInputMask readOnly className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                                                    </Form.Item>
                                                                    <Switch disabled checked={this.state.doc} unCheckedChildren="CPF" checkedChildren="CNPJ" onChange={(v) => this.setState({doc: v})} style={{position: 'absolute', right: 13, top: 35}} />
                                                                </Col>
                                                                <Col xs={24} md={14}>
                                                                    <Form.Item hidden name="ID">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                    <Form.Item label="Nome Completo" name="NAME" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="Data Nascimento" name="BIRTHDAY" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                                                        <Input type='date' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={16}>
                                                                    <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                                        <Input addonBefore={<IoLogoGoogle />} type='email' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Whatsapp" name="WHATSAPP" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                                        <span class="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev">
                                                                            <span class="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev">
                                                                                <span class="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                                                <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.WHATSAPP} />
                                                                            </span>
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="Apresentação" name="DESC">
                                                                        <Input.TextArea type='text' maxLength={1000} showCount rows={4}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="Observações" name="ABOUT">
                                                                        <Input.TextArea type='text' maxLength={1000} showCount rows={4}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Endereço" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label="CEP" name="CEP">
                                                                        <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" onBlur={this.buscaCEP} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={14}>
                                                                    <Form.Item label="Endereço" name="STREET">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="Número" name="NUMBER">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Bairro" name="DISTRICT">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Cidade" name="CITY">
                                                                        <Input type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="UF" name="STATE">
                                                                        <Input type='text' maxLength={2} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Contato" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Instagram" name="INSTAGRAM">
                                                                        <Input addonBefore={<IoLogoInstagram />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Facebook" name="FACEBOOK">
                                                                        <Input addonBefore={<IoLogoFacebook />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Twitter" name="TWITTER">
                                                                        <Input addonBefore={<IoLogoTwitter />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="LinkedIn" name="LINKEDIN">
                                                                        <Input addonBefore={<IoLogoLinkedin />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Youtube" name="YOUTUBE">
                                                                        <Input addonBefore={<IoLogoYoutube />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Site" name="SITE">
                                                                        <Input addonBefore={<IoLinkOutline />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                        <Col span={24}>
                                            <Card bordered size="small" title="Tags">
                                                <Input placeholder="Escolher tag" disabled addonAfter={<Button type="link" size="small" shape="circle" onClick={() => this.setState({_modal: true})}><IoSearch color="black" size={20} /></Button>} />
                                                <Row>
                                                    { this.state.tags.map((v, i) => (
                                                        <Col className="tag" style={{backgroundColor: v.COLOR}}>
                                                            <Button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 10, height: 10}}  shape="circle" size="small" type="link"><IoClose color="white" size={18} onClick={() => this.onDelete(v.ID, i)} /></Button>{v.NAME}
                                                        </Col>
                                                    )) }
                                                </Row>
                                            </Card>
                                        </Col> 
                                    </>
                                ) : (
                                    <>
                                        <Col xs={24} md={8}>
                                            <Row gutter={[8,8]}>
                                                <Col span={24}>
                                                    <Card bordered size="small" style={{paddingTop: 20}}>
                                                        <Row justify="center" align={'middle'} style={{flexDirection:  'column'}}>
                                                            <Avatar size={200} shape="circle" src={this.state.defaultValue.AVATAR} style={{marginBottom: 12}}/>
                                                            <Typography editable={{ tooltip: false, onChange: this.onChangeName }} className={`admin-profile-name`}>{this.state.defaultValue.NAME}</Typography>
                                                            <Tag color={this.state.defaultValue.STATUS_COLOR}>{this.state.defaultValue.CREDENTIAL_TYPE_NAME}</Tag>
                                                        </Row>
                                                    </Card>
                                                </Col> 
                                                <Col span={24}>
                                                    <Card bordered size="small" title="Tags">
                                                        <Input placeholder="Escolher tag" disabled addonAfter={<Button type="link" size="small" shape="circle" onClick={() => this.setState({_modal: true})}><IoSearch color="black" size={20} /></Button>} />
                                                        <Row>
                                                            { this.state.defaultValue?.TAGS.map((v, i) => (
                                                                <Col className="tag" style={{backgroundColor: v.TAG_COLOR}}>
                                                                    <Button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 10, height: 10}}  shape="circle" size="small" type="link"><IoClose color="white" size={18} onClick={() => this.onDelete(v.ID)} /></Button>{v.TAG_NAME}
                                                                </Col>
                                                            )) }
                                                        </Row>
                                                    </Card>
                                                </Col> 
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={16}>
                                            <Form layout="vertical" onFinish={this.onSendAccount} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                                <Row gutter={[8,8]}>
                                                    <Col span={24}>
                                                        <Card title="Perfil Público" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Form.Item hidden name="ID">
                                                                    <Input type='text' />
                                                                </Form.Item>
                                                                <Col xs={24} md={18}>
                                                                    <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="LOGIN" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                                                        <ReactInputMask disabled className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                                                    </Form.Item>
                                                                    <Switch disabled checked={this.state.doc} unCheckedChildren="CPF" checkedChildren="CNPJ" onChange={(v) => this.setState({doc: v})} style={{position: 'absolute', right: 13, top: 35}} />
                                                                </Col>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label="Data Nascimento" name="BIRTHDAY" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                                                        <Input disabled type='date' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="Apresentação" name="DESC">
                                                                        <Input.TextArea disabled type='text' maxLength={1000} showCount rows={4}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item label="Sobre" name="ABOUT">
                                                                        <Input.TextArea disabled type='text' maxLength={1000} showCount rows={4}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Endereço" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={6}>
                                                                    <Form.Item label="CEP" name="CEP">
                                                                        <ReactInputMask disabled mask={'99999-999'} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" onBlur={this.buscaCEP} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={14}>
                                                                    <Form.Item label="Endereço" name="STREET">
                                                                        <Input disabled type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="Número" name="NUMBER">
                                                                        <Input disabled type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Bairro" name="DISTRICT">
                                                                        <Input disabled type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={10}>
                                                                    <Form.Item label="Cidade" name="CITY">
                                                                        <Input disabled type='text' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={4}>
                                                                    <Form.Item label="UF" name="STATE">
                                                                        <Input disabled type='text' maxLength={2} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card title="Contato" bordered size="small">
                                                            <Row gutter={[16,0]}>
                                                                <Col xs={24} md={16}>
                                                                    <Form.Item label="E-mail" name="EMAIL">
                                                                        <Input disabled addonBefore={<IoLogoGoogle />} type='email' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Whatsapp" name="WHATSAPP">
                                                                        <span class="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev">
                                                                            <span class="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev">
                                                                                <span class="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                                                <ReactInputMask disabled className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.WHATSAPP} />
                                                                            </span>
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Instagram" name="INSTAGRAM">
                                                                        <Input disabled addonBefore={<IoLogoInstagram />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Facebook" name="FACEBOOK">
                                                                        <Input disabled addonBefore={<IoLogoFacebook />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Twitter" name="TWITTER">
                                                                        <Input disabled addonBefore={<IoLogoTwitter />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="LinkedIn" name="LINKEDIN">
                                                                        <Input disabled addonBefore={<IoLogoLinkedin />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Youtube" name="YOUTUBE">
                                                                        <Input disabled addonBefore={<IoLogoYoutube />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={8}>
                                                                    <Form.Item label="Site" name="SITE">
                                                                        <Input disabled addonBefore={<IoLinkOutline />} type='url' />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    
                                                </Row>
                                            </Form>
                                        </Col>
                                    </>
                                )}
                            </>
                        ) }
                    </Row>
                    <Modal open={this.state._modal} onCancel={() => this.setState({_modal: false})} title="Lista de Tags" footer={false}>
                        <Row>
                        <Col span={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                {/* { this.state.table_select.length > 0 ? <Button onClick={() => this.onSelect(null, 'add-select', 'Selecionar Tags?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Escolher Selecionados ({this.state.table_select.length})</Button> : null } */}
                                { verifyConfig('trf.add') ? <Link to={'/painel/tags/add'} target="_blank"><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link>: null }
                            </Col>
                            <Col span={24}>
                                <TableComponent
                                    _columns={this.state.table_column}
                                    _data={this.state.table_data}
                                    _pagination={this.state.table_pagination}
                                    _loading={this.state.table_load}
                                    _onChangeTable={this.onTableChange}
                                    _onChangePagination={this.onPaginationChange}
                                    _onChangeSearch={this.onSearchChange}
                                    _search={this.state.table_search}
                                />
                            </Col>
                        </Row>
                    </Modal>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminAdvogadoForm;