import React from "react";
import { Alert, Breadcrumb, Button, Card, Col, Collapse, Form, Input, Layout, Modal, Row, Table, Tag, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { POST_API, getToken, titleWeb, POST_CATCH } from "../../services/api";

// CONTROLLER
import PageLoad from '../Controller/PageLoad';

import { IoArrowDown, IoArrowUp, IoCheckmark, IoCheckmarkDoneSharp, IoClose, IoReorderTwo } from 'react-icons/io5';
import ReactInputMask from "react-input-mask";

class AdminAlterarPlanoCart extends React.Component {

    state = {
        _navigate: false,
        _buy: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: null,
        modal: false,
        title: '',
        type: '',
        type_select: '',
        id: '',
        plan: null,
        plan_select: null,
        my: null,
        result: null,
    }

    routes = [
        { title: <Link to='/painel/minhaconta'>Minha conta</Link> },
        { title: <Link to='/painel/minhaconta/alterarplano'>Alterar Plano</Link>}
    ]

    componentDidMount = () => {
        titleWeb('Alterar Plano')
        POST_API('/plan/search.php', { token: 'X', filter: JSON.stringify({ID: this.state._params[6]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({data: res.data[0], _screen: false})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        })
    }

    onSelectPlan = (text, id, type) => {

        POST_API('/plan/onload.php', { token: getToken(), ID: id }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({modal: true, title: text, id, type, plan: res.plan, my: res.my, result: res.result})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {POST_CATCH() })

        this.setState({
            plan_select: id,
            type_select: type,
        })

    }

    onSend = (values) => {
        this.setState({ _loading: true })
        POST_API('/subscription/asaas.php', { token: getToken(), master: JSON.stringify(values), PLAN_ID: this.state._params[6], type: this.state._params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {POST_CATCH() }).finally(() => this.setState({ _loading: false }))
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/painel/minhaconta'} />)
        }

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Row gutter={[8,16]} align={'top'}>
                                    <Col md={16} xs={24}>
                                        <Card size="small" title="Plano">
                                            <Row gutter={[8,8]} style={{flexWrap: 'nowrap'}} align={'bottom'}>
                                                <Col flex={'auto'}>
                                                    <Typography className="text-new">{this.state.data?.NAME} <Tag color={'var(--primary)'}>{this.state._params[7] === 'A' ? 'Plano anual' : 'Plano mensal'}</Tag></Typography>
                                                    <Typography className="text-new-sub">{this.state.data?.DESC}</Typography>
                                                </Col>
                                                <Col flex={'260px'}>
                                                    <Typography className="text-new-price"><span>R$ </span>{this.state._params[7] === 'A' ? Number(this.state.data?.PRICE_YEAR).toLocaleString('pt-br', { minimumFractionDigits: 2 }) :  Number(this.state.data?.PRICE_MONTH).toLocaleString('pt-br', { minimumFractionDigits: 2 }) }</Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        <Card size="small" title="Escolha uma forma de pagamento">
                                            <Collapse accordion={true}>
                                                <Collapse.Panel key={0} header="Cartão de crédito">
                                                    <Form onFinish={this.onSend}>
                                                        <Row gutter={[8,0]}>
                                                            <Form.Item name="TYPE" hidden initialValue="CREDIT_CARD"><Input value="CREDIT_CARD" /></Form.Item>
                                                            <Col span={24}>
                                                                <Form.Item name={"NUMBER"} rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                                    <ReactInputMask className="ant-input ant-input-lg css-dev-only-do-not-override-w8mnev" placeholder={'Número do cartão'} mask={'9999 9999 9999 9999'} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item name={"NAME"} rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                                    <Input size="large" placeholder={'Nome do titular'} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item name={"EXPIRY"} rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                                    <ReactInputMask className="ant-input ant-input-lg css-dev-only-do-not-override-w8mnev" placeholder={'Validade'} mask={'99/99'} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={10}>
                                                                <Form.Item name={"CVC"} rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                                    <ReactInputMask className="ant-input ant-input-lg css-dev-only-do-not-override-w8mnev" placeholder={'CVC'} mask={'999'} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Button htmlType="submit" block type="primary" loading={this.state._loading}>Finalizar</Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Collapse.Panel>
                                                <Collapse.Panel key={1} header="Pix">
                                                    <Form onFinish={this.onSend}>
                                                        <Row gutter={[8,0]}>
                                                            <Form.Item name="TYPE" hidden initialValue="PIX"><Input value="PIX" /></Form.Item>
                                                            <Col span={24}>
                                                                <Button htmlType="submit" block type="primary" loading={this.state._loading}>Gerar pix</Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Collapse.Panel>
                                                <Collapse.Panel key={2} header="Boleto">
                                                    <Form onFinish={this.onSend}>
                                                        <Row gutter={[8,0]}>
                                                            <Form.Item name="TYPE" hidden initialValue="BOLETO"><Input value="BOLETO" /></Form.Item>
                                                            <Col span={24}>
                                                                <Button htmlType="submit" block type="primary" loading={this.state._loading}>Gerar boleto</Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminAlterarPlanoCart;