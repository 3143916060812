import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';
import Embed from "react-embed";

class AdminTreinamentoEmbed extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    back = "/painel/treinamentos";

    routes = [
        {title: <Link to={this.back}>Treinamentos</Link>},
        {title: this.state._params[5]}
    ]

    componentDidMount = () => {
        titleWeb('Treinamentos')
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onView = async () => {
        POST_API('/training/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/training/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate)  return (<Navigate to={this.back} />)

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <div className={`admin-form`}>
                                    <Embed url={this.state.defaultValue.URL} style={{height: 300}} />
                                </div>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminTreinamentoEmbed;