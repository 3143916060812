import React from "react"
import { Avatar, Button, Col, Drawer, Image, Layout, Modal, Row, Skeleton, Typography } from "antd"

import { POST_API, delToken, getToken, delType, POST_CATCH, setConfig } from "../../services/api"
import { Navigate, Outlet } from "react-router-dom"

import SidebarComponent from '../../components/Sidebar'
import { IoExitOutline, IoMenuOutline } from "react-icons/io5"
import { ExclamationCircleOutlined } from '@ant-design/icons';

import logo from '../../assets/img/logo/jurisvip-all-transparente-menor.png';
import ControllerPageLoad from "../Controller/PageLoad"

class AdminDashboard extends React.Component {

    state = {
        _loading: false,
        _navigate: false,
        _menu: false,
        _user: null,
        modal: false,
        pages: null,
        contagem: 5
    }

    contagemRegressiva = null;

    componentDidMount = () => {
        this.onVerify()
    }

    onVerify = () => {
        this.setState({modal: false})
        if (getToken()) {
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (!res.return) {
                    this.setState({_navigate: true })
                } else {
                    setConfig(res.per)
                    this.setState({_user: res.user, modal: (res.pages.length > 0), pages: res.pages[0]})
                    if (res.pages.length > 0) {
                        this.setState({modal: true, pages: res.pages[0], contagem: 5})
                        setTimeout(() => {
                            this.contagemRegressiva = setInterval(() => {
                                if (this.state.contagem > -1) {
                                    this.setState({contagem: this.state.contagem-0.5})
                                } else {
                                    clearInterval(this.contagemRegressiva)
                                }
                            }, 1000);
                        }, 1000);
                    }
                }
            }).catch(() => POST_CATCH()).finally(() => {
                this.setState({_loading: false})
            })
        } else {
            this.setState({_navigate: true})
        }
    }

    onLogOut = () => {
        var self = this
        Modal.confirm({
            title: 'Sair do sistema?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() { delToken(); delType(); self.setState({_navigate: true}) },
            onCancel() {},
        })
    }

    onMenu = () => {
        this.setState({_menu: !this.state._menu})
    }

    onAceite = () => {
        POST_API('/page_signature/save.php', { token: getToken(), page: this.state.pages.ID, ver: this.state.pages.VER }).then(rs => rs.json()).then(res => {
            this.onVerify()
        }).catch(() => POST_CATCH())
    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/" />
        }

        return (
            <Layout>
                <Layout.Content className={`admin-content`}>
                    { this.state._user === null ? (
                        <Row align="middle" justify="space-between" style={{height: '100%'}}>
                            <ControllerPageLoad />
                        </Row>
                    ) : (
                        <>
                            <Row className={`admin-navbar`} align="middle" justify="space-between">
                                <Col>
                                    <Row align={'middle'}>
                                        <Button onClick={this.onMenu} shape="circle" className="btn-nav menu-button" type="link"><IoMenuOutline size={22} /></Button>
                                        <Image style={{marginBottom: 10, objectFit: 'contain'}} src={logo} preview={false} width='150px' height="50px" />
                                    </Row>
                                </Col>
                                <Col>
                                    <Row align={'middle'}>
                                        <Button onClick={this.onLogOut} shape="circle" className="btn-nav" type="link"><IoExitOutline size={18} /></Button>
                                        { this.state._user?.AVATAR ? <Avatar shape="circle" size={40} src={this.state._user?.AVATAR} /> : <Skeleton.Avatar shape="circle" size={40} /> }
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{flexWrap: 'nowrap'}}>
                                <Col flex={'250px'} className={`admin-sidebar`}>
                                    <SidebarComponent />
                                </Col>
                                <Col flex={'auto'}>
                                    <Outlet />
                                </Col>
                            </Row>
                            <Drawer size="small" placement="left" open={this.state._menu} onClose={this.onMenu} width={300}>
                                <SidebarComponent clickOption={this.onMenu} />
                            </Drawer>
                            <Modal open={this.state.modal} footer={false} closable={false} style={{top: 30}} width={900}>
                                <Row>
                                    <Col span={24}><Typography className="home-row-page-text-title">{this.state.pages?.TITLE}</Typography></Col>
                                    <Col span={24} style={{height: 400, overflowY: 'auto', border: '1px solid black', borderRadius: 8, padding: 10}}>
                                    { this.state.pages?.PAGE && this.state.pages?.PAGE.map((v, i) => (
                                        <>
                                            <Row gutter={[16,0]} key={i}>
                                                { v.columns.map((cv, ci) => (
                                                    <Col key={ci} span={24/v.type}>
                                                        <Row>
                                                            { cv.items.map((iv, ii) => (
                                                                <Col span={24} key={ii} style={{marginBottom: 10}}>
                                                                    { iv.type === 'T' ? <Typography className="policy-row-title" style={{marginTop: 30, marginBottom: 20}}>{iv.value}</Typography> : null }
                                                                    { iv.type === 'P' ? <Typography className="policy-row-subtitle">{iv.value}</Typography> : null }
                                                                    { iv.type === 'L' ? <Typography className="policy-row-subtitle-line">{iv.value}</Typography> : null }
                                                                </Col>
                                                            )) }
                                                        </Row>
                                                    </Col>
                                                )) }
                                            </Row>
                                        </>
                                    )) }
                                    <Col span={24}><Typography className="home-row-page-version">Versão {this.state.pages?.VER} - Atualizada em {this.state.pages?.DATE_VER}</Typography></Col>
                                    </Col>
                                    <Col span={24}>
                                        <center><Button disabled={this.state.contagem > -0.1} style={{marginTop: 10}} type={this.state.contagem > -0.1 ? 'default' : 'primary'} shape={"round"} onClick={this.onAceite}>Li e concordo { this.state.contagem > -0.1 ? `(${Number(this.state.contagem).toFixed(0)})` : '' }</Button></center>
                                    </Col>
                                </Row>
                            </Modal>
                        </>
                    )}
                </Layout.Content>
            </Layout>
        )
    }

}

export default AdminDashboard