import React from "react";
import { Breadcrumb, Button, Card, Checkbox, Col, Divider, Form, Input, Layout, Row, Select, Spin, Switch, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, getType, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';
import ReactInputMask from "react-input-mask";
import { IoLogoGoogle, IoLogoWhatsapp } from "react-icons/io5";

class AdminEquipeForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        doc: false,
        loadPermission: true,
        listPermission: [],
        permissionSelect: [],
        filter: { CREDENTIAL_TYPE_ID: [] }
    }

    back = "/painel/equipe";

    routes = [
        {title: <Link to={this.back}>Equipe</Link>},
        {title: this.props.type === 'add' ? 'Adicionar' : 'Editar'}
    ]

    componentDidMount = () => {
        titleWeb('Equipe')
        POST_API('/credential_type/search.php', { token: getToken(), filter: JSON.stringify({ VISIBLE: "'A'" }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({filter: { CREDENTIAL_TYPE_ID: res.data }})
            } else { message.warning({ content: res.msg, key: 'screen' }) }
        }).catch(() => POST_CATCH())
        POST_API('/permission/page.php', { token: getToken(), filter: JSON.stringify({ TYPE: getType() }) }).then(rs => rs.json()).then(res => {
            if (res.return) { this.setState({ listPermission: res.data }) } else { message.warning({ content: res.msg, key: 'screen' }) }
        }).catch(POST_CATCH).finally(() => this.setState({ loadPermission: false }))
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onView = async () => {
        POST_API('/credential_team/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, doc: String(res.data.LOGIN).length > 14, permissionSelect: res.sec})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/credential_team/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values), per: this.state.permissionSelect }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    onChangeField = (a, b) => {
        if (a.length > 0) {
            var name = a[0].name[0]
            var value = a[0].value
            this.setState({
                defaultValue: {
                    ...this.state.defaultValue,
                    [name]: value
                }
            })
        }
    } 

    onChangePermission = (e) => {

        var temp = this.state.permissionSelect

        var val = e.target.value

        if (temp.includes(val)) {
            temp.splice(temp.indexOf(val), 1)
        } else {
            temp.push(val)
        }

        this.setState({permissionSelect: temp})

    }

    onChangePermissionColumn = (type) => {

        this.state.listPermission.map((v) => {

            var temp = this.state.permissionSelect

            var val = `${v.ID}.${type}`

            if (temp.includes(val)) {
                temp.splice(temp.indexOf(val), 1)
            } else {
                temp.push(val)
            }

            this.setState({permissionSelect: temp})

        })

    }

    onChangePermissionLine = (id) => {

        [`${id}.lista`,`${id}.lixeira`,`${id}.form`,`${id}.delete`].map((v) => {

            var temp = this.state.permissionSelect

            if (temp.includes(v)) {
                temp.splice(temp.indexOf(v), 1)
            } else {
                temp.push(v)
            }

            this.setState({permissionSelect: temp})

        })

    }

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue} onFieldsChange={(a, b) => this.onChangeField(a, b)}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16,0]}>
                                        <Col xs={24} md={6}>
                                            <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="LOGIN" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                            </Form.Item>
                                            <Switch unCheckedChildren="CPF" checkedChildren="CNPJ" defaultChecked={this.state.doc} onChange={(v) => this.setState({doc: v})} style={{position: 'absolute', right: 13, top: 35}} />
                                        </Col>
                                        <Col xs={24} md={18}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={16}>
                                            <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input addonBefore={<IoLogoGoogle />} type='email' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Whatsapp" name="WHATSAPP" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <span className="ant-input-group-wrapper css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                    <span className="ant-input-wrapper ant-input-group css-dev-only-do-not-override-w8mnev css-w8mnev">
                                                        <span className="ant-input-group-addon"><IoLogoWhatsapp /></span>
                                                        <ReactInputMask className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" mask={'+55 (99) 99999-9999'} value={this.state.defaultValue?.WHATSAPP} />
                                                    </span>
                                                </span>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Card size="small">
                                                <Row gutter={[8,8]} style={{paddingBottom: 8}}>
                                                    <Col span={12}></Col>
                                                    <Col span={3}><center><Button shape="round" type="primary" size="small" onClick={() => this.onChangePermissionColumn('lista')}>Marcar</Button></center></Col>
                                                    <Col span={3}><center><Button shape="round" type="primary" size="small" onClick={() => this.onChangePermissionColumn('lixeira')}>Marcar</Button></center></Col>
                                                    <Col span={3}><center><Button shape="round" type="primary" size="small" onClick={() => this.onChangePermissionColumn('form')}>Marcar</Button></center></Col>
                                                    <Col span={3}><center><Button shape="round" type="primary" size="small" onClick={() => this.onChangePermissionColumn('delete')}>Marcar</Button></center></Col>
                                                </Row>
                                                <Row gutter={[8,8]}>
                                                    <Col span={12}><Typography style={{fontWeight: 700}}>Permissão</Typography></Col>
                                                    <Col span={3}><Typography style={{fontWeight: 700, textAlign: 'center'}}>Lista</Typography></Col>
                                                    <Col span={3}><Typography style={{fontWeight: 700, textAlign: 'center'}}>Lixeira</Typography></Col>
                                                    <Col span={3}><Typography style={{fontWeight: 700, textAlign: 'center'}}>Formulário</Typography></Col>
                                                    <Col span={3}><Typography style={{fontWeight: 700, textAlign: 'center'}}>Deletar</Typography></Col>
                                                </Row>
                                                { this.state.listPermission.map((v, i) => (
                                                    <Row key={i} gutter={[8,8]} style={{paddingBottom: 0}}>
                                                        <Col span={24}><Divider style={{margin: 0, marginTop: 8}} /></Col>
                                                        <Col span={12}><Button shape="round" type="primary" size="small" onClick={() => this.onChangePermissionLine(v.ID)}>{v.NAME}</Button></Col>
                                                        <Col span={3}><center><Checkbox value={`${v.ID}.lista`} onChange={this.onChangePermission} checked={this.state.permissionSelect.includes(`${v.ID}.lista`) ? true : false} /></center></Col>
                                                        <Col span={3}><center><Checkbox value={`${v.ID}.lixeira`} onChange={this.onChangePermission} checked={this.state.permissionSelect.includes(`${v.ID}.lixeira`) ? true : false} /></center></Col>
                                                        <Col span={3}><center><Checkbox value={`${v.ID}.form`} onChange={this.onChangePermission} checked={this.state.permissionSelect.includes(`${v.ID}.form`) ? true : false} /></center></Col>
                                                        <Col span={3}><center><Checkbox value={`${v.ID}.delete`} onChange={this.onChangePermission} checked={this.state.permissionSelect.includes(`${v.ID}.delete`) ? true : false} /></center></Col>
                                                    </Row>
                                                ))}
                                            </Card>
                                        </Col>
                                        {/* { this.state.listPermission.map((v, i) => (
                                            <Col key={i} xs={24} md={Number(v.COL)}>
                                                <Card hoverable size="small" title={v.NAME}>
                                                    <Row>
                                                        <Col span={24}>
                                                        { this.state.loadPermission ? <Spin size="small"><Checkbox>Selecionar Todos</Checkbox></Spin> : <Checkbox onChange={this.onChangeAll} value={i}>Selecionar Todos</Checkbox> }
                                                        </Col>
                                                        { v.PERMISSION.map((v1, i1) => (
                                                            <Col span={24} key={i1} style={{ display: 'flex', alignItems: 'center', height: 22}}>
                                                                { this.state.loadPermission ? <Spin size="small"><Checkbox>{v1.DESCRIPTION}</Checkbox></Spin> : <Checkbox onChange={this.onChangeField} value={v1.ID} checked={this.state.permissionSelect.includes(v1.ID) ? true : false}>{v1.DESCRIPTION}</Checkbox> }
                                                            </Col>
                                                        )) }
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )) } */}
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminEquipeForm;