import { message } from "antd";

const CONF_URL = "https://jurisvip.com.br";
// const CONF_URL = "http://localhost";

var PATH = '';

export const setPath = (value) => { PATH = value }
export const getPath = () => { return PATH }

export const URL_API = CONF_URL+'/services/php';
export const UPLOAD_API = CONF_URL+'/services/php/upload/upload.php';

export const POST_CATCH = () => {
    message.error('Não foi possível estabelecer uma conexão com o sistema')
}

export const BLOCK_FORM_ENTER = (e) => {
    if (e.key === "Enter") {
        e.preventDefault();
    }
}

export const getUPLOADAPI = () => {
    return UPLOAD_API+'?path='+getPath()+'&token='+getToken();
}

export const titleWeb = (value) => {
    window.document.title = `JurisVIP | ${value}`
}

export const setToken = (value) => {
    localStorage.setItem('TOKEN', value);
}

export const getToken = () => {
    return localStorage.getItem('TOKEN');
}

export const delToken = () => {
    localStorage.removeItem('TOKEN')
}

export const setType = (value) => {
    localStorage.setItem('TYPE', value);
}

export const getType = () => {
    return localStorage.getItem('TYPE');
}

export const delType = () => {
    localStorage.removeItem('TYPE')
}

export const setConfig = (value) => {
    localStorage.setItem('CONFIG', value);
}

export const getConfig = () => {
    return localStorage.getItem('CONFIG');
}

export const delConfig = () => {
    localStorage.removeItem('CONFIG')
}

export const POST_API = (url, data) => {

    function createFormData () {
        const form = new FormData()
    
        Object.keys(data).forEach(key => {
            form.append(key, data[key]);
        });
      
        return form;
    };
  
    return fetch(URL_API+url, { method: "post", body: createFormData() })

}

export const verifyConfig = (value) => {
    try {
        return getConfig().includes(value)
    } catch (error) {
        return false
    }
}
