import React from "react";
import { Breadcrumb, Button, Col, Form, Input, Layout, Modal, Popover, Row, Select, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';
import { IoClose } from "react-icons/io5";

class AdminProcessFinancialForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        gastos: [],
        pagamento: [],
        value: 0,
        modal: false
    }

    back = "/painel/financeiro";

    routes = [
        {title: <Link to={this.back}>Financeiro</Link>},
        {title: 'Detalhes'}
    ]

    componentDidMount = () => {
        titleWeb('Financeiro')
        this.onView()
    }

    onView = async () => {
        this.setState({_screen: true})
        POST_API('/process_financial/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, gastos: res.data.GASTO, pagamento: res.data.PAY})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.GASTO = this.state.gastos
        values.PAY = this.state.pagamento
        POST_API('/process_financial/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    onSendValue = (values) => {
        this.setState({_loading: true})
        POST_API('/process_financial/savevalue.php', { token: getToken(), master: JSON.stringify({ ID: this.state.defaultValue.ID, AMOUNT_CHARGED: this.state.value }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.onView()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false, modal: false}))
    }

    onAddGasto   = ( ) => {
        var list = this.state.gastos
        list.push({ VALUE: '0', STATUS: 'Pendente', DATETIME_PAYMENT: '', NAME: '' })
        this.setState({gastos: list})
    }

    onChangeGasto = (campo, valor, index) => {
        var list = this.state.gastos
        list[index][campo] = valor
        this.setState({gastos: list})
    }

    onDeleteGasto = (index) => {
        var list = this.state.gastos
        list.splice(index, 1);
        this.setState({gastos: list})
    }

    onAddPayment   = ( ) => {
        var list = this.state.pagamento
        list.push({ VALUE: '0', STATUS: 'Pendente', DATETIME_PAYMENT: '', NAME: '' })
        this.setState({pagamento: list})
    }

    onChangePayment = (campo, valor, index) => {
        var list = this.state.pagamento
        list[index][campo] = valor
        this.setState({pagamento: list})
    }

    onDeletePayment = (index) => {
        var list = this.state.pagamento
        list.splice(index, 1);
        this.setState({pagamento: list})
    }

    onChangeValor = () => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Atualizar "Valor Cobrado"?',
            onOk: () => this.openCampo(),
            okText: 'Sim',
            onCancel: () => null,
            cancelText: 'Não'
        })
    }

    openCampo = () => {
        this.setState({modal: true})
    }

    atualizar = () => {
        
    }

    render = () => {

        if (this.state._navigate)  return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[16]}>
                                        <Col span={24}><Typography className="financial_title">Informações do Processo</Typography></Col>
                                        <Col xs={24} md={24}>
                                            <Typography className="financial_number">Número do Processo: {this.state.defaultValue.PROCESS_NUMBER}</Typography>
                                            <Typography className="financial_action">Tipo de Ação: {this.state.defaultValue.PROCESS_ACTION_NAME}</Typography>
                                            <Typography className="financial_register">Cadastro: {this.state.defaultValue.PROCESS_DATETIME_INSERT}</Typography>
                                            <Typography className="financial_filing">Data Ajuizamento: {this.state.defaultValue.PROCESS_DATE_FILING}</Typography>
                                            <br />
                                            <Typography>Clientes:</Typography>
                                            { this.state.defaultValue.CLIENTS.map((v, i) => (
                                                <Link to={'/painel/clientes/'+v.ID}>
                                                    <div className="financial_lawyer">
                                                        <Typography className="clientes" key={i}>{v.NAME}</Typography>
                                                        <Typography className="clientes" key={i}>{v.CPFCNPJ}</Typography>
                                                    </div>
                                                </Link>
                                            )) }
                                            { this.state.defaultValue.LAWYERS.lenght > 0 ? (
                                                <>
                                                    <br />
                                                    <Typography>Advogados Participantes:</Typography>
                                                    { this.state.defaultValue.LAWYERS.map((v, i) => (
                                                        <Link to={'/painel/advogados/'+v.ID}><Typography className="financial_lawyer" key={i}>{v.NAME}</Typography></Link>
                                                    )) }
                                                </>
                                            ) : null }
                                        </Col>
                                        <Col span={24}><Typography className="financial_title" style={{marginTop: 30}}>Observação Financeira</Typography></Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="OBS">
                                                <Input.TextArea rows={6} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}><Typography className="financial_title" style={{marginTop: 30}}>Resumo Financeiro</Typography></Col>
                                        <Col span={24} className="financial_resumo_col">
                                            <Typography className="financial_resumo">Valor da Causa:</Typography>
                                            <Form.Item name="PROCESS_VALUE_FORMAT" >
                                                <Input prefix={'R$'} readOnly={true} className="financial_resumo_campo" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} className="financial_resumo_col">
                                            <Typography className="financial_resumo">Valor Cobrado:</Typography>
                                            <Form.Item name="AMOUNT_CHARGED_FORMAT" >
                                                <Input style={{cursor: 'pointer'}} onClick={this.onChangeValor} prefix={'R$'} readOnly={true} className="financial_resumo_campo" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} className="financial_resumo_col">
                                            <Typography className="financial_resumo">Valor Recebido:</Typography>
                                            <Form.Item name="AMOUNT_RECEIVED_FORMAT" >
                                                <Input prefix={'R$'} readOnly={true} className="financial_resumo_campo" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} className="financial_resumo_col">
                                            <Typography className="financial_resumo">Gastos e Repasses:</Typography>
                                            <Form.Item name="EXPENSES_TRANSFERS_FORMAT" >
                                                <Input prefix={'R$'} readOnly={true} className="financial_resumo_campo" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} className="financial_resumo_col">
                                            <Typography className="financial_resumo">Valor a Receber:</Typography>
                                            <Form.Item name="AMOUNT_RECEIVABLE_FORMAT" >
                                                <Input prefix={'R$'} readOnly={true} className="financial_resumo_campo" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}><Typography className="financial_title" style={{marginTop: 30}}>Gastos e Repasses</Typography></Col>
                                        <Col span={24}>
                                            <Row gutter={[8,8]}>
                                                <Col span={3}><Typography className="financial_title_table">Valor</Typography></Col>
                                                <Col span={3}><Typography className="financial_title_table">Situação</Typography></Col>
                                                <Col span={3}><Typography className="financial_title_table">Data Pagamento</Typography></Col>
                                                <Col span={14}><Typography className="financial_title_table">Descrição</Typography></Col>
                                            </Row>
                                        </Col>
                                        { this.state.gastos.length > 0 ? (
                                            <>
                                                { this.state.gastos.map((v, i) => (
                                                    <Col span={24} style={{marginBottom: 8}}>
                                                        <Row gutter={[8,8]} align={'middle'}>
                                                            <Col span={3}><Input type="number" prefix="R$" className="financial_resumo_campo" value={v.VALUE} onChange={(v) => this.onChangeGasto('VALUE', v.target.value, i)} /></Col>
                                                            <Col span={3}><Select style={{width: '100%'}} value={v.STATUS} onChange={(v) => this.onChangeGasto('STATUS', v, i)}><Select.Option value="Pendente">Pendente</Select.Option><Select.Option value="Pago">Pago</Select.Option></Select></Col>
                                                            <Col span={4}><Input value={v.DATETIME_PAYMENT} type="date" onChange={(v) => this.onChangeGasto('DATETIME_PAYMENT', v.target.value, i)} /></Col>
                                                            <Col span={13}><Input value={v.NAME} onChange={(v) => this.onChangeGasto('NAME', v.target.value, i)} /></Col>
                                                            <Col span={1}><IoClose className="financial_delete" onClick={(v) => this.onDeleteGasto(i)} /></Col>
                                                        </Row>
                                                    </Col>
                                                )) }
                                            </>
                                        ) : <Col span={24}><Typography className="nodata">Nenhum dado cadastrado</Typography></Col>}
                                        <Col span={24}><center><Button onClick={this.onAddGasto} style={{marginTop: 10}} type="default" shape="round">Adicionar Gastos e Repasse</Button></center></Col>
                                        <Col span={24}><Typography className="financial_title" style={{marginTop: 30}}>Pagamentos</Typography></Col>
                                        <Col span={3}><Typography className="financial_title_table">Data Pagamento</Typography></Col>
                                        <Col span={3}><Typography className="financial_title_table">Valor</Typography></Col>
                                        <Col span={3}><Typography className="financial_title_table">Situação</Typography></Col>
                                        <Col span={15}><Typography className="financial_title_table">Descrição</Typography></Col>
                                        { this.state.pagamento.length > 0 ? (
                                            <>
                                                { this.state.pagamento.map((v, i) => (
                                                    <Col span={24} style={{marginBottom: 8}}>
                                                        <Row gutter={[8,8]} align={'middle'}>
                                                            <Col span={4}><Input value={v.DATETIME_PAYMENT} type="date" onChange={(v) => this.onChangePayment('DATETIME_PAYMENT', v.target.value, i)} /></Col>
                                                            <Col span={3}><Input type="number" prefix="R$" className="financial_resumo_campo" value={v.VALUE} onChange={(v) => this.onChangePayment('VALUE', v.target.value, i)} /></Col>
                                                            <Col span={3}><Select style={{width: '100%'}} value={v.STATUS} onChange={(v) => this.onChangePayment('STATUS', v, i)}><Select.Option value="Pendente">Pendente</Select.Option><Select.Option value="Pago">Pago</Select.Option></Select></Col>
                                                            <Col span={13}><Input value={v.NAME} onChange={(v) => this.onChangePayment('NAME', v.target.value, i)} /></Col>
                                                            <Col span={1}><IoClose className="financial_delete" onClick={(v) => this.onDeletePayment(i)} /></Col>
                                                        </Row>
                                                    </Col>
                                                )) }
                                            </>
                                        ) : <Col span={24}><Typography className="nodata">Nenhum dado cadastrado</Typography></Col>}
                                        <Col span={24}><center><Button onClick={this.onAddPayment} style={{marginTop: 10}} type="default" shape="round">Adicionar Pagamento</Button></center></Col>
                                        <Col xs={24} md={24}>
                                            <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" className="float-right">Salvar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) }
                    </Row>
                    <Modal title='Atualizar "Valor Cobrado"' onCancel={() => this.setState({modal: false})} open={this.state.modal} footer={[<Button onClick={this.onSendValue} type="primary" shape="round">Salvar</Button>]}>
                        <Input prefix={'R$'} type="number" className="financial_resumo_campo" value={this.state.value} onChange={(v) => this.setState({value: v.target.value})} />
                    </Modal>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminProcessFinancialForm;