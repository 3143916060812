import React from "react";
import { Avatar, Breadcrumb, Button, Card, Col, Dropdown, Form, Layout, Modal, Popover, Row, Select, Spin, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoEllipsisVertical } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';

class AdminTarefaList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        dragF: false,
        dragW: false,
        dragP: false,
        dragA: false,
        type: '',
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        filters: {
            CREDENTIAL_ID: [],
            CLIENT_ID: [],
            PROCESS_ID: [],
        },
        ind: null
    }

    back = "/painel/tarefas";

    routes = [
        {title: <Link to={this.back}>Tarefas</Link>},
        {title: this.props.type === 'list' ? 'Lista' : 'Lixeira'}
    ]

    componentDidMount = () => {
        titleWeb('Tarefas')
        this.onFilter()
        this.onRenderData()
    }

    onFilter = () => {
        POST_API('/task/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ filters: res.data })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
    }
    
    // LOAD DATA
    onRenderData = (values = null) => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/task/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(values), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        },
                        ind: res.summary
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/task/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onClickMenu = (v) => {

        var value = String(v.key).split('-')

        if ( value[0] === 'edit' ) this.setState({_navigate_edit: value[1]})
        if ( value[0] === 'comm' ) this.setState({_navigate_comment: value[1]})
        if ( value[0] === 'arqh' ) this.setState({_navigate_archive: value[1]})
        if ( value[0] === 'del'  ) this.onAction(value[1], 'del', 'Excluir tarefa?')

    }

    onEnd = (v) => {
        POST_API('/task/update.php', { token: getToken(), ID: v.target.dataset.id, TYPE: this.state.type }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.onRenderData()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    render = () => {
        
        if (this.state._navigate)  return (<Navigate to={'/painel'} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />    

        if (this.state._navigate_edit > 0) {
            return (<Navigate to={this.state._navigate_edit} />)
        }

        if (this.state._navigate_comment > 0) {
            return (<Navigate to={this.state._navigate_comment+'/comentarios'} />)
        }

        if (this.state._navigate_archive > 0) {
            return (<Navigate to={this.state._navigate_archive+'/documentos'} />)
        }

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'top'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.add`) ? <Link to={'add'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link> : null }
                        </Col>
                        <Col span={24}>
                            <Row className="table__row_table">
                                <Col xs={12} md={6}>
                                    <Typography className="ind-title">A Fazer</Typography>
                                    <Typography className="ind-text a-fazer">{this.state.ind?.AFAZER}</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="ind-title">Em Andamento</Typography>
                                    <Typography className="ind-text em-andamento">{this.state.ind?.EMANDAMENTO}</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="ind-title">Concluído</Typography>
                                    <Typography className="ind-text concluido">{this.state.ind?.CONCLUIDO}</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="ind-title a-fazer">Atrasados</Typography>
                                    <Typography className="ind-text atrasados">{this.state.ind?.ATRASADOS}</Typography>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Form className="table__row_table" layout="vertical" onFinish={this.onRenderData}>
                                <Row gutter={[8,8]} align={"bottom"}>
                                    <Col md={7} xs={24}>
                                        <Form.Item initialValue={'*'} label="Responsável" name="CREDENTIAL_ID">
                                            <Select>
                                                <Select.Option value="*">Todos</Select.Option>
                                                { this.state.filters.CREDENTIAL_ID.map((v, i) => (
                                                    <Select.Option key={i} value={v.key}>{v.NAME}</Select.Option>
                                                )) }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={7} xs={24}>
                                        <Form.Item initialValue={'*'} label="Processo" name="PROCESS_ID">
                                            <Select>
                                                <Select.Option value="*">Todos</Select.Option>
                                                { this.state.filters.PROCESS_ID.map((v, i) => (
                                                    <Select.Option key={i} value={v.key}>{v.NUMBER}</Select.Option>
                                                )) }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={7} xs={24}>
                                        <Form.Item initialValue={'*'} label="Cliente" name="CLIENT_ID">
                                            <Select>
                                                <Select.Option value="*">Todos</Select.Option>
                                                { this.state.filters.CLIENT_ID.map((v, i) => (
                                                    <Select.Option key={i} value={v.key}>{v.NAME}</Select.Option>
                                                )) }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={3} xs={24}>
                                        <Button style={{marginBottom: 24}} loading={this.state.table_load} block htmlType="submit" type="primary" shape="round">Filtrar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col md={6} xs={24}>
                            <Card onDragLeave={() => this.setState({dragW: false})} onDragOver={() => this.setState({dragW: true, type: 'W'})} style={{backgroundColor: '#8f8f8f11'}} size="small" title={<Typography style={{textAlign: 'center'}}>A fazer</Typography>}>
                                { this.state.table_load ? <center><Spin /></center> : 
                                    <>
                                        { this.state.table_data?.W && this.state.table_data?.W.map((v, i) => (
                                            <Card data-id={v.ID} onDragEnd={this.onEnd} key={i} style={{marginBottom: 10}} draggable hoverable size="small" title={v.TITLE} extra={
                                                <Dropdown menu={{items: [
                                                    verifyConfig('tdc.list') ? { label: 'Arquivos', key: `arqh-${v.ID}` } : null,
                                                    verifyConfig('tcm.list') ? { label: 'Comentários', key: `comm-${v.ID}` } : null,
                                                    verifyConfig(`${this.props.per}.edit`) ? { label: 'Editar', key: `edit-${v.ID}` } : null,
                                                    verifyConfig(`${this.props.per}.del`) ? { label: 'Deletar', key: `del-${v.ID}` } : null
                                                ], onClick: this.onClickMenu}}>
                                                    <Button type="link" shape="circle" size="small" className="btn-link"><IoEllipsisVertical/></Button>
                                                </Dropdown>
                                            }>
                                                <Row gutter={[4,8]}>
                                                    { v.DESC ? <Col span={24}><Typography>{v.DESC}</Typography></Col> : null }
                                                    { v.PROCESS_ID > 0 ? (
                                                        <Col span={24}>
                                                            <Typography className="task-title">Vinculado ao processo</Typography>
                                                            <Typography className="task-text">{v.PROCESS_NUMBER}</Typography>
                                                            { v.CLIENT_PROCESS.length > 0 && v.CLIENT_PROCESS.map((v1, i1) => (
                                                                <Typography className="task-client">{v1.NAME}</Typography>
                                                            ))}
                                                        </Col>
                                                    ) : null }
                                                    { v.CLIENT_ID > 0 ? (
                                                        <Col span={24}>
                                                            <Typography className="task-title">Vinculado ao cliente</Typography>
                                                            <Typography className="task-text">{v.CLIENT_NAME}</Typography>
                                                        </Col>
                                                    ) : null }
                                                    <Col span={24}>
                                                        <Avatar.Group>
                                                            { v.PART && v.PART.map((pv, pk) => (
                                                                <Popover key={pk} content={pv.NAME} ><Avatar size={30} src={pv.AVATAR} /></Popover>
                                                            )) }
                                                        </Avatar.Group>
                                                    </Col>
                                                    <Col span={24}><Typography title="Data de conclusão" style={{textAlign: 'end', fontSize: 12, color: v.COLOR_TEMPDIFF}}>{v.DATE_FINISH_FORMAT} {v.TIME_FINISH_FORMAT}</Typography></Col>
                                                    <Col span={24}><Typography style={{textAlign: 'end', fontSize: 12, color: '#00000056'}}>Criado por {v.CREDENTIAL_NAME}</Typography></Col>
                                                    <Col span={24}><Typography style={{textAlign: 'end', fontSize: 12, color: '#00000056', marginTop: '-10px'}}>Em {v.DATETIME_INSERT_FORMAT}</Typography></Col>
                                                </Row>
                                            </Card>
                                        )) }
                                        { this.state.dragW ? <Card></Card> : null }
                                    </>
                                }
                            </Card>
                        </Col>
                        <Col md={6} xs={24}>
                            <Card onDragLeave={() => this.setState({dragP: false})} onDragOver={() => this.setState({dragP: true, type: 'P'})} style={{backgroundColor: '#8f8f8f11'}} size="small" title={<Typography style={{textAlign: 'center'}}>Em andamento</Typography>}>
                                { this.state.table_load ? <center><Spin /></center> : 
                                    <>
                                    { this.state.table_data?.P && this.state.table_data?.P.map((v, i) => (
                                        <Card data-id={v.ID} onDragEnd={this.onEnd} key={i} style={{marginBottom: 10}} draggable hoverable size="small" title={v.TITLE} extra={
                                            <Dropdown menu={{items: [
                                                verifyConfig('tdc.list') ? { label: 'Arquivos', key: `arqh-${v.ID}` } : null,
                                                verifyConfig('tcm.list') ? { label: 'Comentários', key: `comm-${v.ID}` } : null,
                                                verifyConfig(`${this.props.per}.edit`) ? { label: 'Editar', key: `edit-${v.ID}` } : null,
                                                verifyConfig(`${this.props.per}.del`) ? { label: 'Deletar', key: `del-${v.ID}` } : null
                                            ], onClick: this.onClickMenu}}>
                                                <Button type="link" shape="circle" size="small" className="btn-link"><IoEllipsisVertical/></Button>
                                            </Dropdown>
                                        }>
                                            <Row gutter={[4,8]}>
                                                { v.DESC ? <Col span={24}><Typography>{v.DESC}</Typography></Col> : null }
                                                { v.PROCESS_ID > 0 ? (
                                                    <Col span={24}>
                                                        <Typography className="task-title">Vinculado ao processo</Typography>
                                                        <Typography className="task-text">{v.PROCESS_NUMBER}</Typography>
                                                        { v.CLIENT_PROCESS.length > 0 && v.CLIENT_PROCESS.map((v1, i1) => (
                                                            <Typography className="task-client">{v1.NAME}</Typography>
                                                        ))}
                                                    </Col>
                                                ) : null }
                                                { v.CLIENT_ID > 0 ? (
                                                    <Col span={24}>
                                                        <Typography className="task-title">Vinculado ao cliente</Typography>
                                                        <Typography className="task-text">{v.CLIENT_NAME}</Typography>
                                                    </Col>
                                                ) : null }
                                                <Col span={24}>
                                                    <Avatar.Group>
                                                        { v.PART && v.PART.map((pv, pk) => (
                                                            <Popover key={pk} content={pv.NAME} ><Avatar size={30} src={pv.AVATAR} /></Popover>
                                                        )) }
                                                    </Avatar.Group>
                                                </Col>
                                                <Col span={24}><Typography title="Data de conclusão" style={{textAlign: 'end', fontSize: 12, color: v.COLOR_TEMPDIFF}}>{v.DATE_FINISH_FORMAT} {v.TIME_FINISH_FORMAT}</Typography></Col>
                                                <Col span={24}><Typography style={{textAlign: 'end', fontSize: 12, color: '#00000056'}}>Criado por {v.CREDENTIAL_NAME}</Typography></Col>
                                                <Col span={24}><Typography style={{textAlign: 'end', fontSize: 12, color: '#00000056', marginTop: '-10px'}}>Em {v.DATETIME_INSERT_FORMAT}</Typography></Col>
                                            </Row>
                                        </Card>
                                    )) }
                                    { this.state.dragP ? <Card></Card> : null }
                                    </>
                                }
                            </Card>
                        </Col>
                        <Col md={6} xs={24}>
                            <Card onDragLeave={() => this.setState({dragF: false})} onDragOver={() => this.setState({dragF: true, type: 'F'})} style={{backgroundColor: '#8f8f8f11'}} size="small" title={<Typography style={{textAlign: 'center'}}>Concluído</Typography>}>
                                { this.state.table_load ? <center><Spin /></center> : 
                                    <>
                                    { this.state.table_data?.F && this.state.table_data?.F.map((v, i) => (
                                        <Card data-id={v.ID} onDragEnd={this.onEnd} key={i} style={{marginBottom: 10}} draggable hoverable size="small" title={v.TITLE} extra={
                                            <Dropdown menu={{items: [
                                                verifyConfig('tdc.list') ? { label: 'Arquivos', key: `arqh-${v.ID}` } : null,
                                                    verifyConfig('tcm.list') ? { label: 'Comentários', key: `comm-${v.ID}` } : null,
                                                    verifyConfig(`${this.props.per}.edit`) ? { label: 'Editar', key: `edit-${v.ID}` } : null,
                                                    verifyConfig(`${this.props.per}.del`) ? { label: 'Deletar', key: `del-${v.ID}` } : null
                                            ], onClick: this.onClickMenu}}>
                                                <Button type="link" shape="circle" size="small" className="btn-link"><IoEllipsisVertical/></Button>
                                            </Dropdown>
                                        }>
                                            <Row gutter={[4,8]}>
                                                { v.DESC ? <Col span={24}><Typography>{v.DESC}</Typography></Col> : null }
                                                { v.PROCESS_ID > 0 ? (
                                                    <Col span={24}>
                                                        <Typography className="task-title">Vinculado ao processo</Typography>
                                                        <Typography className="task-text">{v.PROCESS_NUMBER}</Typography>
                                                        { v.CLIENT_PROCESS.length > 0 && v.CLIENT_PROCESS.map((v1, i1) => (
                                                            <Typography className="task-client">{v1.NAME}</Typography>
                                                        ))}
                                                    </Col>
                                                ) : null }
                                                { v.CLIENT_ID > 0 ? (
                                                    <Col span={24}>
                                                        <Typography className="task-title">Vinculado ao cliente</Typography>
                                                        <Typography className="task-text">{v.CLIENT_NAME}</Typography>
                                                    </Col>
                                                ) : null }
                                                <Col span={24}>
                                                    <Avatar.Group>
                                                        { v.PART && v.PART.map((pv, pk) => (
                                                            <Popover key={pk} content={pv.NAME} ><Avatar size={30} src={pv.AVATAR} /></Popover>
                                                        )) }
                                                    </Avatar.Group>
                                                </Col>
                                                <Col span={24}><Typography title="Data de conclusão" style={{textAlign: 'end', fontSize: 12, color: v.COLOR_TEMPDIFF}}>{v.DATE_FINISH_FORMAT} {v.TIME_FINISH_FORMAT}</Typography></Col>
                                                <Col span={24}><Typography style={{textAlign: 'end', fontSize: 12, color: '#00000056'}}>Criado por {v.CREDENTIAL_NAME}</Typography></Col>
                                                <Col span={24}><Typography style={{textAlign: 'end', fontSize: 12, color: '#00000056', marginTop: '-10px'}}>Em {v.DATETIME_INSERT_FORMAT}</Typography></Col>
                                            </Row>
                                        </Card>
                                    )) }
                                    { this.state.dragF ? <Card></Card> : null }
                                    </>
                                }
                            </Card>
                        </Col>
                        <Col md={6} xs={24}>
                            <Card onDragLeave={() => this.setState({dragA: false})} onDragOver={() => this.setState({dragA: true, type: 'P'})} style={{backgroundColor: '#8f8f8f11'}} size="small" title={<Typography style={{textAlign: 'center'}}>Atrasados</Typography>}>
                                { this.state.table_load ? <center><Spin /></center> : 
                                    <>
                                    { this.state.table_data?.A && this.state.table_data?.A.map((v, i) => (
                                        <Card data-id={v.ID} onDragEnd={this.onEnd} key={i} style={{marginBottom: 10}} draggable hoverable size="small" title={v.TITLE} extra={
                                            <Dropdown menu={{items: [
                                                verifyConfig('tdc.list') ? { label: 'Arquivos', key: `arqh-${v.ID}` } : null,
                                                    verifyConfig('tcm.list') ? { label: 'Comentários', key: `comm-${v.ID}` } : null,
                                                    verifyConfig(`${this.props.per}.edit`) ? { label: 'Editar', key: `edit-${v.ID}` } : null,
                                                    verifyConfig(`${this.props.per}.del`) ? { label: 'Deletar', key: `del-${v.ID}` } : null
                                            ], onClick: this.onClickMenu}}>
                                                <Button type="link" shape="circle" size="small" className="btn-link"><IoEllipsisVertical/></Button>
                                            </Dropdown>
                                        }>
                                            <Row gutter={[4,8]}>
                                                { v.DESC ? <Col span={24}><Typography>{v.DESC}</Typography></Col> : null }
                                                { v.PROCESS_ID > 0 ? (
                                                    <Col span={24}>
                                                        <Typography className="task-title">Vinculado ao processo</Typography>
                                                        <Typography className="task-text">{v.PROCESS_NUMBER}</Typography>
                                                        { v.CLIENT_PROCESS.length > 0 && v.CLIENT_PROCESS.map((v1, i1) => (
                                                            <Typography className="task-client">{v1.NAME}</Typography>
                                                        ))}
                                                    </Col>
                                                ) : null }
                                                { v.CLIENT_ID > 0 ? (
                                                    <Col span={24}>
                                                        <Typography className="task-title">Vinculado ao cliente</Typography>
                                                        <Typography className="task-text">{v.CLIENT_NAME}</Typography>
                                                    </Col>
                                                ) : null }
                                                <Col span={24}>
                                                    <Avatar.Group>
                                                        { v.PART && v.PART.map((pv, pk) => (
                                                            <Popover key={pk} content={pv.NAME} ><Avatar size={30} src={pv.AVATAR} /></Popover>
                                                        )) }
                                                    </Avatar.Group>
                                                </Col>
                                                <Col span={24}><Typography title="Data de conclusão" style={{textAlign: 'end', fontSize: 12, color: v.COLOR_TEMPDIFF}}>{v.DATE_FINISH_FORMAT} {v.TIME_FINISH_FORMAT}</Typography></Col>
                                                <Col span={24}><Typography style={{textAlign: 'end', fontSize: 12, color: '#00000056'}}>Criado por {v.CREDENTIAL_NAME}</Typography></Col>
                                                <Col span={24}><Typography style={{textAlign: 'end', fontSize: 12, color: '#00000056', marginTop: '-10px'}}>Em {v.DATETIME_INSERT_FORMAT}</Typography></Col>
                                            </Row>
                                        </Card>
                                    )) }
                                    { this.state.dragA ? <Card></Card> : null }
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminTarefaList;