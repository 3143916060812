import React from "react";
import { Col, Image, Layout, Row, Typography } from 'antd';
import NavbarComponent from "../../components/Navbar";
import { Outlet } from "react-router";

import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

import logo from '../../assets/img/logo/jurisvip-all-branco-transparente-menor.png';
import { IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

class WebMain extends React.Component {

    onClick = () => {
        window.document.getElementById('web-content').scrollTo(0, 0)
    }

    render = () => {

        return (

            <Layout.Content className="web-body">
                <NavbarComponent />
                <Layout.Content id="web-content" className="web-content">
                    <div id="teste-div">
                        <Outlet />
                    </div>
                    <Row style={{ marginTop: 40 }}>
                        <Col span={24} className="home-col-black">
                            <Row gutter={[16, 30]} className="home-row-page-footer">
                                <Col md={8} xs={24} className="home-row-col">
                                    <Image src={logo} preview={false} width={'50%'} style={{ marginBottom: 10 }} />
                                    <Typography className="home-row-page-footer-text"><span>Telefone:</span> +99 99 9999 9999</Typography>
                                    <Typography className="home-row-page-footer-text"><span>E-mail:</span> info@example.com</Typography>
                                    <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
                                        <Col><a href={'#1'} className="home-row-page-footer-social"><FaInstagram color="#FFF" /></a></Col>
                                        <Col><a href={'#1'} className="home-row-page-footer-social"><FaFacebookF color="#FFF" /></a></Col>
                                        <Col><a href={'#1'} className="home-row-page-footer-social"><FaLinkedin color="#FFF" /></a></Col>
                                        <Col><a href={'#1'} className="home-row-page-footer-social"><FaTwitter color="#FFF" /></a></Col>
                                    </Row>
                                </Col>
                                <Col md={8} xs={12}>
                                    <Typography className="home-row-page-footer-title">Link Úteis</Typography>
                                    <Link to='/' onClick={this.onClick}><Typography className="home-row-page-footer-link"> <IoChevronForwardOutline /> Home</Typography></Link>
                                    <Link to='/quemsomos' onClick={this.onClick}><Typography className="home-row-page-footer-link"> <IoChevronForwardOutline /> Quem Somos</Typography></Link>
                                    <Link to='/termos' onClick={this.onClick}><Typography className="home-row-page-footer-link"> <IoChevronForwardOutline /> Termos de Uso</Typography></Link>
                                    <Link to='/politica' onClick={this.onClick}><Typography className="home-row-page-footer-link"> <IoChevronForwardOutline /> Política de Privacidade</Typography></Link>
                                </Col>
                                <Col md={8} xs={12}>
                                    <Typography className="home-row-page-footer-title">JurisVip</Typography>
                                    <Link to='/planos' onClick={this.onClick}><Typography className="home-row-page-footer-link"> <IoChevronForwardOutline /> Planos</Typography></Link>
                                    <Link to='/advogados' onClick={this.onClick}><Typography className="home-row-page-footer-link"> <IoChevronForwardOutline /> Advogados</Typography></Link>
                                    <Link to='/faq' onClick={this.onClick}><Typography className="home-row-page-footer-link"> <IoChevronForwardOutline /> FAQ - Perguntas Frequentes</Typography></Link>
                                    <Link to='/contato' onClick={this.onClick}><Typography className="home-row-page-footer-link"> <IoChevronForwardOutline /> Contato</Typography></Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} className="home-col-copy">
                            <Typography className="home-copy">© Copyright <b>JurisVip</b>. Todos os direitos reservados.</Typography>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout.Content>

        )

    }

}

export default WebMain;