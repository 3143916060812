import React from "react";
import { Button, Card, Col, Row, Spin, message, Layout, Breadcrumb, Typography } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken } from "../../../services/api";

class AdminPageView extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        rows: [],
    }

    back = "/painel/politicastermos";

    routes = [
        {title: <Link to={this.back}>Políticas e Termos</Link>},
        {title: this.state._params[5]},
        {title: 'Versão '+this.state._params[7]}
    ]

    componentDidMount = () => {
        this.onView()
    }

    onView = async () => {
        POST_API('/page_body/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({PAGE_ID: this.state._params[5], VER: this.state._params[7]}), sorter: JSON.stringify({ columnKey: 'page_body.`ROW`, page_body.`COLUMN`, page_body.`ITEM`', order: 'ascend' }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({rows: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
        POST_API('/page/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}), VER: this.state._params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={this.back} />)
        }

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <Col span={24}><center><Spin /></center></Col> : (
                            <Col span={24}>
                                <Card size="small">
                                    <Row className="home-row-page">
                                        <Col span={24}><Typography className="home-row-page-text-title">{this.state.defaultValue.TITLE}</Typography></Col>
                                        { this.state.rows.map((v, i) => (
                                            <>
                                                <Row gutter={[16,0]} key={i}>
                                                    { v.columns.map((cv, ci) => (
                                                        <Col key={ci} span={24/v.type}>
                                                            <Row>
                                                                { cv.items.map((iv, ii) => (
                                                                    <Col span={24} key={ii} style={{marginBottom: 10}}>
                                                                        { iv.type === 'T' ? <Typography className="policy-row-title" style={{marginTop: 30, marginBottom: 20}}>{iv.value}</Typography> : null }
                                                                        { iv.type === 'P' ? <Typography className="policy-row-subtitle">{iv.value}</Typography> : null }
                                                                        { iv.type === 'L' ? <Typography className="policy-row-subtitle-line">{iv.value}</Typography> : null }
                                                                    </Col>
                                                                )) }
                                                            </Row>
                                                        </Col>
                                                    )) }
                                                </Row>
                                            </>
                                        )) }
                                        <Col span={24}><Typography className="home-row-page-version">Versão {this.state.defaultValue.VERSIONS[0].VER} - Atualizada em {this.state.defaultValue.VERSIONS[0].DATE_VER}</Typography></Col>
                                    </Row>
                                </Card>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminPageView;