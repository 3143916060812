import React from "react";
import { Layout, Row, Col, Typography, Button, Input, Switch, message } from 'antd';
import { FaFacebookF, FaGoogle, FaLinkedin } from "react-icons/fa";
import { IoArrowUndo, IoLockOpen, IoPerson } from "react-icons/io5";
import { Link, Navigate } from 'react-router-dom';
import { POST_API, POST_CATCH, setToken, setType } from "../../services/api";
import ReactInputMask from "react-input-mask";

class AuthLogin extends React.Component {
    
    state = {
        _navigate: false,
        load: false,
        side: 0,
        doc: false,
        form: {
            login: '',
            pass: '',
        }
    }

    onChangeValue = (field, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value
            }
        })
    }

    onChangeSide = (side) => {
        this.setState({side: side, doc: false, form: { login: '', pass: '' }})
    }

    onSend = async () => {

        const { login, pass } = this.state.form
        if ( login === '' || pass === '' ) {
            message.warning('Por favor, preencha todos os campos')
        } else {

            this.setState({load: true})
            const values = {
                LOGIN: login,
                PASSWORD: pass,
                VISIBLE: (this.state.side === 0) ? "'D','P','A'" : "'SC'"
            }

            await POST_API('/credential/login.php', {token: 'X', master: JSON.stringify(values)}).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success(res.msg)
                    setToken(res.token)
                    setType(res.type)
                    this.setState({_navigate: true})
                } else {
                    message.warning(res.msg)
                }
            }).catch(() => { POST_CATCH() }).finally(() => {
                this.setState({load: false})
            })

        }

    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/painel" />
        }

        return (
            <Layout.Content className="web-body">
                <Layout.Content className="auth-content">
                    <Row className="auth-box-login">
                        <Col className={ this.state.side === 0 ? "auth-col active" : "auth-col" }>
                            <Link to="/"><Button shape="circle" className="btn-primary btn-auth" type="primary"><IoArrowUndo size={22} /></Button></Link>
                            { this.state.side !== 0 ? (
                                <Row justify={'center'} gutter={[8,8]}>
                                    <Col span={24} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <Typography className="auth-col-title">Sou Advogado</Typography>
                                        <Typography style={{marginTop: 10}} className="auth-col-text">Lorem ipsum dolor sit.</Typography>
                                        <Typography style={{marginTop: 10}} className="auth-col-text">Lorem ipsum dolor sit amet elit.</Typography>
                                        <Button onClick={() => this.onChangeSide(0)} style={{marginTop: 20}} shape="round" type="primary" className="btn-primary">CONTINUAR</Button>
                                    </Col>
                                </Row>
                            ): (
                                <>
                                    <Typography className="auth-col-title">Sou Advogado</Typography>
                                    <Row gutter={[8,8]} style={{marginTop: 10}}>
                                        <Col><Button className="auth-social"><FaFacebookF /></Button></Col>
                                        <Col><Button className="auth-social"><FaGoogle /></Button></Col>
                                        <Col><Button className="auth-social"><FaLinkedin /></Button></Col>
                                    </Row>
                                    <Typography style={{marginTop: 10}} className="auth-col-text color-white">Ou utilize o seu CPF para o acesso:</Typography>
                                    <div style={{height: 40}} className="auth-div auth-input ant-input ant-input-lg css-dev-only-do-not-override-w8mnev css-w8mnev">
                                        <IoPerson />
                                        <ReactInputMask className="doc-input" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} defaultValue={this.state.form.login} onChange={(v) => this.onChangeValue('login', v.target.value)} />
                                        <Switch unCheckedChildren="CPF" checkedChildren="CNPJ" onChange={(v) => this.setState({doc: v})} />
                                    </div>
                                    <Input size="large" prefix={<IoLockOpen />} className="auth-input" placeholder="Senha" type="password" defaultValue={this.state.form.pass} onChange={(v) => this.onChangeValue('pass', v.target.value)} />
                                    <Link to="/esqueceuasenha"><Typography style={{marginTop: 10}} className="auth-col-text color-white">Esqueceu a Senha?</Typography></Link>
                                    <div className="plan-card-button"><Button type="default" className="btn-outline" shape="round" loading={this.state.load} onClick={this.onSend}>ENTRAR</Button></div>
                                </>
                            ) }
                        </Col>
                        <Col className={ this.state.side === 1 || this.state.side === 2 ? "auth-col active" : "auth-col" }>
                            { this.state.side === 0 ? (
                                <Row justify={'center'} gutter={[8,8]}>
                                    <Col span={24} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <Typography className="auth-col-title">Sou Cliente</Typography>
                                        <Typography style={{marginTop: 10}} className="auth-col-text">Lorem ipsum dolor sit.</Typography>
                                        <Typography style={{marginTop: 10}} className="auth-col-text">Lorem ipsum dolor sit amet elit.</Typography>
                                        <Button onClick={() => this.onChangeSide(1)} style={{marginTop: 20}} shape="round" type="primary" className="btn-primary">CONTINUAR</Button>
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Typography className="auth-col-title">Sou Cliente</Typography>
                                    <Row gutter={[8,8]} style={{marginTop: 10}}>
                                        <Col><Button className="auth-social"><FaFacebookF /></Button></Col>
                                        <Col><Button className="auth-social"><FaGoogle /></Button></Col>
                                        <Col><Button className="auth-social"><FaLinkedin /></Button></Col>
                                    </Row>
                                    <Typography style={{marginTop: 10}} className="auth-col-text color-white">Ou utilize o seu CPF para o acesso:</Typography>
                                    <div style={{height: 40}} className="auth-div auth-input ant-input ant-input-lg css-dev-only-do-not-override-w8mnev css-w8mnev">
                                        <IoPerson />
                                        <ReactInputMask className="doc-input" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} defaultValue={this.state.form.login} onChange={(v) => this.onChangeValue('login', v.target.value)} />
                                        <Switch unCheckedChildren="CPF" checkedChildren="CNPJ" onChange={(v) => this.setState({doc: v})} />
                                    </div>
                                    <Input size="large" prefix={<IoLockOpen />} className="auth-input" placeholder="Senha" type="password" defaultValue={this.state.form.pass} onChange={(v) => this.onChangeValue('pass', v.target.value)} />
                                    <Link to="/esqueceuasenha"><Typography style={{marginTop: 10}} className="auth-col-text color-white">Esqueceu a Senha?</Typography></Link>
                                    <div className="plan-card-button"><Button type="default" className="btn-outline" shape="round" loading={this.state.load} onClick={this.onSend}>ENTRAR</Button></div>
                                </>
                            ) }
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout.Content>

        )

    }

}

export default AuthLogin;