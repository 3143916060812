import { Avatar, Button, Card, Carousel, Col, Image, Row, Typography } from "antd";
import React from "react";

import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';

import about from '../../assets/img/about/about-img.png';
import { IoClipboardOutline, IoGlobeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

class WebHome extends React.Component {

    onClick = () => {
        window.document.getElementById('web-content').scrollTo(0,0)
    }

    render = () => {

        return (
            
            <Row>
                <Col span={24}>
                    <Carousel className="home-carousel" autoplay>
                        <div>
                            <div className="home-carousel-item" style={{backgroundImage: 'url(https://jurisvip.adsolucoestecnologia.com.br/assets/img/slide/slide-1.jpg)'}}>
                                <div className="home-carousel-item-shadow">
                                    <Typography className="home-carousel-item-title">Sou advogado</Typography>
                                    <Typography className="home-carousel-item-subtitle">Faça a gestão dos seus processos, prazos e agendamentos, compartilhe informações e comunique-se com seus clientes.</Typography>
                                    <Button type="primary" className="btn-primary home-carousel-item-button">Quero me cadastrar!</Button>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </Col>
                <Col span={24}>
                    <Row gutter={[60,24]} className="home-row-page">
                        <Col xs={24} md={10} className="home-row-page-about">
                            <Typography className="home-row-title">Quem Somos</Typography>
                            <Typography className="home-row-subtitle">Startup jurídica 100% nacional e focada em agilidade, controle, gestão e atendimento, com a nossa plataforma vamos otimizar a gestão de seus processos e relacionamento com os clientes.</Typography>
                            <Link to="quemsomos"><Button onClick={this.onClick} shape="round" type="default" className="btn-default" style={{fontSize: 10}}>SAIBA MAIS</Button></Link>
                        </Col>
                        <Col xs={24} md={14}>
                            <Image src={about} preview={false} style={{width: '100%', borderRadius: 8}} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="home-col-light">
                    <Row justify={'center'} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text">A melhor plataforma de gestão de processos para escritório de advogacia a advogados autônomos</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text-center">prática <span>+</span> fácil <span>+</span> rápida</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">A JurisVip valoriza o tempo do advogado, com poucos cliques você faz gestão dos processos, clientes, pagamentos e ainda facilita sua comunicação com os clientes.</Typography></Col>
                        <Col span={24}><center><Button type="primary" className="btn-primary" style={{marginTop: 8}}>Criar conta grátis</Button></center></Col>
                    </Row>
                </Col>
                <Col span={24} className="home-col-white">
                    <Row justify={'center'} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-center">Faça seu escritório crescer com essas super funcionalidades!</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">Gestão completa de processos</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">Tarefas para sua equipe</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">Controle financeiro</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">Informação na mão de qualquer lugar</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">Clientes mais satisfeitos, atendimento ágil e fácil</Typography></Col>
                        <Col span={24}><center><Button type="primary" className="btn-primary" style={{marginTop: 8}}>Fale com um especialista</Button></center></Col>
                    </Row>
                </Col>
                <Col span={24} className="home-col-light">
                    <Row justify={'center'} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title">Depoimentos</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">Depoimentos de quem já passou por aqui!</Typography></Col>
                        <Col xs={0} md={24}>
                            <Carousel className="home-carousel" slidesToShow={2} autoplay>
                                <div>
                                    <div style={{margin: 15, marginBottom: 50}}>
                                        <Card hoverable>
                                            <Row gutter={[16,16]}>
                                                <Col span={24}>
                                                    <Card.Meta
                                                        avatar={<Avatar shape="square" size={55.14} src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                        title="Card title"
                                                        description="This is the description"
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Typography style={{textAlign: 'justify'}}><RiDoubleQuotesL color="var(--primary)"/> Quis quorum legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid. <RiDoubleQuotesR color="var(--primary)"/></Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                                <div>
                                    <div style={{margin: 15, marginBottom: 50}}>
                                        <Card hoverable>
                                            <Row gutter={[16,16]}>
                                                <Col span={24}>
                                                    <Card.Meta
                                                        avatar={<Avatar shape="square" size={55.14} src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                        title="Card title"
                                                        description="This is the description"
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Typography style={{textAlign: 'justify'}}><RiDoubleQuotesL color="var(--primary)"/> Quis quorum legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid. <RiDoubleQuotesR color="var(--primary)"/></Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                                <div>
                                    <div style={{margin: 15, marginBottom: 50}}>
                                        <Card hoverable>
                                            <Row gutter={[16,16]}>
                                                <Col span={24}>
                                                    <Card.Meta
                                                        avatar={<Avatar shape="square" size={55.14} src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                        title="Card title"
                                                        description="This is the description"
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Typography style={{textAlign: 'justify'}}><RiDoubleQuotesL color="var(--primary)"/> Quis quorum legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid. <RiDoubleQuotesR color="var(--primary)"/></Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                                <div>
                                    <div style={{margin: 15, marginBottom: 50}}>
                                        <Card hoverable>
                                            <Row gutter={[16,16]}>
                                                <Col span={24}>
                                                    <Card.Meta
                                                        avatar={<Avatar shape="square" size={55.14} src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                        title="Card title"
                                                        description="This is the description"
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Typography style={{textAlign: 'justify'}}><RiDoubleQuotesL color="var(--primary)"/> Quis quorum legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid. <RiDoubleQuotesR color="var(--primary)"/></Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            </Carousel>
                        </Col>
                        <Col xs={24} md={0}>
                            <Carousel className="home-carousel" slidesToShow={1} autoplay>
                                <div>
                                    <div style={{margin: 15, marginBottom: 50}}>
                                        <Card hoverable>
                                            <Row gutter={[16,16]}>
                                                <Col span={24}>
                                                    <Card.Meta
                                                        avatar={<Avatar shape="square" size={55.14} src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                        title="Card title"
                                                        description="This is the description"
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Typography style={{textAlign: 'justify'}}><RiDoubleQuotesL color="var(--primary)"/> Quis quorum legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid. <RiDoubleQuotesR color="var(--primary)"/></Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                                <div>
                                    <div style={{margin: 15, marginBottom: 50}}>
                                        <Card hoverable>
                                            <Row gutter={[16,16]}>
                                                <Col span={24}>
                                                    <Card.Meta
                                                        avatar={<Avatar shape="square" size={55.14} src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                        title="Card title"
                                                        description="This is the description"
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Typography style={{textAlign: 'justify'}}><RiDoubleQuotesL color="var(--primary)"/> Quis quorum legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid. <RiDoubleQuotesR color="var(--primary)"/></Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                                <div>
                                    <div style={{margin: 15, marginBottom: 50}}>
                                        <Card hoverable>
                                            <Row gutter={[16,16]}>
                                                <Col span={24}>
                                                    <Card.Meta
                                                        avatar={<Avatar shape="square" size={55.14} src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                        title="Card title"
                                                        description="This is the description"
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Typography style={{textAlign: 'justify'}}><RiDoubleQuotesL color="var(--primary)"/> Quis quorum legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid. <RiDoubleQuotesR color="var(--primary)"/></Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                                <div>
                                    <div style={{margin: 15, marginBottom: 50}}>
                                        <Card hoverable>
                                            <Row gutter={[16,16]}>
                                                <Col span={24}>
                                                    <Card.Meta
                                                        avatar={<Avatar shape="square" size={55.14} src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                                        title="Card title"
                                                        description="This is the description"
                                                    />
                                                </Col>
                                                <Col span={24}>
                                                    <Typography style={{textAlign: 'justify'}}><RiDoubleQuotesL color="var(--primary)"/> Quis quorum legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid. <RiDoubleQuotesR color="var(--primary)"/></Typography>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            </Carousel>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="home-col-black">
                    <Row justify={'center'} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title color-white">Como Funciona</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text color-white">Com a nossa plataforma, advogados e clientes conseguem se conectar de forma fácil e rápida.</Typography></Col>
                        <Col xs={24} md={16} className="home-row-page-grey">
                            <Row gutter={[4,20]} justify={'center'}>
                                <Col span={24}><Typography className="home-row-page-text-grey color-white">Sou Advogado</Typography></Col>
                                <Col span={24}><Typography className="home-row-page-text color-grey">Conheça nossas soluções para gestão de seus processos e selecione o plano ideal para seu escritório.</Typography></Col>
                                <Col span={24}>
                                    <center>
                                        <div className="home-row-card">
                                            <IoClipboardOutline color="var(--primary)" size={30}/>
                                            <Typography className="home-row-page-text color-white">Faça seu cadastro e personalize o perfil do seu escritório e da sua equipe.</Typography>
                                        </div>
                                    </center>
                                </Col>
                                <Col span={24}>
                                    <center>
                                        <div className="home-row-card">
                                            <IoGlobeOutline color="var(--primary)" size={30}/>
                                            <Typography className="home-row-page-text color-white">Agora você já está online em nosso site.</Typography>
                                        </div>
                                    </center>
                                </Col>
                                <Col span={24}>
                                    <center>
                                        <Button type="primary" className="btn-primary">Quero contratar!</Button>
                                    </center>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
           

        )

    }

}

export default WebHome;