import React from "react";
import { Avatar, Button, Card, Col, Image, Layout, Row, Tag, Typography, message } from 'antd';
import { Outlet } from "react-router";

import { IoLinkOutline, IoLogoFacebook, IoMailOutline, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube } from 'react-icons/io5';

import logo from '../../assets/img/logo/jurisvip-all-branco-transparente-menor.png';

import { Link } from "react-router-dom";
import { POST_API, POST_CATCH, getToken } from "../../services/api";

class WebPerfil extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        select: 'sobre',
        profile: null,
        _equipe_total: 0,
        _cliente_total: 0,
    }

    componentDidMount = () => {
        this.onSelect(this.state._params[5] ? this.state._params[5] : 'sobre')
        this.onView()
        this.onRenderDataEquipe()
        this.onRenderDataCliente()
    }

    onView = async () => {
        await POST_API('/credential/search.php', { token: getToken(), filter: JSON.stringify({ID: this.state._params[4]}), type: 'view' }).then(rs => rs.json()).then(res => {
            this.setState({profile: res.data, _screen: false})
        }).catch(() => POST_CATCH())
    }

    onRenderDataEquipe = () => {
        setTimeout(async () => {
            POST_API('/team/search.php', { token: getToken(), filter: JSON.stringify({CREDENTIAL_ID: this.state._params[4]}), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _equipe_total: res.summary.QTDE,
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH())
        }, 500);
    }

    onRenderDataCliente = () => {
        setTimeout(async () => {
            POST_API('/client_team/search.php', { token: getToken(), filter: JSON.stringify({CREDENTIAL_ID: this.state._params[4]}), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _cliente_total: res.summary.QTDE,
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH())
        }, 500);
    }

    onSelect = (page) => {
        this.setState({select: page})
    }

    render = () => {

        return (
            
            <Layout.Content className="web-body">
                <Layout.Content id="web-content" className="web-content web-perfil">
                    <Row className="perfil-content">
                        <Col span={24}>
                            <Card actions={[
                                <Row justify={'center'} gutter={[8,8]}>
                                    <Col><Link to='sobre'><Button onClick={() => this.onSelect('sobre')} type="link" className={this.state.select === 'sobre' ? "btn-link-perfil active" : "btn-link-perfil"}>SOBRE</Button></Link></Col>
                                    { this.state._equipe_total > 0 ? <Col><Link to='nossaequipe'><Button onClick={() => this.onSelect('nossaequipe')} type="link" className={this.state.select === 'nossaequipe' ? "btn-link-perfil active" : "btn-link-perfil"}>NOSSA EQUIPE</Button></Link></Col> : null }
                                    <Col><Link to='contatos'><Button onClick={() => this.onSelect('contatos')} type="link" className={this.state.select === 'contatos' ? "btn-link-perfil active" : "btn-link-perfil"}>CONTATOS</Button></Link></Col>
                                    { this.state._equipe_total > 0 ? <Col><Link to='clientes'><Button onClick={() => this.onSelect('clientes')} type="link" className={this.state.select === 'clientes' ? "btn-link-perfil active" : "btn-link-perfil"}>CLIENTES</Button></Link></Col> : null }
                                    <Col><Link to='galeria'><Button onClick={() => this.onSelect('galeria')} type="link" className={this.state.select === 'galeria' ? "btn-link-perfil active" : "btn-link-perfil"}>GALERIA</Button></Link></Col>
                                </Row>
                            ]} cover={<Image height={300} src={this.state.profile?.BANNER} preview={false} className="banner-profile" />}>
                                <center><Avatar src={this.state.profile?.INTER_AVATAR} size={200} className="perfil-avatar" /></center>
                                <center><Typography className="perfil-title">{this.state.profile?.NAME}</Typography></center>
                                <center><Row justify={'center'}>{this.state.profile?.SPECIALITY_ITEMS.map((v, i) => <Col key={i}><Tag>{v.SPECIALTY_NAME}</Tag></Col>)}</Row></center>
                                <Row justify={'center'} gutter={[8,8]} style={{marginTop: 10}}>
                                    { this.state.profile?.EMAIL ? <Col><a rel="noreferrer" href={`mailto:${this.state.profile?.EMAIL}`} target="_blank"><Button shape="circle" type="default" className="btn-logos-perfil"><IoMailOutline size={20} /></Button></a></Col> : null }
                                    { this.state.profile?.WHATSAPP ? <Col><a rel="noreferrer" href={`https://web.whatsapp.com/send?phone=${this.state.profile?.WHATSAPP}`}target="_blank"><Button shape="circle" type="default" className="btn-logos-perfil"><IoLogoWhatsapp size={20} /></Button></a></Col> : null }
                                    { this.state.profile?.FACEBOOK ? <Col><a rel="noreferrer" href={`${this.state.profile?.FACEBOOK}`}target="_blank"><Button shape="circle" type="default" className="btn-logos-perfil"><IoLogoFacebook size={20} /></Button></a></Col> : null }
                                    { this.state.profile?.INSTAGRAM ? <Col><a rel="noreferrer" href={`${this.state.profile?.INSTAGRAM}`}target="_blank"><Button shape="circle" type="default" className="btn-logos-perfil"><IoLogoInstagram size={20} /></Button></a></Col> : null }
                                    { this.state.profile?.TWITTER ? <Col><a rel="noreferrer" href={`${this.state.profile?.TWITTER}`}target="_blank"><Button shape="circle" type="default" className="btn-logos-perfil"><IoLogoTwitter size={20} /></Button></a></Col> : null }
                                    { this.state.profile?.LINKEDIN ? <Col><a rel="noreferrer" href={`${this.state.profile?.LINKEDIN}`}target="_blank"><Button shape="circle" type="default" className="btn-logos-perfil"><IoLogoLinkedin size={20} /></Button></a></Col> : null }
                                    { this.state.profile?.YOUTUBE ? <Col><a rel="noreferrer" href={`${this.state.profile?.YOUTUBE}`}target="_blank"><Button shape="circle" type="default" className="btn-logos-perfil"><IoLogoYoutube size={20} /></Button></a></Col> : null }
                                    { this.state.profile?.SITE ? <Col><a rel="noreferrer" href={`${this.state.profile?.SITE}`}target="_blank"><Button shape="circle" type="default" className="btn-logos-perfil"><IoLinkOutline size={20} /></Button></a></Col> : null }
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Outlet />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 40}} align={'middle'}>
                        <Col span={24} className="home-col-red">
                            <Row className="home-row-page-footer">
                                <Col span={24} className="home-row-col">
                                    <center><Image src={logo} preview={false} className="perfil-footer" /></center>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout.Content>

        )

    }

}

export default WebPerfil;