import React from "react";
import { Breadcrumb, Button, Col, Layout, Modal, Row, Tag, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

class AdminMyProcessCommentList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _params: window.location.href.split('/'),
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: { current: 1, total: 0, limit: 10  },
        table_filter: null,
        table_sorter: null,
        table_select: [],
    }

    back = "/painel/meusprocessos/"+this.state._params[5]+"/comentarios";
    back2 = "/painel/meusprocessos";

    routes = [
        {title: <Link to={this.back2}>Meus Processos</Link>},
        {title: this.state._params[5] },
        {title: <Link to={this.back}>Comentários</Link>}
    ]

    componentDidMount = () => {
        titleWeb('Processos Comentários')
        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/process_comment/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify({...this.state.table_filter, PROCESS_ID: this.state._params[5]}), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
    
        this.setState({
            table_column: [
                { title: 'Data/Hora', dataIndex: 'DATETIME_INSERT_FORMAT', key: 'process_comment.DATETIME_INSERT', width: 130, sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Realizado por', dataIndex: 'CREDENTIAL_NAME', key: 'credential.NAME', width: 160, sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Comentário', dataIndex: 'BODY', key: 'process_comment.BODY', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
            ]
        })
        
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate) return (<Navigate to={'/painel'} />)

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.del`) && this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.list`) ? <Link to={this.back2}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small" style={{marginRight: 5}}>Voltar</Button></Link> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.trash`) ? <Link to={'trash'}><Button onClick={this.onChangePage} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Lixeira</Button></Link> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.add`) ? <Link to={'add'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link> : null }
                            { this.props.type === 'trash' && verifyConfig(`${this.props.per}.rec`) && this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                            { this.props.type === 'trash' && verifyConfig(`${this.props.per}.list`) ? <Link to={this.back}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link> : null }
                        </Col>
                        <Col span={24}>
                            <Table
                                _align={'left'}
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                                _rowSelection={{onChange: this.onSelect, getCheckboxProps: () => {}}}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminMyProcessCommentList;