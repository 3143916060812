import React from "react";
import { Breadcrumb, Button, Col, Layout, Modal, Row, Tag, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoArchive, IoCash, IoChatboxEllipses, IoCheckmarkDone, IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';

class AdminMyProcessList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _plan: true,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: { current: 1, total: 0, limit: 10 },
        table_filter: null,
        table_sorter: null,
        table_select: [],
    }

    back = "/painel/meusprocessos";

    routes = [
        {title: <Link to={this.back}>Meus Processos</Link>},
        {title: this.props.type === 'list' ? 'Lista' : 'Lixeira'}
    ]

    componentDidMount = () => {
        titleWeb('Meus Processos')
        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/myprocess/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _plan: res.plan,
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/process/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Processo', dataIndex: 'PROCESS_ID', key: 'process.ID', width: 260, align: 'left', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Row>
                        <Col span={24}><Typography><small><b>ID:</b></small> {record.ID}</Typography></Col>
                        <Col span={24}><Typography><small><b>NÚMERO:</b></small> {record.NUMBER}</Typography></Col>
                        <Col span={24}><Typography><small><b>TIPO AÇÃO:</b></small> {record.PROCESS_ACTION_NAME}</Typography></Col>
                        <Col span={24}><Typography><small><b>DATA CADASTRO:</b></small> {record.DATETIME_INSERT_FORMAT}</Typography></Col>
                    </Row>
                ) },
                { title: 'Parte Adversária', sorter: false, showSorterTooltip: false, width: 160, render: (text, record) => (
                    <Row>
                        { record.OPPOSING_PARTS.map((v, i) => (
                            <Col key={i}><Tag>{v.NAME}</Tag></Col>
                        )) }
                    </Row>
                ) },
                { title: 'Advogados', dataIndex: 'PROCESS_STEP_NAME', key: 'process_step.NAME', width: 160, align: 'center', render: (text, record) => (
                    <Row>
                        <Col span={24}><Tag color="var(--primary)">{record.CREDENTIAL_NAME}</Tag></Col>
                        { record.LAWYERS.map((v, i) => (
                            <Col key={i}><Tag>{v.NAME}</Tag></Col>
                        )) }
                    </Row>
                ) },
               { title: 'Situação', dataIndex: 'PROCESS_STEP_NAME', key: 'process_step.NAME', width: 160, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Ações', key: 'action', width: 80, render: (text, record) => (
                    <Row gutter={[4,4]} className="table__actions">
                        <Col><Link to={record.ID+'/comentarios'}><Button className={ record.COMMENTS > 0 ? "table__btn table__btn_recover" : "table__btn table__btn_gray" } title='Comentários' type="primary" shape="circle" size="small"><IoChatboxEllipses size={14} /></Button></Link></Col>
                        { record.LIBERAR_CLIENTE === '1' ? <Col><Link to={record.ID+'/prestacaodecontas'}><Button className={ "table__btn table__btn_cash" } title='Prestação de Contas' type="primary" shape="circle" size="small"><IoCash size={14} /></Button></Link></Col> : null }
                    </Row>
                )},
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`dsh.cln`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.del`) && this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                            { this.props.type === 'list' && verifyConfig(`plc.list`) ? <Link to={'config'}><Button type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Configurações</Button></Link> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.trash`) ? <Link to={'trash'}><Button onClick={this.onChangePage} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Lixeira</Button></Link> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.add`) ? <Link to={'add'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link> : null }
                            { this.props.type === 'trash' && verifyConfig(`${this.props.per}.rec`) && this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                            { this.props.type === 'trash' && verifyConfig(`${this.props.per}.list`) ? <Link to={this.back}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link> : null }
                        </Col>
                        <Col span={24}>
                            <Table
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminMyProcessList;