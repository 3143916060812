import React from "react";
import { Alert, Breadcrumb, Button, Col, Layout, Modal, Row, Table, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { POST_API, getToken, titleWeb, POST_CATCH } from "../../services/api";

// CONTROLLER
import PageLoad from '../Controller/PageLoad';

import { IoArrowDown, IoArrowUp, IoCheckmark, IoCheckmarkDoneSharp, IoClose, IoReorderTwo } from 'react-icons/io5';

class AdminMinhaConta extends React.Component {

    state = {
        _navigate: false,
        _buy: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _params: window.location.href.split('/'),
        defaultValue: null,
        modal: false,
        title: '',
        type: '',
        type_select: '',
        id: '',
        plan: null,
        plan_select: null,
        my: null,
        result: null,
    }

    routes = [
        { title: <Link to='/painel/minhaconta'>Minha conta</Link> },
        { title: 'Alterar Plano'}
    ]

    componentDidMount = () => {
        titleWeb('Alterar Plano')
        POST_API('/plan/page.php', { token: 'X' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({data: res.data, _screen: false})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        })
    }

    onSelectPlan = (text, id, type) => {

        POST_API('/plan/onload.php', { token: getToken(), ID: id }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({modal: true, title: text, id, type, plan: res.plan, my: res.my, result: res.result})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {POST_CATCH() })

        this.setState({
            plan_select: id,
            type_select: type,
        })

    }

    onSend = () => {

        if (this.state.plan_select == 1) {
            this.setState({_loading: true})
            POST_API('/subscription/save.php', { token: getToken(), ID: this.state.id, type: this.state.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                    this.setState({_navigate: true})
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {POST_CATCH() })
        } else {
            this.setState({ _buy: true })
        }

    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/painel/minhaconta'} />)
        }

        if (this.state._buy) {
            return (<Navigate to={`/painel/minhaconta/alterarplano/${this.state.plan_select}/${this.state.type_select}`} />)
        }

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <>
                                <Col span={24}>
                                    <Row gutter={[8,16]} align={'top'}>
                                        <Col xs={24} md={24}>
                                            <div className="home-col-out-red plan-card-3">
                                                <Row>
                                                    <Col xs={24} md={12} className="plan-div">
                                                        <Typography className="plan-title">PLANO <span>GRÁTIS</span></Typography>
                                                        <Typography className="plan-subtitle">“e sempre será!”</Typography>
                                                        <Button type="primary" className="btn-primary plan-card-free" onClick={() => this.onSelectPlan('Alterar para plano "Grátis"?', '1')}>Quero Contratar</Button>
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Acesso para {this.state.data?.[0]?.gratis} usuário.</Typography>
                                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Até {this.state.data?.[2]?.gratis} processos cadastrados.</Typography>
                                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Histórico ações por processos.</Typography>
                                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Histórico ações por clientes.</Typography>
                                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>E-mail automático para clientes.</Typography>
                                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Controle de tarefas, prazos, audiências (Workflow).</Typography>
                                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Controle financeiro.</Typography>
                                                    </Col>
                                                </Row>
                                                
                                            </div>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <div className="home-col-black plan-card-2" style={{height: 550}}>
                                                <Typography className="plan-card-title">INICIANTE</Typography>
                                                <Typography className="plan-card-text">Ideal para Recém-formados</Typography>
                                                <Typography className="plan-card-price">R$14,90<span> / MÊS</span></Typography>
                                                <Typography className="plan-card-text">Ou</Typography>
                                                <Typography className="plan-card-price">R$149,00<span> / ANO</span></Typography>
                                                <Typography className="plan-card-text">Até {this.state.data?.[0]?.iniciante} usuário.</Typography>
                                                <Typography className="plan-card-text">Máximo de {this.state.data?.[2]?.iniciante} processos cadastrados.</Typography>
                                                <Row className="plan-card-button" gutter={[8,8]}>
                                                    <Col><Button type="default" className="btn-outline" onClick={() => this.onSelectPlan('Alterar para plano "Iniciante" com renovação mensal?', '2', 'M')}>Plano Mensal</Button></Col>
                                                    <Col><Button type="default" className="btn-outline" onClick={() => this.onSelectPlan('Alterar para plano "Iniciante" com renovação anual?', '2', 'A')}>Plano Anual</Button></Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <div className="home-col-red plan-card-2" style={{height: 550}}>
                                                <Typography className="plan-card-title">BÁSICO</Typography>
                                                <Typography className="plan-card-text">Ideal para Profissionais Autônomos e Pequenos Escritórios</Typography>
                                                <Typography className="plan-card-price">R$49,90<span> / MÊS</span></Typography>
                                                <Typography className="plan-card-text">Ou</Typography>
                                                <Typography className="plan-card-price">R$399,00<span> / ANO</span></Typography>
                                                <Typography className="plan-card-text">No plano anual você ganha duas parcelas grátis.</Typography>
                                                <Typography className="plan-card-text">Até {this.state.data?.[0]?.basico} usuários.</Typography>
                                                <Typography className="plan-card-text">Até {this.state.data?.[1]?.basico} advogados participantes.</Typography>
                                                <Typography className="plan-card-text">Máximo de {this.state.data?.[2]?.basico} processos cadastrados.</Typography>
                                                <Row className="plan-card-button" gutter={[8,8]}>
                                                    <Col><Button type="default" className="btn-outline" onClick={() => this.onSelectPlan('Alterar para plano "Básico" com renovação mensal?', '3', 'M')}>Plano Mensal</Button></Col>
                                                    <Col><Button type="default" className="btn-outline" onClick={() => this.onSelectPlan('Alterar para plano "Básico" com renovação anual?', '3', 'A')}>Plano Anual</Button></Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <div className="home-col-black plan-card-2" style={{height: 550}}>
                                                <Typography className="plan-card-title">INTERMEDIÁRIO</Typography>
                                                <Typography className="plan-card-text">Ideal para Recém-formados</Typography>
                                                <Typography className="plan-card-price">R$99,90<span> / MÊS</span></Typography>
                                                <Typography className="plan-card-text">Ou</Typography>
                                                <Typography className="plan-card-price">R$599,00<span> / ANO</span></Typography>
                                                <Typography className="plan-card-text">No plano anual você ganha duas parcelas grátis.</Typography>
                                                <Typography className="plan-card-text">Até {this.state.data?.[0]?.intermediario} usuários.</Typography>
                                                <Typography className="plan-card-text">Até {this.state.data?.[1]?.intermediario} advogados participantes.</Typography>
                                                <Typography className="plan-card-text">Máximo de {this.state.data?.[2]?.intermediario} processos cadastrados.</Typography>
                                                <Row className="plan-card-button" gutter={[8,8]}>
                                                    <Col><Button type="default" className="btn-outline" onClick={() => this.onSelectPlan('Alterar para plano "Intermediário" com renovação mensal?', '4', 'M')}>Plano Mensal</Button></Col>
                                                    <Col><Button type="default" className="btn-outline" onClick={() => this.onSelectPlan('Alterar para plano "Intermediário" com renovação anual?', '4', 'A')}>Plano Anual</Button></Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <div className="home-col-red plan-card-2" style={{height: 550}}>
                                                <Typography className="plan-card-title">COMPLETO</Typography>
                                                <Typography className="plan-card-text">Feito para Escritórios Diferenciados com Grande Atuação</Typography>
                                                <Typography className="plan-card-price">R$149,90<span> / MÊS</span></Typography>
                                                <Typography className="plan-card-text">Ou</Typography>
                                                <Typography className="plan-card-price">R$999,00<span> / ANO</span></Typography>
                                                <Typography className="plan-card-text">No plano anual você ganha duas parcelas grátis.</Typography>
                                                <Typography className="plan-card-text">Usuário ilimitados.</Typography>
                                                <Typography className="plan-card-text">Advogados participantes ilimitados.</Typography>
                                                <Typography className="plan-card-text">Processos ilimitados.</Typography>
                                                <Row className="plan-card-button" gutter={[8,8]}>
                                                    <Col><Button type="default" className="btn-outline" onClick={() => this.onSelectPlan('Alterar para plano "Completo" com renovação mensal?', '5', 'M')}>Plano Mensal</Button></Col>
                                                    <Col><Button type="default" className="btn-outline" onClick={() => this.onSelectPlan('Alterar para plano "Completo" com renovação anual?', '5', 'A')}>Plano Anual</Button></Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Table
                                                size="small"
                                                pagination={false}
                                                scroll={{x: true}}
                                                columns={[
                                                    { title: 'Diferencias', dataIndex: 'title', align: 'left', sorter: null, showSorterTooltip: false },
                                                    { title: 'Grátis', dataIndex: 'gratis', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                                    { title: 'Iniciante', dataIndex: 'iniciante', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                                    { title: 'Básico', dataIndex: 'basico', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                                    { title: 'Intermediário', dataIndex: 'intermediario', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                                    { title: 'Completo', dataIndex: 'completo', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                                ]}
                                                dataSource={this.state.data}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        ) }
                    </Row>
                    <Modal title={this.state.title} open={this.state.modal} onCancel={() => this.setState({modal: false})} footer={false}>
                        <Row style={{marginTop: 20}}>
                            <Col span={5}>
                                <Typography style={{textAlign: 'center', fontWeight: 700, textTransform: 'uppercase', color: 'var(--primary)',  lineHeight: '12px', height: '25px'}}>Itens</Typography>
                                <Typography style={{fontWeight: 700}}>Usuário: </Typography>
                                <Typography style={{fontWeight: 700}}>Advogados: </Typography>
                                <Typography style={{fontWeight: 700}}>Processos: </Typography>
                            </Col>
                            <Col span={5}>
                                <Typography style={{textAlign: 'center', fontWeight: 700, textTransform: 'uppercase', color: 'var(--primary)', lineHeight: '12px', height: '25px'}}>Sua Conta</Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.result?.USUARIO}</Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.result?.ADVOGADO}</Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.result?.PROCESSO}</Typography>
                            </Col>
                            <Col span={6}>
                                <Typography style={{textAlign: 'center', fontWeight: 700, textTransform: 'uppercase', color: 'var(--primary)', lineHeight: '12px', height: '25px'}}>Plano Atual<br/><span style={{color: '#000', fontSize: 10}}>{this.state.my?.NAME}</span></Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.my?.METAVALUE[0].VALUE !== '-1' ? this.state.my?.METAVALUE[0].VALUE : 'Ilimitado'}</Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.my?.METAVALUE[1].VALUE !== '-1' ? this.state.my?.METAVALUE[1].VALUE : 'Ilimitado'}</Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.my?.METAVALUE[2].VALUE !== '-1' ? this.state.my?.METAVALUE[2].VALUE : 'Ilimitado'}</Typography>
                            </Col>
                            <Col span={2}>
                                <Typography style={{textAlign: 'center', fontWeight: 700, textTransform: 'uppercase', color: '#FFF', height: '25px'}}>.</Typography>
                                <Typography style={{textAlign: 'center'}}> { this.state.result?.[0] === 'up' ? <IoCheckmark color="#008000" /> : ( this.state.result?.[0] === 'down' ? <IoClose color="#ff0000" /> : <IoReorderTwo /> ) } </Typography>
                                <Typography style={{textAlign: 'center'}}> { this.state.result?.[1] === 'up' ? <IoCheckmark color="#008000" /> : ( this.state.result?.[1] === 'down' ? <IoClose color="#ff0000" /> : <IoReorderTwo /> ) } </Typography>
                                <Typography style={{textAlign: 'center'}}> { this.state.result?.[2] === 'up' ? <IoCheckmark color="#008000" /> : ( this.state.result?.[2] === 'down' ? <IoClose color="#ff0000" /> : <IoReorderTwo /> ) } </Typography>
                            </Col>
                            <Col span={6}>
                                <Typography style={{textAlign: 'center', fontWeight: 700, textTransform: 'uppercase', color: 'var(--primary)', lineHeight: '12px', height: '25px'}}>Plano Novo<br/><span style={{color: '#000', fontSize: 10}}>{this.state.plan?.NAME}</span></Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.plan?.METAVALUE[0].VALUE !== '-1' ? this.state.plan?.METAVALUE[0].VALUE : 'Ilimitado'}</Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.plan?.METAVALUE[1].VALUE !== '-1' ? this.state.plan?.METAVALUE[1].VALUE : 'Ilimitado'}</Typography>
                                <Typography style={{textAlign: 'center'}}>{this.state.plan?.METAVALUE[2].VALUE !== '-1' ? this.state.plan?.METAVALUE[2].VALUE : 'Ilimitado'}</Typography>
                            </Col>
                            <Col span={24} style={{marginTop: 20}}>
                                { this.state.result?.APTO ? (
                                    <>
                                        <Alert message="Alterar plano disponível" type="success" showIcon description="Sua conta se encaixa nos requisitos para a mudança de plano." />
                                        <Button onClick={this.onSend} style={{marginTop: 20}} loading={this.state._loading} type="primary" shape="round" className="float-right">Alterar Plano</Button>
                                    </>
                                ) : (
                                    <Alert message="Alterar plano indisponível" type="error" showIcon description="Sua conta já excedeu os limites para a mudança de plano." />
                                ) }
                            </Col>
                        </Row>
                    </Modal>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminMinhaConta;