import React from "react";
import { Col, Row, Breadcrumb, Typography, message, Spin } from "antd";
import { Link } from "react-router-dom";
import { POST_API, POST_CATCH, getToken } from "../../services/api";

class WebPolicy extends React.Component {

    state = {
        _screen: true,
        rows: [],
        defaultValue: null
    }

    componentDidMount = async () => {
        POST_API('/page_body/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({PAGE_ID: 2}), sorter: JSON.stringify({ columnKey: 'page_body.`ROW`, page_body.`COLUMN`, page_body.`ITEM`', order: 'ascend' }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({rows: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
        POST_API('/page/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: 2}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    breadcrumb = [
        { title: <Link to='/'><Typography className="home-breadcrumb-link">Home</Typography></Link> },
        { title: <Typography className="home-breadcrumb-link active">Política de Privacidade</Typography> }
    ]

    render = () => {

        return (
            
            <Row>
                <Col span={24} className="home-col-black">
                    <Row align={'middle'} className="home-row-breadcrumb">
                        <Col span={12}>
                            <Breadcrumb className="home-breadcrumb" items={this.breadcrumb} />
                        </Col>
                        <Col span={12}>
                            <Typography className="home-breadcrumb-title">Política de Privacidade</Typography>
                        </Col>
                    </Row>
                </Col>
                { this.state._screen ? <Col span={24}><center><Spin /></center></Col> : (
                    <Col span={24}>
                        <Row className="home-row-page">
                            <Col span={24}><Typography className="home-row-page-text-title">{this.state.defaultValue.TITLE}</Typography></Col>
                            { this.state.rows.map((v, i) => (
                                <>
                                    <Row gutter={[16,0]} key={i}>
                                        { v.columns.map((cv, ci) => (
                                            <Col key={ci} span={24/v.type}>
                                                <Row>
                                                    { cv.items.map((iv, ii) => (
                                                        <Col span={24} key={ii} style={{marginBottom: 10}}>
                                                            { iv.type === 'T' ? <Typography className="policy-row-title" style={{marginTop: 30, marginBottom: 20}}>{iv.value}</Typography> : null }
                                                            { iv.type === 'P' ? <Typography className="policy-row-subtitle">{iv.value}</Typography> : null }
                                                            { iv.type === 'L' ? <Typography className="policy-row-subtitle-line">{iv.value}</Typography> : null }
                                                        </Col>
                                                    )) }
                                                </Row>
                                            </Col>
                                            
                                        )) }
                                    </Row>
                                </>
                            )) }
                            <Col span={24}><Typography className="home-row-page-version">Versão {this.state.defaultValue.VERSIONS[0].VER} - Atualizada em {this.state.defaultValue.VERSIONS[0].DATE_VER}</Typography></Col>
                        </Row>
                    </Col>
                ) }
            </Row>
           
        )

    }

}

export default WebPolicy;