import React from "react";
import { Col, Image, Pagination, Row, Spin, Typography, message } from 'antd';

import { POST_API, POST_CATCH, getToken } from "../../services/api";

class WebPerfilGaleria extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        profile: null,
        _fotos_list: [],
        _fotos_loading: false,
        _fotos_pagination: {
            current: 1,
            total: 0,
            limit: 8
        },
    }

    componentDidMount = () => {
        this.onView()
        this.onRenderDataFotos()
    }

    onView = async () => {
        await POST_API('/credential/search.php', { token: getToken(), filter: JSON.stringify({ID: this.state._params[4]}), type: 'view' }).then(rs => rs.json()).then(res => {
            this.setState({profile: res.data, _screen: false})
        }).catch(() => POST_CATCH())
    }

    onRenderDataFotos = () => {
        this.setState({_fotos_loading: true })
        setTimeout(async () => {
            POST_API('/timeline_photo/search.php', { token: getToken(), filter: JSON.stringify({CREDENTIAL_ID: this.state._params[4]}), pagination: JSON.stringify(this.state._fotos_pagination), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _fotos_list: res.data,
                        _fotos_pagination: {
                            ...this.state._fotos_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({_fotos_loading: false }))
        }, 500);
    }

    onPaginationChangeFotos = (page, size) => {
        this.setState({
            _fotos_pagination: {
                ...this.state._fotos_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderDataFotos()
    }

    render = () => {

        return (
            
            <Row className="perfil-content-row" gutter={[16, 16]}>
                <Col md={24} xs={24}>
                    <Row gutter={[16, 16]}>
                        { this.state._fotos_loading ? <Col span={24}><center><Spin /></center></Col> : (
                            <>
                                { this.state._fotos_list.length > 0 ? (
                                    <>
                                        { this.state._fotos_list.map((v, i) => (
                                            <Col md={6} xs={12}>
                                                <Image src={v.URL} className="galeria-image" />
                                            </Col>
                                        )) }
                                    </>
                                ) : <Col span={24}><Typography className="no-data">Sem Fotos</Typography></Col> }
                            </>
                        ) }
                        <Col span={24}>
                            <center><Pagination size="small" current={this.state._fotos_pagination.current} defaultCurrent={this.state._fotos_pagination.current} total={this.state._fotos_pagination.total} pageSize={this.state._fotos_pagination.limit} onChange={this.onPaginationChangeFotos}/></center>
                        </Col>
                    </Row>
                </Col>
            </Row>

        )

    }

}

export default WebPerfilGaleria;