import React from "react"

import './styles.css'

import img_logo from '../../assets/img/logo/jurisvip-all-transparente-menor.png'

class FooterComponent extends React.Component {
    render() {
        const { nomeEscritorio } = this.props

        return (
            <footer className="footer-component">
                <img src={img_logo} />
                <p>
                    Documento gerado pela plataforma JurisVip. Todas as informações <br />
                    foram fornecidas pelo escritório {nomeEscritorio}.
                </p>
            </footer>
        )
    }
}

export default FooterComponent