import React from "react";
import { Col, Row, Breadcrumb, Typography, Image, Button, Card, Table, message } from "antd";
import { Link } from "react-router-dom";
import { IoCellularOutline, IoCheckmarkCircleOutline, IoCheckmarkDoneSharp, IoCloudUploadOutline, IoInformationCircleOutline, IoLogoWhatsapp, IoNotificationsOutline } from "react-icons/io5";

import plan from '../../assets/img/plans/planos.jpg';
import plan2 from '../../assets/img/plans/planos02.jpg';
import plan3 from '../../assets/img/plans/planos03.jpg';
import plan4 from '../../assets/img/plans/template.png';
import { POST_API } from "../../services/api";

class WebPlans extends React.Component {

    state = { data: [] }

    breadcrumb = [
        { title: <Link to='/'><Typography className="home-breadcrumb-link">Home</Typography></Link> },
        { title: <Typography className="home-breadcrumb-link active">Planos</Typography> }
    ]

    componentDidMount = () => {

        POST_API('/plan/page.php', { token: 'X' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                console.log(res.data)
                this.setState({data: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        })

    }

    render = () => {

        return (
            
            <Row>
                <Col span={24} className="home-col-black">
                    <Row align={'middle'} className="home-row-breadcrumb">
                        <Col span={12}>
                            <Breadcrumb className="home-breadcrumb" items={this.breadcrumb} />
                        </Col>
                        <Col span={12}>
                            <Typography className="home-breadcrumb-title">Planos</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[60,16]} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title">Conheça nossos planos</Typography></Col>
                    </Row>
                </Col>
                <Col span={24} className="home-col-light">
                    <Row gutter={[60,16]} align={'middle'} className="home-row-page">
                        <Col md={12} xs={24} className="home-row-page-about">
                            <Typography className="plan-row-title" style={{marginBottom: 10}}>PLATAFORMA COMPLETA PARA GESTÃO DE ESCRITÓRIOS DE ADVOCACIA</Typography>
                            <Row gutter={[8,8]} style={{marginBottom: 10}}>
                                <Col xs={12} md={6}>
                                    <Typography className="plan-text">Clientes</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="plan-text">Processos</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="plan-text">Financeiros</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="plan-text">Atendimento</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="plan-text">Relatórios</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="plan-text">Documentos</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="plan-text">Prazos</Typography>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Typography className="plan-text">Tarefas</Typography>
                                </Col>
                            </Row>
                            <Typography className="home-row-subtitle" style={{marginTop:20}}>Tudo em um único lugar.</Typography>
                            <Typography className="home-row-subtitle"><b>Nosso trabalho</b> é simplificar o seu!</Typography>
                            <Button type="primary" size="large" className="btn-primary">Ver Planos</Button>
                        </Col>
                        <Col md={12} xs={24}>
                            <Image src={plan} preview={false} style={{width: '100%', borderRadius: 8}} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[16,16]} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title">Automatize seu atendimento</Typography></Col>
                        <Col span={24}><Typography className="home-row-page-text">Mantenha seus clientes informados e atualizados de forma automática!</Typography></Col>
                        <Col xs={24} md={6}>
                            <Card size="small" hoverable>
                                <div className="plan-card">
                                    <IoCellularOutline color="var(--primary)" size={30}/>
                                    <Typography className="about-row-page-text" style={{marginTop: 10, textAlign: 'center'}}>Atualize seus clientes de cada andamento do processo direto pela plataforma.</Typography>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card size="small" hoverable>
                                <div className="plan-card">
                                    <IoCloudUploadOutline color="var(--primary)" size={30}/>
                                    <Typography className="about-row-page-text" style={{marginTop: 10, textAlign: 'center'}}>Solicite e envie documentos.</Typography>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card size="small" hoverable>
                                <div className="plan-card">
                                    <IoLogoWhatsapp color="var(--primary)" size={30}/>
                                    <Typography className="about-row-page-text" style={{marginTop: 10, textAlign: 'center'}}>Converse com seus clientes em um clique com o WhatsApp.</Typography>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card size="small" hoverable>
                                <div className="plan-card">
                                    <IoNotificationsOutline color="var(--primary)" size={30}/>
                                    <Typography className="about-row-page-text" style={{marginTop: 10, textAlign: 'center'}}>Notificações por aplicativo no celular, agora é possível.</Typography>
                                </div>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <center>
                                <Button className="btn-primary" type="primary" size="large">Ver Planos</Button>
                            </center>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="home-col-light">
                    <Row gutter={[40,16]} align={'middle'} className="home-row-page">
                        <Col md={12} xs={24}>
                            <Image src={plan2} preview={false} style={{width: '100%', borderRadius: 8}} />
                        </Col>
                        <Col md={12} xs={24} className="home-row-page-about">
                            <Typography className="home-row-text">NÃO FAÇA MAIS TRABALHO REPETITIVO!</Typography>
                            <Typography className="plan-row-title" style={{marginBottom: 10, textTransform: 'uppercase'}}>Tudo que um advogado precisa em poucos cliques.</Typography>
                            <Typography className="home-row-subtitle" style={{marginTop:20}}>Vamos mostrar 5 motivos apenas.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Acesso rápido.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Por computador, tablet e celular.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Compartilhado com sua equipe.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Acesso para outros advogados parceiros.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Alertas de prazos.</Typography>
                            <Typography className="home-row-subtitle" style={{marginTop:20}}><b>Lembre-se: tempo é dinheiro!</b></Typography>
                            <Button type="primary" size="large" className="btn-primary">Ver Planos</Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[40,16]} align={'middle'} className="home-row-page invert">
                        <Col md={12} xs={24} className="home-row-page-about">
                            <Typography className="home-row-text">SEJA DIGITAL: INTEGRAÇÃO E AUTOMATIZAÇÃO</Typography>
                            <Typography className="plan-row-title" style={{marginBottom: 10, textTransform: 'uppercase'}}>Use a tecnologia à seu favor e faça o dobro de serviço de forma simples e rápida.</Typography>
                            <Typography className="home-row-subtitle" style={{marginTop:20}}>Vamos mostrar 5 motivos apenas.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoInformationCircleOutline color="var(--primary)" size={20} /></span>App JurisVip para seus clientes.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoInformationCircleOutline color="var(--primary)" size={20} /></span>Integração com as publicações.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoInformationCircleOutline color="var(--primary)" size={20} /></span>Integração com Trello e Google Agenda.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoInformationCircleOutline color="var(--primary)" size={20} /></span>Integração com WhatsApp.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoInformationCircleOutline color="var(--primary)" size={20} /></span>Integração com Google Drive para documentos.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoInformationCircleOutline color="var(--primary)" size={20} /></span>Modelo de prestação de contas.</Typography>
                            <Button type="primary" size="large" className="btn-primary">Quero ser digital</Button>
                        </Col>
                        <Col md={12} xs={24}>
                            <Image src={plan3} preview={false} style={{width: '100%', borderRadius: 8}} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="home-col-light">
                    <Row gutter={[40,16]} align={'middle'} className="home-row-page">
                        <Col md={12} xs={24}>
                            <Image src={plan4} preview={false} style={{width: '100%', borderRadius: 8}} />
                        </Col>
                        <Col md={12} xs={24} className="home-row-page-about">
                            <Typography className="home-row-text">PRECISA DE UM SITE?</Typography>
                            <Typography className="plan-row-title" style={{marginBottom: 10, textTransform: 'uppercase'}}>Crie sua conta e ganhe um site personalizado!</Typography>
                            <Typography className="home-row-subtitle" style={{marginTop:20}}>Assim que se cadastrar, terá um link do seu site www.jurisvip.com.br/seu-nome/</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Seu site, suas informações.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Fotos, redes sociais, contatos.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Atualizações e novidades.</Typography>
                            <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkCircleOutline color="var(--primary)" size={18} /></span>Sua equipe, suas especialidades.</Typography>
                            <Button type="primary" size="large" className="btn-primary">Quero meu site pronto</Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[8,38]} align={'top'} className="plan-row-page">
                        <Col xs={24} md={24}>
                            <div className="home-col-out-red plan-card-3">
                                <Row>
                                    <Col xs={24} md={12} className="plan-div">
                                        <Typography className="plan-title">PLANO <span>GRÁTIS</span></Typography>
                                        <Typography className="plan-subtitle">“e sempre será!”</Typography>
                                        <Button type="primary" className="btn-primary plan-card-free">Quero Contratar</Button>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Acesso para {this.state.data?.[0]?.gratis} usuário.</Typography>
                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Até {this.state.data?.[2]?.gratis} processos cadastrados.</Typography>
                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Histórico ações por processos.</Typography>
                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Histórico ações por clientes.</Typography>
                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>E-mail automático para clientes.</Typography>
                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Controle de tarefas, prazos, audiências (Workflow).</Typography>
                                        <Typography className="about-row-subtitle" style={{marginBottom: '0px'}}><span style={{paddingTop: 2, marginRight: 2}}><IoCheckmarkDoneSharp color="var(--primary)" size={18} /></span>Controle financeiro.</Typography>
                                    </Col>
                                </Row>
                                
                            </div>
                        </Col>
                        <Col xs={24} md={6}>
                            <div className="home-col-black plan-card-2">
                                <Typography className="plan-card-title">INICIANTE</Typography>
                                <Typography className="plan-card-text">Ideal para Recém-formados</Typography>
                                <Typography className="plan-card-price">R$14,90<span> / MÊS</span></Typography>
                                <Typography className="plan-card-text">Ou</Typography>
                                <Typography className="plan-card-price">R$149,00<span> / ANO</span></Typography>
                                <Typography className="plan-card-text">Até {this.state.data?.[0]?.iniciante} usuário.</Typography>
                                <Typography className="plan-card-text">Máximo de {this.state.data?.[2]?.iniciante} processos cadastrados.</Typography>
                                <div className="plan-card-button"><Button type="default" className="btn-outline">Quero Contratar</Button></div>
                            </div>
                        </Col>
                        <Col xs={24} md={6}>
                            <div className="home-col-red plan-card-2">
                                <Typography className="plan-card-title">BÁSICO</Typography>
                                <Typography className="plan-card-text">Ideal para Profissionais Autônomos e Pequenos Escritórios</Typography>
                                <Typography className="plan-card-price">R$49,90<span> / MÊS</span></Typography>
                                <Typography className="plan-card-text">Ou</Typography>
                                <Typography className="plan-card-price">R$399,00<span> / ANO</span></Typography>
                                <Typography className="plan-card-text">No plano anual você ganha duas parcelas grátis.</Typography>
                                <Typography className="plan-card-text">Até {this.state.data?.[0]?.basico} usuários.</Typography>
                                <Typography className="plan-card-text">Até {this.state.data?.[1]?.basico} advogados participantes.</Typography>
                                <Typography className="plan-card-text">Máximo de {this.state.data?.[2]?.basico} processos cadastrados.</Typography>
                                <div className="plan-card-button"><Button type="default" className="btn-outline">Quero Contratar</Button></div>
                            </div>
                        </Col>
                        <Col xs={24} md={6}>
                            <div className="home-col-black plan-card-2">
                                <Typography className="plan-card-title">INTERMEDIÁRIO</Typography>
                                <Typography className="plan-card-text">Ideal para Recém-formados</Typography>
                                <Typography className="plan-card-price">R$99,90<span> / MÊS</span></Typography>
                                <Typography className="plan-card-text">Ou</Typography>
                                <Typography className="plan-card-price">R$599,00<span> / ANO</span></Typography>
                                <Typography className="plan-card-text">No plano anual você ganha duas parcelas grátis.</Typography>
                                <Typography className="plan-card-text">Até {this.state.data?.[0]?.intermediario} usuários.</Typography>
                                <Typography className="plan-card-text">Até {this.state.data?.[1]?.intermediario} advogados participantes.</Typography>
                                <Typography className="plan-card-text">Máximo de {this.state.data?.[2]?.intermediario} processos cadastrados.</Typography>
                                <div className="plan-card-button"><Button type="default" className="btn-outline">Quero Contratar</Button></div>
                            </div>
                        </Col>
                        <Col xs={24} md={6}>
                            <div className="home-col-red plan-card-2">
                                <Typography className="plan-card-title">COMPLETO</Typography>
                                <Typography className="plan-card-text">Feito para Escritórios Diferenciados com Grande Atuação</Typography>
                                <Typography className="plan-card-price">R$149,90<span> / MÊS</span></Typography>
                                <Typography className="plan-card-text">Ou</Typography>
                                <Typography className="plan-card-price">R$999,00<span> / ANO</span></Typography>
                                <Typography className="plan-card-text">No plano anual você ganha duas parcelas grátis.</Typography>
                                <Typography className="plan-card-text">Usuário ilimitados.</Typography>
                                <Typography className="plan-card-text">Advogados participantes ilimitados.</Typography>
                                <Typography className="plan-card-text">Processos ilimitados.</Typography>
                                <div className="plan-card-button"><Button type="default" className="btn-outline">Quero Contratar</Button></div>
                            </div>
                        </Col>
                        <Col xs={24} md={24}>
                            <Table
                                size="small"
                                pagination={false}
                                scroll={{x: true}}
                                columns={[
                                    { title: 'Diferencias', dataIndex: 'title', align: 'left', sorter: null, showSorterTooltip: false },
                                    { title: 'Grátis', dataIndex: 'gratis', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                    { title: 'Iniciante', dataIndex: 'iniciante', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                    { title: 'Básico', dataIndex: 'basico', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                    { title: 'Intermediário', dataIndex: 'intermediario', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                    { title: 'Completo', dataIndex: 'completo', width: 120, align: 'center', sorter: null, showSorterTooltip: false, render: (text, record) => ( <> { text === 'S' ? <IoCheckmarkDoneSharp color="var(--primary)" /> : ( text === 'N' ? '-' : text)} </> ) },
                                ]}
                                dataSource={this.state.data}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
           
        )

    }

}

export default WebPlans;