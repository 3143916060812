import React from "react";
import { Button, Col, Layout, Row } from 'antd';
import { Link } from "react-router-dom";

import { IoBookOutline, IoCubeOutline, IoDesktopOutline, IoPeopleOutline, IoBookmarksOutline, IoApertureOutline, IoPersonOutline, IoPricetagOutline, IoHelpOutline, IoStarOutline, IoBagOutline, IoCheckmarkDoneOutline, IoHappyOutline, IoBusinessOutline, IoSchoolOutline, IoCashOutline, IoCardOutline, IoDocumentTextOutline, IoAlbumsOutline, IoChatboxEllipsesOutline } from 'react-icons/io5';
import { PiHandshake } from "react-icons/pi";
import { verifyConfig } from "../../services/api";

class Screen extends React.Component {

    state = { select: 'dashboard', paths: [] }

    componentDidMount = () => {
        
        const params = window.location.href;
        const values = params.split('/');

        values[4] = values[4] ? values[4] : values[3]
        
        this.onSelect(values[4])

    }

    onSelect = (page) => {
        if (this.props?.clickOption) this.props?.clickOption()
        this.setState({select: page})
    }


    render = () => {

        const { Content } = Layout
        const menus = [
            { label: 'Meu Site', url: 'meusite', value: 'meusite', icon: <IoPersonOutline size={22}/>, permission: 'mst.list'},
            { label: 'Equipe', url: 'equipe', value: 'equipe', icon: <IoPeopleOutline size={22}/>, permission: 'eqp.list'},
            { label: 'Clientes', url: 'clientes', value: 'clientes', icon: <IoHappyOutline size={22}/>, permission: 'cli.list'},
            { label: 'Parte Contrária', url: 'partecontraria', value: 'partecontraria', icon: <PiHandshake size={22}/>, permission: 'ptc.list'},
            { label: 'Advogados Participantes', url: 'advogados', value: 'advogados', icon: <IoBagOutline size={22}/>, permission: 'adp.list' },
            { label: 'Processos', url: 'processos', value: 'processos', icon: <IoBookOutline size={22}/>, permission: 'prc.list'},
            { label: 'Meus Processos', url: 'meusprocessos', value: 'meusprocessos', icon: <IoBookOutline size={22}/>, permission: 'dsh.cln'},
            { label: 'Financeiro', url: 'financeiro', value: 'financeiro', icon: <IoCashOutline  size={22}/>, permission: 'fnc.list'},
            { label: 'Tags', url: 'tags', value: 'tags', icon: <IoBookmarksOutline size={22}/>, permission: 'tag.list'},
            { label: 'Tarefas', url: 'tarefas', value: 'tarefas', icon: <IoCheckmarkDoneOutline size={22}/>, permission: 'trf.list'},
            { label: 'Usuários', url: 'usuarios', value: 'usuarios', icon: <IoPeopleOutline size={22}/>, permission: 'usr.list' },
            { label: 'Planos', url: 'planos', value: 'planos', icon: <IoBookmarksOutline size={22}/>, permission: 'pln.list' },
            { label: 'Escritórios', url: 'escritorios', value: 'escritorios', icon: <IoDesktopOutline size={22}/>, permission: 'ect.list' },
            { label: 'Treinamentos', url: 'treinamentos', value: 'treinamentos', icon: <IoSchoolOutline size={22}/>, permission: 'trn.list' },
            { label: 'Políticas e Termos', url: 'politicastermos', value: 'politicastermos', icon: <IoDocumentTextOutline size={22}/>, permission: 'pet.list' },
            { label: 'Banners', url: 'banners', value: 'banners', icon: <IoAlbumsOutline size={22}/>, permission: 'alb.edit' },
            // { label: 'Cupons', url: 'cupons', value: 'cupons', icon: <IoPricetagOutline size={22}/>, permission: 'cpn.list' },
            { label: 'Especialidades', url: 'especialidades', value: 'especialidades', icon: <IoApertureOutline size={22}/>, permission: 'esp.list' },
            { label: 'Perguntas', url: 'perguntas', value: 'perguntas', icon: <IoHelpOutline size={22}/>, permission: 'prg.list' },
            { label: 'Comunicação', url: 'comunicacao', value: 'comunicacao', icon: <IoChatboxEllipsesOutline size={22}/>, permission: 'mdp.list' },
            { label: 'Métodos Pagamento', url: 'metodospagamento', value: 'metodospagamento', icon: <IoCardOutline size={22}/>, permission: 'mdp.list' },
            { label: 'Situação Processo', url: 'situacoes', value: 'situacoes', icon: <IoStarOutline size={22}/>, permission: 'pst.list' },
            { label: 'Status Processo', url: 'status', value: 'status', icon: <IoStarOutline size={22}/>, permission: 'pss.list' },
            { label: 'Chance Ganhar', url: 'chanceganhar', value: 'chanceganhar', icon: <IoStarOutline size={22}/>, permission: 'pcg.list' },
            { label: 'Instâncias', url: 'instancias', value: 'instancias', icon: <IoStarOutline size={22}/>, permission: 'pis.list' },
            { label: 'Tribunais', url: 'tribunais', value: 'tribunais', icon: <IoStarOutline size={22}/>, permission: 'ptb.list' },
            { label: 'Tipo Ação', url: 'tipoacao', value: 'tipoacao', icon: <IoStarOutline size={22}/>, permission: 'pta.list' },
            { label: 'Grupos Permissão', url: 'grupopermissao', value: 'grupopermissao', icon: <IoBookOutline size={22}/>, permission: 'gdp.list' },
            { label: 'Permissões', url: 'permissao', value: 'permissao', icon: <IoBookOutline size={22}/>, permission: 'prm.list' },
            { label: 'Tipos de Usuário', url: 'tipousuario', value: 'tipousuario', icon: <IoBookOutline size={22}/>, permission: 'tdu.list' },
            // { label: 'Logs', url: 'logs', value: 'logs', icon: <IoBookOutline size={22}/>, permission: 'log.list' },
        ]

        return (
            <Content style={{marginTop: 15}}>
                <Row gutter={[8,8]} style={{paddingLeft: 8, paddingRight: 8}}>
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'/painel'}>
                            <Button onClick={() => this.onSelect('painel')} type="link" block className={this.state.select === 'painel' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoCubeOutline size={22}/> Dashboard
                            </Button>
                        </Link>
                    </Col>
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'minhaconta'}>
                            <Button onClick={() => this.onSelect('minhaconta')} type="link" block className={this.state.select === 'minhaconta' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoBusinessOutline size={22}/> Minha Conta
                            </Button>
                        </Link>
                    </Col>   
                    { menus.map((v, i) => {
                        if (verifyConfig(v.permission)) {
                            return (
                                <Col key={i} span={24} className={`admin-sidebar-item`}>
                                    <Link to={v.url}>
                                        <Button onClick={() => this.onSelect(v.value)} type="link" block className={this.state.select === v.value ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                            {v.icon} {v.label} 
                                        </Button>
                                    </Link>
                                </Col>  
                            )
                        }
                        return true
                    })}    
                </Row>
            </Content>
        )
    }

}

export default Screen;