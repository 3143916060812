import React from "react";
import { Avatar, Breadcrumb, Button, Col, Form, Layout, Modal, Row, Switch, Tag, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoLockOpen, IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';
import ReactInputMask from "react-input-mask";

class AdminAdvogadoList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _plan: false,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: { current: 1, total: 0, limit: 10 },
        table_filter: null,
        table_sorter: null,
        table_select: [],
        cpfcnpj: '',
        newLoading: false,
        _navigate_add: false,
        dadoAdvogado: null
    }

    back = "/painel/advogados";

    routes = [
        {title: <Link to={this.back}>Advogados</Link>},
        {title: this.props.type === 'list' ? 'Lista' : 'Lixeira'}
    ]

    componentDidMount = () => {
        titleWeb('Advogados')
        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/lawyer/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        _plan: res.option,
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onRecover = (ID) => {
        var self = this
        Modal.confirm({
            title: 'Restaurar senha padrão?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/lawyer/recpass.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => POST_CATCH()).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/lawyer/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Cod.', dataIndex: 'ID', key: 'lawyer.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'CPF/CNPJ', dataIndex: 'LOGIN', key: 'credential.LOGIN', width: 200, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Nome', dataIndex: 'NAME', key: 'credential.NAME', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <Row>
                        <Col span={24}><Typography>{record.NAME}</Typography></Col>
                        <Col span={24}>{ record.TAGS.map((v,i) => <Tag key={i} color={v.TAG_COLOR}>{v.TAG_NAME}</Tag>) }</Col>
                    </Row>
                )  },
                { title: 'Ações', key: 'action', width: 100, render: (text, record) => (
                    <Row gutter={[4,4]} className="table__actions">
                        { this.props.type === 'list' && verifyConfig(`${this.props.per}.edit`) ? <Col><Link to={record.ID}><Button className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col> : null }
                        { this.props.type === 'list' && verifyConfig(`${this.props.per}.del`) ? <Col><Button onClick={() => this.onAction(record.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col> : null}
                        { this.props.type === 'list' ? <Col><Button onClick={() => this.onRecover(record.CREDENTIAL_ID)} className="table__btn table__btn_view" title='Recuperar Senha' type="primary" shape="circle" size="small"><IoLockOpen size={14} /></Button></Col> : null}
                        { this.props.type === 'trash' && verifyConfig(`${this.props.per}.rec`) ? <Col><Button onClick={() => this.onAction(record.ID, 'rec', 'Recuperar registro?')} className="table__btn table__btn_recover" title='Recuperar' type="primary" shape="circle" size='small'><IoReturnUpBackOutline size={14} /></Button></Col> : null }
                    </Row>
                )},
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    newAdvogado = () => {
        this.setState({ modalNew: true, cpfcnpj: '', dadoAdvogado: null })
    }

    verifyAdvogado = () => {
        if (this.state.cpfcnpj.indexOf('_') !== -1 || this.state.cpfcnpj === '') {
            message.warning('Documento inválido!')
            return
        }
        this.setState({ newLoading: true })
        POST_API('/lawyer/search.php', { token: getToken(), filter: JSON.stringify({ LOGIN: this.state.cpfcnpj }), type: 'filter' }).then(rs => rs.json()).then(res => {
            if ( res.return ) {
                if (res.summary.QTDE > 0) {
                    this.setState({ dadoAdvogado: res.data[0] })
                } else {
                    Modal.confirm({
                        type: "info",
                        title: 'Ops!',
                        content: 'Não encontramos nenhum registro com esse documento. Gostaria de cadastrar um novo?',
                        cancelText: 'Não',
                        okText: 'Sim',
                        onCancel: () => {},
                        onOk: () => {
                            window.localStorage.setItem('CPFCNPJ', this.state.cpfcnpj)
                            this.setState({ _navigate_add: true })
                        },
                    })
                }
            } else {
                message.warning(res.msg)
            }
        }).catch(() => {POST_CATCH()}).finally(() => this.setState({newLoading: false}))
    }

    addAdvogado = () => {
        this.setState({ newLoading: true })
        POST_API('/lawyer/add.php', { token: getToken(), master: JSON.stringify(this.state.dadoAdvogado), type: 'filter' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                this.setState({modalNew: false})
                this.onRenderData()
            } else {
                message.warning(res.msg)
            }
        }).catch(() => {POST_CATCH()}).finally(() => this.setState({newLoading: false}))
    }

    render = () => {
        
        if (this.state._navigate) return (<Navigate to={'/painel'} />)
        if (this.state._navigate_add) return (<Navigate to={'add'} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.del`) && this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.trash`) ? <Link to={'trash'}><Button onClick={this.onChangePage} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Lixeira</Button></Link> : null }
                            { this.props.type === 'list' && verifyConfig(`${this.props.per}.add`) && this.state._plan ? <Button onClick={this.newAdvogado} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button> : null }
                            { this.props.type === 'trash' && verifyConfig(`${this.props.per}.rec`) && this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                            { this.props.type === 'trash' && verifyConfig(`${this.props.per}.list`) ? <Link to={this.back}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link> : null }
                        </Col>
                        <Col span={24}>
                            <Table
                                _columns={this.state.table_column}
                                _data={this.state.table_data}
                                _pagination={this.state.table_pagination}
                                _loading={this.state.table_load}
                                _onChangeTable={this.onTableChange}
                                _onChangePagination={this.onPaginationChange}
                                _onChangeSearch={this.onSearchChange}
                                _search={this.state.table_search}
                                _rowSelection={{onChange: this.onSelect, getCheckboxProps: () => {}}}
                            />
                        </Col>
                    </Row>
                    <Modal title={ this.state.dadoAdvogado === null  ? "Adicionar novo advogado" : "Registro encontrado" } open={this.state.modalNew} onCancel={() => this.setState({modalNew: false})} cancelText="Fechar" okText={ this.state.dadoAdvogado === null ? "Procurar documento" : "Adicionar" } onOk={ this.state.dadoAdvogado === null ? this.verifyAdvogado : this.addAdvogado } confirmLoading={this.state.newLoading}>
                        <Row>
                            { this.state.dadoAdvogado === null ? (
                                <Col xs={24} md={24}>
                                    <Form layout="vertical">
                                        <Form.Item label={this.state.doc ? 'CNPJ' : 'CPF'} name="CPFCNPJ" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                            <ReactInputMask onChange={(v) => this.setState({cpfcnpj: v.target.value})} className="ant-input css-dev-only-do-not-override-w8mnev css-w8mnev" placeholder={this.state.doc ? 'CNPJ' : 'CPF'} mask={this.state.doc ? '99.999.999/9999-99' : '999.999.999-99'} />
                                        </Form.Item>
                                        <Switch checked={this.state.doc} unCheckedChildren="CPF" checkedChildren="CNPJ" onChange={(v) => this.setState({doc: v})} style={{position: 'absolute', right: 13, top: 35}} />
                                    </Form>
                                </Col>
                            ) : (
                                <Col xs={24} md={24}>
                                    <center><Avatar src={this.state.dadoAdvogado?.AVATAR} size={180} /></center>
                                    <Typography><b>Nome:</b> {this.state.dadoAdvogado?.NAME}</Typography>
                                    <Typography><b>Documento:</b> {this.state.dadoAdvogado?.LOGIN}</Typography>
                                    <Typography><b>Data Nascimento:</b> {this.state.dadoAdvogado?.BIRTHDAY}</Typography>
                                    <Typography><b>Cadastro:</b> {this.state.dadoAdvogado?.DATETIME_INSERT_FORMAT}</Typography>
                                </Col>
                            ) }
                        </Row>
                    </Modal>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminAdvogadoList;